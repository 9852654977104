import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import './BugDetailFilter.css'

import UserListItem from '../UserListItem/UserListItem';
import { setBugListFilter } from '../../redux/actions/bug/bugs';

class BugDetailFilter extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleAssignedTo = this.toggleAssignedTo.bind(this);
    this.state = {
      dropdownOpen: false,
      dropdownAssignedToOpen: false
    };
  }
  
  getButtonLabel() {
    return this.getBugStatusActionByKey(this.props.currentBugListFilter.status);
  }

  getBugStatusActionByKey(statusKey) {
    var item = this.props.bugStatusObject[statusKey];
    if (item) {
      return item;
    }
    return {
      name: "Show all",
      key: "",
      color: "#888"
    };
  }

  bugStatusList() {
    var actionList = [{
      name: "Show all",
      key: "",
      color: "#888"
    }, ...this.props.bugStatusList];
    return actionList;
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleAssignedTo() {
    this.setState(prevState => ({
      dropdownAssignedToOpen: !prevState.dropdownAssignedToOpen
    }));
  }

  changeValue(e) {
    let id = e.currentTarget.getAttribute("id");
    localStorage.setItem('bugStatusFilter', id);
    this.props.setBugListFilter(id, localStorage.getItem('bugAssignedToFilter'));
  }

  changeAssignedToValue(e) {
    let id = e.currentTarget.getAttribute("id");
    localStorage.setItem('bugAssignedToFilter', id);
    this.props.setBugListFilter(localStorage.getItem('bugStatusFilter'), id);
  }

  render() {
    if (!this.props.currentProject || !this.props.currentProject.owner) {
      return (<div></div>);
    }

    var currentStatus = this.getButtonLabel();
    return (
        <div className="buglist-filter-dropdown">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
              <div className="bugListFilterItem">
                <span>{ currentStatus.name }</span>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              { this.bugStatusList().map((item, index) => {
                return (
                  <DropdownItem key={ index } id={ item.key } onClick={ this.changeValue.bind(this) }>
                    <div className="bugStatusItem">
                      <div className="bugStatusBubbleInlineBig" style={{
                        backgroundColor: item.color
                      }}></div>
                      <span>{ item.name }</span>
                    </div>
                  </DropdownItem>
                )
              }) }
            </DropdownMenu>
          </Dropdown>

          <Dropdown isOpen={this.state.dropdownAssignedToOpen} toggle={this.toggleAssignedTo}>
            <DropdownToggle caret>
              <div className="bugAssignedToItem">
                <UserListItem userId={ this.props.currentBugListFilter.assignedTo }></UserListItem>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              { this.props.currentProject.owner.map((item, index) => {
                return (
                  <DropdownItem key={ index } id={ item } onClick={ this.changeAssignedToValue.bind(this) }>
                    <div className="bugAssignedToItem">
                      <UserListItem userId={ item }></UserListItem>
                    </div>
                  </DropdownItem>
                )
              }) }
              <DropdownItem id={ null } onClick={ this.changeAssignedToValue.bind(this) }>
                  <div className="bugAssignedToItem">
                    <UserListItem userId=""></UserListItem>
                  </div>
                </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
    bugStatusList: state.bug.bugStatusList,
    bugStatusObject: state.bug.bugStatusObject,
    currentBugListFilter: state.bug.currentBugListFilter
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      setBugListFilter: (status, assignedTo) => {
        dispatch(setBugListFilter(status, assignedTo));
      }
    };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugDetailFilter);