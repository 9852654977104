import { BUGS_FOR_TESTER_LOADED, LOADING_BUGS_FOR_TESTER, SET_FILTER_FOR_TESTER, LOADING_COMMENTS_FOR_TESTER_BUG, SET_TESTER_CREDENTIALS, TOGGLE_BUG_FOR_TESTER, COMMENTS_FOR_TESTER_BUG_LOADED, TOGGLE_CONFETTI_PARTY_FOR_TESTER } from "../../actions/tester/tester";

const initialState = {
    loading: false,
    loadingComments: false,
    bugs: [],
    currentComments: [],
    currentTogglededBug: "",
    filter: "",
    projectId: "",
    testerEmail: "",
    accessKey: "",
    confettiParty: false
};
  
export default function tester(state = initialState, action) {
    if (action.type === LOADING_BUGS_FOR_TESTER) {
        return Object.assign({}, state, {
            loading: true
        });
    }
    if (action.type === BUGS_FOR_TESTER_LOADED) {
        return Object.assign({}, state, {
            loading: false,
            bugs: action.bugs
        });
    }
    if (action.type === SET_FILTER_FOR_TESTER) {
        return Object.assign({}, state, {
            filter: action.filter
        });
    }
    if (action.type === LOADING_COMMENTS_FOR_TESTER_BUG) {
        return Object.assign({}, state, {
            loadingComments: true,
            currentComments: []
        });
    }
    if (action.type === COMMENTS_FOR_TESTER_BUG_LOADED) {
        return Object.assign({}, state, {
            loadingComments: false,
            currentComments: action.comments
        });
    }
    if (action.type === TOGGLE_CONFETTI_PARTY_FOR_TESTER) {
        return Object.assign({}, state, {
            confettiParty: action.confettiParty
        });
    }
    if (action.type === SET_TESTER_CREDENTIALS) {
        return Object.assign({}, state, {
            projectId: action.projectId,
            testerEmail: action.testerEmail,
            accessKey: action.accessKey
        });
    }
    if (action.type === TOGGLE_BUG_FOR_TESTER) {
        return Object.assign({}, state, {
            currentTogglededBug: action.bugId
        });
    }
    return state;
}  