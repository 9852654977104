import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faColumns, faCog, faBox, faBoxUsd, faPlug } from '@fortawesome/pro-regular-svg-icons';
import './ProjectSettingsPage.css';

import { setProject, setNavRoute } from '../../redux/actions/project/projectUI';
import IntegrationTokenViewer from '../../components/IntegrationTokenViewer/IntegrationTokenViewer';
import ProjectMembers from '../../components/ProjectMembers/ProjectMembers';
import ProjectMetaSettings from '../../components/ProjectMetaSettings/ProjectMetaSettings';
import ProjectStatusSettings from '../../components/ProjectStatusSettings/ProjectStatusSettings';
import BillingPlanSubscriptions from '../../components/BillingPlanSubscriptions/BillingPlanSubscriptions';
import ProjectIntegrations from '../../components/ProjectIntegrations/ProjectIntegrations';

const headerArray = [{
    title: "SDK",
    icon: faBox,
    key: "sdk",
    description: "Integrate the BugBattle SDK in your apps.",
    link: {
        title: "Learn more",
        url: "https://developers.bugbattle.io/bugbattle/getting-started"
    }
}, {
    title: "Team management",
    icon: faUsers,
    key: "team_management",
    description: "Invite your team to BugBattle.",
    link: {
        title: "",
        url: ""
    }
}, {
    title: "Bug states",
    icon: faColumns,
    key: "board_states",
    description: "Customise your bug states.",
    link: {
        title: "Learn more",
        url: "http://developers.bugbattle.io/bugbattle/bugs/bug-states"
    }
}, {
    title: "Integrations",
    icon: faPlug,
    key: "integrations",
    description: "Fully integrate BugBattle into your enviroment.",
    link: {
        title: "Integrations",
        url: "http://www.bugbattle.io/"
    }
}, {
    title: "Settings",
    icon: faCog,
    key: "settings",
    description: "All your project settings.",
    link: {
        title: "",
        url: ""
    }
}, {
    title: "Billing",
    icon: faBoxUsd,
    key: "billing",
    description: "Choose the plan that suits you best.",
    link: {
        title: "BugBattle plans",
        url: "http://www.bugbattle.io/"
    }
}];

class ProjectSettingsPage extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: "sdk"
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    getTabForRoute(route) {
        if (route === "team") {
            return "team_management";
        }
        if (route === "states") {
            return "board_states";
        }
        if (route === "settings") {
            return "settings";
        }
        if (route === "integration") {
            return "integration";
        }
        if (route === "billing") {
            return "billing";
        }
        return "sdk";
    }

    componentDidMount() {
        this.setState({
            activeTab: this.getTabForRoute(this.props.match.params.page)
        });
        this.props.setProject(this.props.match.params.id);
        this.props.setNavRoute("settings");
    }

    getSelectedHeader() {
        for (var i = 0; i < headerArray.length; i++) {
            if (headerArray[i].key === this.state.activeTab) {
                return headerArray[i];
            }
        }
        return {};
    }

    render() {
        let selectedHeader = this.getSelectedHeader();

        return (
            <div className="Container">
                <div className="LeftNavigationBar">
                    <div className="settings-tab-item-hero">Settings</div>
                    {
                        headerArray.map((headerItem, index) => {
                            return (
                                <div key={ index } className={`settings-tab-item ${this.state.activeTab === headerItem.key ? 'settings-tab-item-active' : ''}`} onClick={() => {
                                    this.toggle(headerItem.key)
                                }}>
                                    <div className="settings-tab-item-icon">
                                        <FontAwesomeIcon icon={ headerItem.icon }></FontAwesomeIcon>
                                    </div>
                                    { headerItem.title }
                                </div>
                            );
                        })
                    }
                </div>
                <div className="MainContainerFull">
                    <div className="MainContainerFullHero">
                        <div className="MainContainerFullHeroIcon"><FontAwesomeIcon icon={ selectedHeader.icon }></FontAwesomeIcon></div>
                        <div className="MainContainerFullHeroTitle">{ selectedHeader.title }</div>
                        <div className="MainContainerFullHeroBody">
                            <div className="MainContainerFullHeroBodyTitle">{ selectedHeader.description }</div>
                            <div className="MainContainerFullHeroBodyLinks"><a href={ selectedHeader.link.url } target="_blank" rel="noopener noreferrer">{ selectedHeader.link.title }</a></div>
                        </div>
                    </div>
                    <div className="MainContainerFullInner">
                        { (this.state.activeTab === "sdk") && <IntegrationTokenViewer></IntegrationTokenViewer> }
                        { (this.state.activeTab === "team_management") && <ProjectMembers></ProjectMembers> }
                        { (this.state.activeTab === "board_states") && <ProjectStatusSettings></ProjectStatusSettings> }
                        { (this.state.activeTab === "integrations") && <ProjectIntegrations></ProjectIntegrations> }
                        { (this.state.activeTab === "settings") && <ProjectMetaSettings></ProjectMetaSettings> }
                        { (this.state.activeTab === "billing") && <BillingPlanSubscriptions></BillingPlanSubscriptions> }
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProject: (projectId) => {
            dispatch(setProject(projectId));
        },
        setNavRoute: (route) => {
            dispatch(setNavRoute(route));
        }
    };
};

export default connect(
    null,
    mapDispatchToProps
)(ProjectSettingsPage);
