import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { TwitterPicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import './ProjectStatusSettings.css';

import { updateProject } from '../../redux/actions/project/projects';

class ProjectStatusSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
        bugStatusList: null,
        newStatusName: "",
        newStatusColor: "#0693E3",
        showColorPicker: false
    }
  }

  addState() {
      var newState = {
          name: this.state.newStatusName,
          color: this.state.newStatusColor,
          key: encodeURIComponent(this.state.newStatusName)
      };
      var bugStatusList = [...this.props.currentProject.bugStatusList];
      bugStatusList.push(newState);
      this.saveWithNewStatusList(bugStatusList);
      this.setState({
        newStatusName: "",
        newStatusColor: "#0693E3"
      });
  }

  removeState(index) {
    var bugStatusList = [...this.props.currentProject.bugStatusList];
    bugStatusList.splice(index, 1);
    this.saveWithNewStatusList(bugStatusList);
  }

  saveWithNewStatusList(bugStatusList) {
    this.props.updateProject(this.props.currentProject._id, {
      bugStatusList: bugStatusList
    });
  }

  handleChangeComplete = (color) => {
    this.setState({
        newStatusColor: color.hex,
        showColorPicker: false
    });
  };

  render() {
    return (
        <div className="">
            <div className="box-content mb-32">
                <div className="projectheader">
                    <h1>Bug states</h1>
                </div>
                <div className="subtitle-text">Customise your bug states the way you want to.</div>
                <div className="project-board-state-list">
                    <div className="project-board-state-list-user-item">
                        <div className="bugStatusBubbleState" style={{
                            backgroundColor: '#ff0000'
                        }}></div>
                        <div className="state-item-bubble">
                            Open
                        </div>
                    </div>
                    {
                        this.props.currentProject.bugStatusList.map((status, index) => {
                            return (
                                <div key={ index } className="project-board-state-list-user-item">
                                    <div className="bugStatusBubbleState" style={{
                                        backgroundColor: status.color
                                    }}></div>
                                    <div className="state-item-bubble">
                                        { status.name }
                                    </div>
                                    <div className="project-board-state-list-user-item-remove" onClick={() => {
                                        this.removeState(index);
                                    }}>
                                        <FontAwesomeIcon color="#fff" icon={ faTimes } />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="project-board-state-list-user-item">
                        <div className="bugStatusBubbleState" style={{
                            backgroundColor: 'rgb(142, 209, 252)'
                        }}></div>
                        <div className="state-item-bubble">
                            Feedback needed
                        </div>
                    </div>
                    <div className="project-board-state-list-user-item">
                        <div className="bugStatusBubbleState" style={{
                            backgroundColor: 'rgb(6, 147, 227)'
                        }}></div>
                        <div className="state-item-bubble">
                            Ready to test again
                        </div>
                    </div>
                    <div className="project-board-state-list-user-item">
                        <div className="bugStatusBubbleState" style={{
                            backgroundColor: 'rgb(141, 198, 63)'
                        }}></div>
                        <div className="state-item-bubble">
                            Done
                        </div>
                    </div>
                </div>

                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <div className="stateColorPickerPreview">
                            <div className="stateColorPickerPreviewColor" onClick={() => {
                                this.setState({
                                    showColorPicker: !this.state.showColorPicker
                                });
                            }}
                            style={{
                                backgroundColor: this.state.newStatusColor
                            }}></div>
                        {
                            this.state.showColorPicker && <div className="stateColorPickerPreviewPicker">
                                <TwitterPicker
                                    color={ this.state.newStatusColor }
                                    onChangeComplete={ this.handleChangeComplete } />
                            </div>
                        }
                        </div>
                    </InputGroupAddon>
                    <Input placeholder="State name" type="text" value={ this.state.newStatusName } onChange={(event) => {
                        this.setState({ newStatusName: event.target.value })
                    }} />
                    <InputGroupAddon addonType="append">
                        <Button disabled={ this.state.newStatusName === "" } color="primary" onClick={this.addState.bind(this)}>
                            Add state
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (projectId, data) => {
        dispatch(updateProject(projectId, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectStatusSettings);