import React from 'react';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import { DropTarget } from 'react-dnd';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import BugCard from '../../components/BugCard/BugCard';
import { showBugModally, updateBug, setBugDraggingColor } from '../../redux/actions/bug/bugs';
import { blendColors, shadeBlend } from '../../helper/general';

// Drag sources and drop targets only interact
// if they have the same string type.
// You want to keep types in a separate file with
// the rest of your app's constants.
const Types = {
    CARD: 'card'
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const chessSquareTarget = {
  canDrop(props, monitor) {
    // You can disallow drop based on props or item
    const item = monitor.getItem();
    return true;
  },

  hover(props, monitor, component) {
    // This is fired very often and lets you perform side effects
    // in response to the hover. You can't handle enter and leave
    // here—if you need them, put monitor.isOver() into collect() so you
    // can just use componentDidUpdate() to handle enter/leave.

    // You can access the coordinates if you need them
    const clientOffset = monitor.getClientOffset();
    const componentRect = findDOMNode(component).getBoundingClientRect();

    // You can check whether we're over a nested drop target
    const isJustOverThisOne = monitor.isOver({ shallow: true });

    // You will receive hover() even for items for which canDrop() is false
    const canDrop = monitor.canDrop();
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    }

    const item = monitor.getItem();

    // Check if we have not moved.
    if (item.bug.status === props.boardkey) {
      return;
    }

    // Obtain the dragged item
    props.updateBug(item.bug, {
      status: props.boardkey
    });

    return { moved: true };
  }
};

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),

    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

class BoardColumn extends React.Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.isOver && this.props.isOver) {
      // You can use this as enter handler
      this.props.setBugDraggingColor(this.props.boardcolor);
    }

    if (prevProps.isOver && !this.props.isOver) {
      // You can use this as leave handler
    }

    if (prevProps.isOverCurrent && !this.props.isOverCurrent) {
      // You can be more specific and track enter/leave
      // shallowly, not including nested targets
    }
  }

  getBoardColor() {
    return shadeBlend(0.9, this.props.boardcolor);
  }

  render() {
    // Your component receives its own props as usual
    const { position } = this.props;

    // These props are injected by React DnD,
    // as defined by your `collect` function above:
    const { isOver, canDrop, connectDropTarget } = this.props;

    return connectDropTarget(
      <div className='BoardColumn' style={{
        backgroundColor: isOver ? this.getBoardColor() : '#fff'
      }}>
        <AutoSizer>
            {({ height }) => {
                return (
                    <div>
                        <List
                            ref='virtualList'
                            className='list'
                            width={ 280 }
                            height={ height }
                            rowCount={ this.props.buglist.length }
                            rowHeight={ 100 }
                            rowRenderer={({
                                key, // Unique key within array of rows
                                index, // Index of row within collection
                                parent,
                                isScrolling, // The List is currently being scrolled --> Important if you need some perf adjustment
                                isVisible, // This row is visible within the List (eg it is not an overscanned row)
                                style, // Style object to be applied to row (to position it)
                             }) => {
                                return (
                                    <div style={ style } key={ key } onClick={() => {
                                        this.props.showBugModally(this.props.buglist[index]);
                                    }}>
                                        <BugCard bug={
                                            this.props.buglist[index]
                                        }></BugCard>
                                    </div>
                                )
                            }}
                        />
                    </div>
                );
            }}
        </AutoSizer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showBugModally: (bug) => {
            dispatch(showBugModally(bug));
        },
        updateBug: (bug, data) => {
            dispatch(updateBug(bug, data));
        },
        setBugDraggingColor: (color) => {
          dispatch(setBugDraggingColor(color));
        }
    };
};

export default connect(
  null,
  mapDispatchToProps
)(DropTarget(Types.CARD, chessSquareTarget, collect)(BoardColumn));