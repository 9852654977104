import React, { Component } from 'react';
import { connect } from 'react-redux';
import './BugDetail.css';

import { removeBug } from '../../redux/actions/bug/bugs';
import BugDetailTabs from '../BugDetailTabs/BugDetailTabs';

class BugDetail extends Component {
  state = {
    activeTab: "0"
  }

  renderMainPage() {
    return (
      <BugDetailTabs></BugDetailTabs>
    );
  }

  render() {
    return (
      <div>
        {
          this.props.currentBug._id ?
            this.renderMainPage() : 
            <div className="bugdetail">
              <div className="bugdetail-nobugselected">Please select a bug in the bug list.</div>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bugs: state.bug.bugs,
    selectedProject: state.projectUI.selectedProjectId,
    currentBug: state.bug.currentBug
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      deleteBug: (bugId) => {
        dispatch(removeBug(bugId));
      }
    };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugDetail);