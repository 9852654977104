import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { push } from 'connected-react-router';
import { BarLoader } from 'react-spinners';
import './VerifyTokenPage.css';

import MongoClient from '../../mongodb/MongoClient';
import Confetti from 'react-dom-confetti';

class VerifyTokenPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      verificationDone: false,
      error: null,
      confetti: false
    };
  }

  componentDidMount() {
    this.verifyEmail();
  }

  verifyEmail() {
    MongoClient.getInstance().verifyEmail().then(() => {
      this.setState({
        verificationDone: true,
        loading: false
      });
      setTimeout(() => {
        this.setState({
          confetti: true
        });
      }, 1000);
    })
    .catch((err) => {
      this.setState({
        error: err.message,
        loading: false
      });
    });
  }

  render() {
    return (
      <div className="LoginContainer">
        <div className="LoginForm p-32">
        {
          this.state.loading ? <div className="modal-center-content">
          <BarLoader
            sizeUnit={"px"}
            width={ 100 }
            height={ 4 }
            color={'#3574F3'}
            loading={ true }
          />
          </div> : <div>
          {
            this.state.error ? <div>
              <span className="verify-email-header" role="img" aria-label="Awesome!">🤯</span>
              <div className="verify-email-body">We are sorry! <b>{ this.state.error }</b></div>
              <Button size="lg" color="primary" onClick={() => {
                  window.location.href = "http://www.bugbattle.io";
                }}>
                  Need help?
                </Button>
            </div> : <div>
                <span className="verify-email-header" role="img" aria-label="Awesome!">🚀</span>
                <div className="projectheader center">
                    <h1 className="center">Awesome!</h1>
                </div>
                <div className="verify-email-body">You are all set.</div>
                <Button size="lg" color="primary" onClick={() => {
                  this.props.push("/login");
                }}>
                  Login
                </Button>
                <div className="confetti-center">
                  <Confetti active={ this.state.confetti } config={{
                        angle: 90,
                        spread: 40,
                        startVelocity: 35,
                        elementCount: 60,
                        decay: 0.9
                      }}/>
                </div>
            </div>
          }
          </div>
        }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      push: (url) => {
          dispatch(push(url));
      }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(VerifyTokenPage);
