import axios from 'axios';

const axiosJ = axios.create({
    // timeout: 1000,
});

/**
 * Exchange the refresh token for access token
 * @param {*} token 
 */
const exchangeRefreshToken = (token) => {
    return axios.get("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/bugbattle-xfblb/service/Jira/incoming_webhook/exchangeRefreshForAccessToken?token=" + token)
        .then(res => res.data.access_token)
}
const getResources = async (conf) => {
    axiosJ.defaults.headers.common['Authorization'] = "Bearer " + conf.access_token;
    try {
        const res = await axiosJ("https://api.atlassian.com/oauth/token/accessible-resources");
        return res.data;
    }
    catch (err) {
        if (err.response.status === 401) {
            return exchangeRefreshToken(conf.refresh_token)
                .then(access_token => {
                    axiosJ.defaults.headers.common['Authorization'] = "Bearer " + access_token;
                    return getResources({ access_token: access_token, refresh_token: conf.refresh_token })
                })
        }
        return err;
    }
}

/**
 * Load all Jira resources
 * @param {} conf 
 */
export const loadResources = async (conf) => {
    return getResources(conf)
};

/**
 * Load all projects fron Jira
 * @param {} resourceId 
 * @param {*} access_token 
 */
export const loadProjects = async (resourceId, access_token) => {
    try {
        const res = await axiosJ({
            method: 'GET',
            url: `https://api.atlassian.com/ex/jira/${resourceId}/rest/api/3/project/search?expand=issueTypes`,
            // headers: {
            //     Authorization: 'Bearer ' + access_token,
            // },
        });
        return res.data.values;
    }
    catch (e) {
        return e;
    }
};

export default { loadResources, loadProjects }
