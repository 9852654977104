import { loadProject } from "./projects";

export const SELECT_PROJECT = 'SELECT_PROJECT'
export const selectProject = (projectId) => {
  return {
    type: SELECT_PROJECT,
    projectId: projectId
  }
}

export const SET_NAV_ROUTE = 'SET_NAV_ROUTE'
export const setNavRoute = (route) => {
  return {
    type: SET_NAV_ROUTE,
    route: route
  }
}

export const setProject = (projectId) => {
  return (dispatch, getState) => {
    dispatch(selectProject(projectId));
    dispatch(loadProject(projectId));
  }
}