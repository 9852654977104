import React, { Component } from 'react';
import { connect } from 'react-redux';
import Steps, { Step } from 'rc-steps';
import { Button } from 'reactstrap';
import Highlight from 'react-highlight';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import './IosIntegrationTutorialModal.css';
import { push } from 'connected-react-router';

class IosIntegrationTutorialModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      language: 'swift',
      currentStep: 0,
      steps: [{
        title: "Add BugBattle SDK"
      }, {
        title: "Add initialization code"
      }, {
        title: "Verify",
      }]
    };
  }
  
  prevStep = () => {
    let s = this.state.currentStep - 1;
    this.setState({
      currentStep: s
    });
  }

  nextStep = () => {
    let s = this.state.currentStep + 1;
    if (s === this.state.steps.length) {
      s = 0;
    }
    this.setState({
      currentStep: s
    });
  }

  render() {
    const cs = this.state.currentStep;
    this.stepsRefs = [];
    return (
      <div className="CardBoxBorder CardBoxBorder--Modal">
        <form className="my-step-form">
          <div className="my-step-container">
            <Steps current={cs}>
              {
                this.state.steps.map((s, i) => {
                  return (
                    <Step ref={c => this.stepsRefs[i] = c}
                      key={i}
                      title={s.title}
                    />
                  );
                })
              }
            </Steps>
          </div>
          <div className="IntegrationModalBody">
              { (cs === 0) && <div>
                <div className="mt-16">The BugBattle SDK uses <a href="https://cocoapods.org/" target="_blank" rel="noopener noreferrer">CocoaPods</a> to install and manage dependencies. Open a terminal window and navigate to the location of the Xcode project for your app.</div>
                <div className="boldtext mt-16">Create a Podfile if you don't have one:</div>
                <div className="codeblock">$ pod init</div>
                <div className="boldtext mt-16">Open your Podfile and add:</div>
                <div className="codeblock">pod 'BugBattle'</div>
                <div className="boldtext mt-16">Save the file and run:</div>
                <div className="codeblock">$ pod install</div>
                <div className="mt-16">This creates an .xcworkspace file for your app. Use this file for all future development on your application.</div>
                <div className="mt-16 mb-16">Check out our <a href="https://developers.bugbattle.io/ios-sdk/getting-stared/getting-started" target="_blank" rel="noopener noreferrer">documentation</a> if you don't want to use CocoaPods.</div>
              </div> }
              { (cs === 1) && <div>
                <div className="programingLanguageSelection">
                  <Button color={ this.state.language === 'swift' ? 'primary' : 'secondary' } onClick={() => {
                    this.setState({
                      language: 'swift'
                    });
                  }}>Swift</Button>
                  <Button color={ this.state.language === 'objc' ? 'primary' : 'secondary' } onClick={() => {
                    this.setState({
                      language: 'objc'
                    });
                  }}>Objective-C</Button>
                </div>
                {
                  this.state.language === 'swift' ? <div>
                    <div className="mt-16">Open your XCode project (.xcworkspace) and open your App Delegate (AppDelegate.swift)</div>
                    <div className="boldtext mt-16">Import the BugBattle SDK</div>
                    <div className="mt-16">Import the BugBattle SDK by adding the following import below your other imports.</div>
                    <Highlight className='swift'>
                      {"import BugBattle"}
                    </Highlight>
                    <div className="boldtext mt-16">Initialize the SDK</div>
                    <div className="mt-16">The last step is to initialize the BugBattle SDK by adding the following Code to the end of the applicationDidFinishLaunchingWithOptions delegate:</div>
                    <Highlight className='swift'>
                      {"BugBattle.initWithToken(\"" + this.props.currentProject._id.toHexString() + "\", andActivationMethod: SHAKE)"}
                    </Highlight>
                  </div> : <div>
                    <div className="mt-16">Open your XCode project (.xcworkspace) and open your App Delegate (AppDelegate.m)</div>
                    <div className="boldtext mt-16">Import the BugBattle SDK</div>
                    <div className="mt-16">Import the BugBattle SDK by adding the following import below your other imports.</div>
                    <div className="codeblock">#import &lt;BugBattle/BugBattle.h&gt;</div>
                    <div className="boldtext mt-16">Initialize the SDK</div>
                    <div className="mt-16">The last step is to initialize the BugBattle SDK by adding the following Code to the end of the applicationDidFinishLaunchingWithOptions delegate:</div>
                    <Highlight className='objectivec'>
                      {"[BugBattle initWithToken: @\"" + this.props.currentProject._id.toHexString() + "\" andActivationMethod: SHAKE];"}
                    </Highlight>
                  </div>
                }
                <div className="boldtext mt-16">Save & run <span role="img" aria-label="rocket">🚀</span></div>
                <div className="mt-16 mb-16">Need help? Check out our <a href="https://developers.bugbattle.io/ios-sdk/getting-stared/getting-started" target="_blank" rel="noopener noreferrer">documentation</a> or <a href="#" onClick={() => {
                  window.Intercom('showNewMessage');
                }}>contact us</a>.</div>
              </div> }
              { (cs === 2) && <div className="success-panel">
                <div className="boldtext mt-16">Congratulations <span role="img" aria-label="rocket">🚀</span></div>
                <div className="mt-16 mb-16">You have installed BugBattle successfully! If you run into problems during installation, just <a href="#" onClick={() => {
                  window.Intercom('showNewMessage');
                }}>let us know</a>! We are here to help!</div>
                <div className="IntegrationModalBodyButtons">
                  <Button size="lg" color="primary" onClick={() => {
                    this.props.push('/project/' + this.props.currentProject._id + '/');
                  }}>Go to dashboard</Button>
                </div>
              </div> }
          </div>
          {
            (cs < 2) && 
            <div className="IntegrationModalBodyButtons">
              { cs > 0 && <Button size="lg" color="link" onClick={this.prevStep}>Back</Button> }
              <Button size="lg" color="primary" onClick={this.nextStep}>Next</Button>
            </div>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => {
      dispatch(push(url));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IosIntegrationTutorialModal);