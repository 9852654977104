import React, { Component } from 'react';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './BillingSettings.css';

import CheckoutForm from './CheckoutForm';
import { updateUser, showAddCardModal } from '../../redux/actions/user/users';
import MasterCard from './MasterCard.png';
import JCB from './JCB.png';
import AmericanExpress from './AmericanExpress.png';
import Discover from './Discover.png';
import DinersClub from './DinersClub.png';
import Visa from './Visa.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt, faTimes } from '@fortawesome/pro-regular-svg-icons';

const modalStyle = {
  content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
      maxWidth: '85vw'
  }
};

class BillingSettings extends Component {
  getCurrentImage(cardType) {
    if (cardType === "American Express") {
      return AmericanExpress;
    }
    if (cardType === "MasterCard") {
      return MasterCard;
    }
    if (cardType === "Discover") {
      return Discover;
    }
    if (cardType === "JCB") {
      return JCB;
    }
    if (cardType === "Diners Club") {
      return DinersClub;
    }
    return Visa;
  }

  renderAddCard() {
    return (
      <div>
        <div className="subtitle-text">Add billing details to your account.</div>
        <div className="stripe-checkout-form">
          <Elements>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    );
  }

  renderModalContent() {
    return (
      <div className="CardBoxBorder mw-550">
        <div className="projectheader ">
            <h1>Update card</h1>
        </div>
        <div className="subtitle-text">Update your billing details.</div>
        <div className="stripe-checkout-form">
          <Elements>
            <CheckoutForm />
          </Elements>
        </div>
        <div className="bug-modal-close-btn" onClick={() => {
          this.props.toggleAddCardModal(false);
        }}>
            <FontAwesomeIcon color="#fff" icon={ faTimes } />
        </div>
      </div>
    )
  }

  renderExistingCard() {
    return (
      <div>
        <Modal
        isOpen={this.props.showAddCardModal}
        contentLabel="Edit Card Modal"
        style={ modalStyle }
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          this.props.toggleAddCardModal(false);
        }}
        ariaHideApp={false}>
          { this.renderModalContent() }
        </Modal>
        <div className="subtitle-text">Update your billing details.</div>
        <div className="billing-settings-existing-cards">
          <div className="billing-settings-existing-cards-logo">
            <img src={ this.getCurrentImage(this.props.currentUser.billingCustomerCard.brand) } alt="card-type" />
          </div>
          <div className="billing-settings-existing-cards-info">
            Active card ends with: { this.props.currentUser.billingCustomerCard.last4 }
          </div>
          <div className="billing-settings-existing-cards-edit" onClick={() => {
            this.props.toggleAddCardModal(true);
          }}>
            <FontAwesomeIcon icon={ faPenAlt } />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="box-content mb-32">
          <div className="projectheader ">
              <h1>Billing details</h1>
          </div>
          { this.props.currentUser.billingCustomerCard ? this.renderExistingCard() : this.renderAddCard() }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
    showAddCardModal: state.user.showAddCardModal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId, data) => {
      dispatch(updateUser(userId, data));
    },
    toggleAddCardModal: (show) => {
      dispatch(showAddCardModal(show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingSettings);