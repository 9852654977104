import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import createProject from "./project/createProject";
import projects from "./project/projects";
import bugs from "./bug/bugs";
import projectUI from "./project/projectUI";
import jira from "./project/jira";
import trello from "./project/trello";
import users from "./user/users";
import tester from "./tester/tester";

export default (historyReducer) => combineReducers({
  router: connectRouter(historyReducer),
  createProject: createProject,
  project: projects,
  projectUI: projectUI,
  bug: bugs,
  user: users,
  tester: tester,
  jira: jira,
  trello: trello,
});