import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Table } from 'reactstrap';
import './ProjectMembers.css';

import User from '../User/User';
import { updateProject } from '../../redux/actions/project/projects';
import MongoClient from '../../mongodb/MongoClient';

class ProjectMembers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ""
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      
    }

    addMember() {
      MongoClient.getInstance().loadUserByEmail(this.state.email).then((user) => {
        if (user) {
          var ownerArray = this.props.currentProject.owner;
          if (ownerArray.indexOf(user.owner) === -1) {
            ownerArray.push(user.owner)
          }
          this.updateOwner(ownerArray);
        } else {
          // Send inviation.
          let invArray = [];
          if (this.props.currentProject.invited) {
            invArray = [...this.props.currentProject.invited];
          }
          invArray.push(this.state.email);
          this.sendInvitation(this.state.email);
          this.updateInvites(invArray);
          this.setState({
            invited: invArray,
            email: ""
          });
        }
      });
    }

    sendInvitation(email) {
      fetch('https://dashboard.bugbattle.io/api/sendInvitation.php', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            invitedBy: this.props.currentUser.name,
              projectName: this.props.currentUser.name,
              email: email
          })
      }).then((data) => {
          data.json().then(json => {
            
          });
      }).catch((err) => {

      });
    }

    updateInvites(invites) {
      this.props.updateProject(this.props.currentProject._id, {
        invited: invites
      });
    }

    removeInvite(email) {
      var invitesArr = this.props.currentProject.invited;
      var itemAt = invitesArr.indexOf(email);
      if (itemAt !== -1) {
        invitesArr.splice(itemAt, 1);
      }
      this.updateInvites(invitesArr);
    }

    removeOwner(userId) {
      var redirectAfterRemove = false;
      if (userId === MongoClient.getInstance().userId()) {
        if (window.confirm("Do you really want to remove yourself from the project? You will lose access to the project!")) {
          redirectAfterRemove = true;
        } else {
          return;
        }
      }

      var ownerArray = this.props.currentProject.owner;
      var itemAt = ownerArray.indexOf(userId);
      if (itemAt !== -1) {
        ownerArray.splice(itemAt, 1);
      }
      this.updateOwner(ownerArray);

      if (redirectAfterRemove) {
        this.props.push("/dashboard/");
      }
    }

    updateOwner(ownerArray) {
      // Local update
      this.setState({
        email: "",
        owner: ownerArray
      });

      // Update project
      this.props.updateProject(this.props.currentProject._id, {
        owner: ownerArray
      });
    }

    renderMembers() {
      let ownUserId = MongoClient.getInstance().userId();
      return (<div>
        { this.props.currentProject.owner &&
          this.props.currentProject.owner.map((userId, index) => {
            return (
              <div key={ index } className="project-members-list-user-item">
                <User userId={ userId }></User>
                {
                  (userId !== ownUserId) && <div className="project-members-list-user-item-remove" onClick={() => {
                      this.removeOwner(userId);
                  }}>
                      <FontAwesomeIcon icon={ faTimes } />
                  </div>
                }
              </div>
            )
          })
        }
      </div>)
    }
    
    renderInvited() {
      if (!this.props.currentProject.invited || this.props.currentProject.invited.length === 0) {
        return;
      }
      
      return (<div>
        { this.props.currentProject.invited &&
          this.props.currentProject.invited.map((invitedUser) => {
            return (
              <div key={ invitedUser } className="project-members-list-user-item">
                <div className="invited-email-body">
                  <img className="invited-email-body-image" alt="Avatar" src={ 'https://api.adorable.io/avatars/285/' + invitedUser }></img>
                  <div className="invited-email-body-cont">
                    <span className="invited-email-body-main">{ invitedUser }</span>
                    <span className="invited-email-body-sub">Invited</span>
                  </div>
                </div>
                <div className="project-members-list-user-item-remove" onClick={() => {
                    this.removeInvite(invitedUser);
                }}>
                    <FontAwesomeIcon icon={ faTimes } />
                </div>
              </div>
            )
          })
        }
      </div>);
    }

    canAddNewMember() {
      let memberLimit = 1;
      if (this.props.currentProject.currentPlan === "startup") {
        memberLimit = 5;
      }
      if (this.props.currentProject.currentPlan === "premium") {
        memberLimit = 100;
      }

      let invited = 0;
      if (this.props.currentProject.invited) {
        invited = this.props.currentProject.invited.length;
      }
      let currLimit = this.props.currentProject.owner.length + invited;
      if (currLimit >= memberLimit) {
        return false;
      }
      return true;
    }

    renderAddMember() {
      if (this.canAddNewMember()) {
        return (
          <InputGroup>
            <Input placeholder="Email" type="text" value={ this.state.email } onChange={(event) => {
              this.setState({ email: event.target.value })
            }} />
            <InputGroupAddon addonType="append">
              <Button disabled={ this.props.currentProject.owner === "" } color="primary" onClick={this.addMember.bind(this)}>
                Invite member
              </Button>
            </InputGroupAddon>
          </InputGroup>
        );
      } else {
        return (<div className="planUpgradeInfo">Please <a href={ "/project/" + this.props.currentProject._id.toString() + "/settings/billing" }>upgrade your plan</a> in order to add new members.</div>);
      }
    }
    
    render() {
      return (
          <div className="">
            <div className="projectheader">
              <h1>Invite members</h1>
            </div>
            <div className="subtitle-text">Invite your team-members.</div>
            <div className="box-content mb-32">
              { this.renderMembers() }
              { this.renderInvited() }
              <div className="mt-32">
              { this.renderAddMember() }
              </div>
            </div>
          </div>
      );
    }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (projectId, data) => {
        dispatch(updateProject(projectId, data));
    },
    push: (url) => {
      dispatch(push(url));
    }
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectMembers);