import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProjectHomePage.css';
import Steps from 'rc-steps';
import { Button } from 'reactstrap';

import IntegrationTokenViewer from '../../components/IntegrationTokenViewer/IntegrationTokenViewer';
import ProjectIntegrations from '../../components/ProjectIntegrations/ProjectIntegrations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import { setProject, setNavRoute } from '../../redux/actions/project/projectUI';
import { updateProject } from '../../redux/actions/project/projects';
import { push } from 'connected-react-router';
class ProjectHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
    };
  }
  componentDidMount() {
    this.props.setProject(this.props.match.params.id);
    this.props.setNavRoute('/');
  }

  componentDidUpdate() {
    if (this.props.currentProject.obDone && !this.state.currentStep) {
      this.setState({ currentStep: 'finished' });
    }
  }

  render() {
    const done = this.props.currentProject.obDone;
    // const done = true;

    return (
      <div className='Container'>
        <div className='LeftNavigationBar'>
          <h2 className={'StepsHeader'}>First</h2>
          <div className={'StepsContainer'}>
            <Steps current={this.state.currentStep} direction='vertical' icons={{ finish: <FontAwesomeIcon icon={faCheck} /> }}>
              <Steps.Step title={'SDK'} status={(done || this.state.currentStep === 'finished') && 'finish'} />
              <Steps.Step title={'Integration'} status={(done || this.state.currentStep === 'finished') && 'finish'} />
            </Steps>
          </div>
        </div>
        <div className='MainContainerFull'>
          <div className='MainContainerFullHero'>
            <div className='MainContainerFullHeroIcon'>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className='MainContainerFullHeroTitle'>{this.props.currentProject.name}</div>
          </div>
          <div className='MainContainerFullInner'>
            {this.state.currentStep === 0 && (
              <>
                <IntegrationTokenViewer />
                <div className={'ButtonContainer'}>
                  <Button
                    color='primary'
                    size='lg'
                    onClick={() => {
                      this.setState({ currentStep: 1 });
                    }}
                  >
                    Next Step
                  </Button>
                </div>
              </>
            )}
            {this.state.currentStep === 1 && (
              <>
                <ProjectIntegrations />
                <div className={'ButtonContainer'}>
                  <Button
                    color='primary'
                    size='lg'
                    onClick={() => {
                      this.setState({ currentStep: 'finished' });
                      this.props.updateProject(this.props.currentProject._id, { obDone: true });
                    }}
                  >
                    Finish
                  </Button>
                </div>
              </>
            )}
            {this.state.currentStep === 'finished' && (
              <div>
                <FontAwesomeIcon icon={faCheckCircle} size={'8x'} color={'red'} />
                <h1 className={'ThankYouHeader'}>You've successfully set up this project.</h1>
                <br />
                <div className={'ThankYouDesc'}>
                  <p>The individual steps can be edited at any time. Just click on the titles to the left.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setProject: projectId => {
      dispatch(setProject(projectId));
    },
    setNavRoute: route => {
      dispatch(setNavRoute(route));
    },
    push: url => {
      dispatch(push(url));
    },
    updateProject: (projectId, data) => {
      dispatch(updateProject(projectId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHomePage);
