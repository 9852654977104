import jira from '../../../helper/jira'

//RESOURCES
export const LOADING_JIRA_RESOURCES = 'LOADING_JIRA_RESOURCES';
export const loadingJiraResources = state => {
    return {
        type: LOADING_JIRA_RESOURCES,
        payload: state,
    };
};

export const LOADING_JIRA_RESOURCES_SUCCESS = 'LOADING_JIRA_RESOURCES_SUCCESS';
export const loadingJiraResourcesSuccess = resources => {
    return {
        type: LOADING_JIRA_RESOURCES_SUCCESS,
        payload: resources,
    };
};

export const LOADING_JIRA_RESOURCES_ERRROR = 'LOADING_JIRA_RESOURCES_ERRROR';
export const loadingJiraResourcesError = err => {
    return {
        type: LOADING_JIRA_RESOURCES_ERRROR,
        payload: err,
    };
};

// PROJECTS
export const LOADING_JIRA_PROJECTS = 'LOADING_JIRA_PROJECTS';
export const loadingJiraProjects = state => {
    return {
        type: LOADING_JIRA_PROJECTS,
        payload: state,
    };
};

export const LOADING_JIRA_PROJECTS_SUCCESS = 'LOADING_JIRA_PROJECTS_SUCCESS';
export const loadingJiraProjectsSuccess = projects => {
    return {
        type: LOADING_JIRA_PROJECTS_SUCCESS,
        payload: projects,
    };
};

export const LOADING_JIRA_PROJECTS_ERRROR = 'LOADING_JIRA_PROJECTS_ERRROR';
export const loadingJiraProjectsError = err => {
    return {
        type: LOADING_JIRA_PROJECTS_ERRROR,
        payload: err,
    };
};

/**
 * Load all resources form Jira
 * @param {*} conf 
 */
export const loadJiraResources = (conf) => {
    return dispatch => {
        dispatch(loadingJiraResources(true))
        jira.loadResources(conf)
            .then(res => {
                dispatch(loadingJiraResources(false));
                dispatch(loadingJiraResourcesSuccess(res))
            })
            .catch(err => {
                dispatch(loadingJiraResourcesError("there was a problem"))
            })
    };
};

export const loadJiraProjects = (resourceId, accessToken) => {
    return dispatch => {
        dispatch(loadingJiraProjects(true))
        jira.loadProjects(resourceId, accessToken)
            .then(res => {
                dispatch(loadingJiraProjects(false));
                dispatch(loadingJiraProjectsSuccess(res))
            })
            .catch(err => {
                dispatch(loadingJiraProjectsError("there was a problem"))
            })
    };
};