import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ConfigureTrelloModal.css';
import { push } from 'connected-react-router';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { updateProject } from '../../redux/actions/project/projects';
import { loadTrelloBoards, loadBoardLists, loadBoardMembers } from '../../redux/actions/project/trello';

class ConfigureTrelloModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trelloConfig: {},
    };
  }

  render() {
    const { trelloConfig } = this.state;
    const { currentProject } = this.props;

    return (
      <div className='ConfigureIntegrationModal'>
        <div className='field-container'>
          <span>Board to post to</span>
          <Select
            isLoading={this.props.trelloData.loadingTrelloBoards}
            options={this.props.trelloData.boards.map(el => {
              return { label: el.name, value: el.id };
            })}
            onMenuOpen={() => {
              this.props.loadBoards(currentProject.trello.token);
            }}
            onChange={val => {
              // load all lists for the selected board
              this.props.loadBoardLists(val.value, this.props.currentProject.trello.token);
              // load all members of the selected board
              this.props.loadBoardMembers(val.value, this.props.currentProject.trello.token);
              let config = { ...trelloConfig };
              config.boardId = val.value;
              config.boardName = val.label;
              this.setState({ trelloConfig: config });
            }}
          />
        </div>
        <div className='field-container'>
          <span>List to post to</span>
          <Select
            isLoading={this.props.trelloData.loadingBoardLists}
            options={this.props.trelloData.lists.map(el => {
              return { label: el.name, value: el.id };
            })}
            onChange={val => {
              let config = { ...trelloConfig };
              config.listId = val.value;
              config.listName = val.label;
              this.setState({ trelloConfig: config });
            }}
          />
        </div>
        <div className='field-container'>
          <span>Assign to member</span>
          <Select
            isLoading={this.props.trelloData.loadingBoardMembers}
            options={this.props.trelloData.members.map(el => {
              return { label: el.fullName, value: el.id };
            })}
            onChange={val => {
              let config = { ...trelloConfig };
              config.memberId = val.value;
              config.memberName = val.label;
              this.setState({ trelloConfig: config });
            }}
          />
        </div>
        <div className='modal-buttons'>
          <Button
            color='primary'
            disabled={!trelloConfig.boardId || !trelloConfig.listId || !trelloConfig.memberId}
            onClick={() => {
              this.props.updateProject(this.props.currentProject._id.toString(), {
                trello: {
                  ...this.state.trelloConfig,
                  token: currentProject.trello.token,
                },
              });
              this.props.closeModal();
            }}
          >
            Save
          </Button>
          <Button style={{ marginLeft: '10px' }} color='secondary' onClick={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
    trelloData: state.trello,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: url => {
      dispatch(push(url));
    },
    updateProject: (projectId, data) => {
      dispatch(updateProject(projectId, data));
    },
    loadBoards: token => {
      dispatch(loadTrelloBoards(token));
    },
    loadBoardLists: (boardId, token) => {
      dispatch(loadBoardLists(boardId, token));
    },
    loadBoardMembers: (boardId, token) => {
      dispatch(loadBoardMembers(boardId, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureTrelloModal);
