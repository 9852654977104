import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import Lightbox from 'react-images';
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import './BugDetail.css';

import BugDetailStateSelect from '../../components/BugDetailStateSelect/BugDetailStateSelect';
import { removeBug, markBugAsDuplicate } from '../../redux/actions/bug/bugs';
import BugDetailAssignToSelect from '../BugDetailAssignToSelect/BugDetailAssignToSelect';
import BugDetailPrioritySelect from '../BugDetailPrioritySelect/BugDetailPrioritySelect';
import Comments from '../Comments/Comments';
import CommentCreation from '../CommentCreation/CommentCreation';
import BugDetailsMeta from '../BugDetailsMeta/BugDetailsMeta';
import BugListSelector from '../BugListSelector/BugListSelector';

const modalStyle = {
  content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
      maxWidth: '85vw'
  }
};

class BugDetailsMainPage extends Component {
  state = {
    activeTab: "0",
    lightboxIsOpen: false,
    showMarkAsDuplicate: false
  }

  getReportedBySignature() {
    if (this.props.currentBug.reportedBy && this.props.currentBug.reportedBy.length > 2) {
      return this.props.currentBug.reportedBy.substring(0, 2);
    }
    return "n/a";
  }

  render() {
    return (
        <div className="bugdetail-container">
          <div className="bugdetail-main">
            <div className="bugdetail-header">
              <a href={ 'mailto:' + this.props.currentBug.reportedBy }>
                <div className="bugdetail-reportedBy">
                  <div className="bugdetail-reportedBy-profile">
                    { this.getReportedBySignature() }
                  </div>
                  { this.props.currentBug.reportedBy }
                </div>
              </a>
              <div className="bugdetaill-createdAt">
                <Moment format="MM/DD/YYYY">{ this.props.currentBug.createdAt }</Moment>
              </div>
            </div>
  
            <div className="bugdetail-main-data">
              { this.props.currentBug.screenshot ? 
                <div className="bugdetail-screenshot-container">
                  <img onClick={() => {
                    this.setState({
                      lightboxIsOpen: true
                    });
                  }} className="bugdetail-screenshot" src={ this.props.currentBug.screenshot } alt="bug screenshot" />
                  <Lightbox
                    images={[{ src: this.props.currentBug.screenshot }]}
                    isOpen={this.state.lightboxIsOpen}
                    preventScroll={true}
                    backdropClosesModal={true}
                    onClose={() => {
                      this.setState({
                        lightboxIsOpen: false
                      });
                    }}
                  />
                </div>
                : <div className="bugdetail-screenshot-container bugdetail-screenshot-container-none"></div>
              }
              <div className="bugdetail-description">
                <BugDetailsMeta></BugDetailsMeta>
                <div className="bugdetail-comments">
                    <CommentCreation></CommentCreation>
                    <Comments></Comments>
                </div>
              </div>
            </div>
          </div>
          <div className="bugdetail-right-sidebar">
            <div className="bugdetail-actions">
                <div className="bugdetail-actions-inner">
                  <div className="bugdetail-actions-header">
                  <div><b>Actions</b></div>
                  <div className="bugdetail-actions-header-actions">
                      <div className="bugdetail-actions-header-action" onClick={() => {
                      if (window.confirm("Do you really want to delete this bug?")) {
                          this.props.deleteBug(this.props.currentBug._id);
                      }
                      }}>
                      <FontAwesomeIcon icon={ faTrash } />
                      </div>
                  </div>
                  </div>
                  <div className="mt-10">
                    <BugDetailStateSelect></BugDetailStateSelect>
                  </div>
                  <div className="mt-8">
                    <BugDetailAssignToSelect></BugDetailAssignToSelect>
                  </div>
                  <div className="mt-8">
                    <BugDetailPrioritySelect></BugDetailPrioritySelect>
                  </div>
                  <div className="mt-16">
                    <Button size="lg" block color="primary" onClick={() => {
                      this.setState({
                        showMarkAsDuplicate: true
                      });
                    }}>
                      Mark as duplicate
                    </Button>
                    <Modal
                    isOpen={this.state.showMarkAsDuplicate}
                    contentLabel="Mark as duplicate"
                    style={ modalStyle }
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => {
                      this.setState({
                        showMarkAsDuplicate: false
                      });
                    }}
                    ariaHideApp={false}>
                      <div>
                        <BugListSelector selectBug={(bug) => {
                          this.setState({
                            showMarkAsDuplicate: false
                          });
                          this.props.markBugAsDuplicate(bug, this.props.currentBug);
                        }}></BugListSelector>
                      </div>
                    </Modal>
                  </div>
                </div>
            </div>
            <div className="bugdetail-actions">
                <div className="bugdetail-actions-inner">
                  <div className="bugdetail-actions-header">
                    <div><b>{ this.props.currentBug.duplicates ? this.props.currentBug.duplicates.length : 0 } Duplicates</b></div>
                  </div>
                  <div className="mt-10">
                  { this.props.currentBug.duplicates ? this.props.currentBug.duplicates.map((bug, index) => {
                    return (
                      <div className="bugTitleContainer">
                        <div className="bugTitle">
                          { bug.name }
                        </div>
                        <div className="bugItemDate">
                          <Moment fromNow>{ bug.createdAt }</Moment>
                        </div>
                      </div>
                    );
                  }) : <div>No duplicates yet.</div> }
                  </div>
                </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bugs: state.bug.bugs,
    selectedProject: state.projectUI.selectedProjectId,
    currentBug: state.bug.currentBug
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      deleteBug: (bugId) => {
        dispatch(removeBug(bugId));
      },
      markBugAsDuplicate: (currentBug, bug) => {
        dispatch(markBugAsDuplicate(currentBug, bug));
      }
    };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugDetailsMainPage);