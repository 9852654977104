import React, { Component } from 'react';
import CreateProjectButton from '../../components/CreateProjectButton/CreateProjectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/pro-regular-svg-icons';

class CreateProjectPage extends Component {
  render() {
    return (

      <div className="Container">
          <div className="MainContainerFull">
              <div className="MainContainerFullHero">
                  <div className="MainContainerFullHeroIcon"><FontAwesomeIcon icon={ faRocket }></FontAwesomeIcon></div>
                  <div className="MainContainerFullHeroTitle">New project</div>
                  <div className="MainContainerFullHeroBody">
                      <div className="MainContainerFullHeroBodyTitle">Do you need help?</div>
                      <div className="MainContainerFullHeroBodyLinks"><a href="#" onClick={() => {
                        window.Intercom('showNewMessage');
                      }}>Contact us!</a></div>
                  </div>
              </div>
              <div className="MainContainerFullInner center">
                <CreateProjectButton></CreateProjectButton>
              </div>
          </div>
      </div>
    );
  }
}

export default CreateProjectPage;