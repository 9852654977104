import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import './Logs.css';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/pro-regular-svg-icons';

class Logs extends Component {
  render() {
    return (
      <div className="callstack-info-container">
          <div className="callstack-info-container-body">
          { this.props.currentConsoleLog && this.props.currentConsoleLog.map((value, index) => {
              return (
                  <div className="console-log" key={ index }>
                      <div className="console-log-icon-container">
                          <div className="console-log-icon-container-inner">
                              <div className="console-log-icon">
                                <FontAwesomeIcon icon={ faTerminal } />
                              </div>
                          </div>
                      </div>
                      <div className="console-log-header">
                          <div className="console-log-header-title">Console</div>
                          <div className="console-log-header-date"><Moment format="hh:mm A">{ value.date }</Moment></div>
                      </div>
                      <div className="console-log-body">{ value.log }</div>
                  </div>
              )
          })}
          { (!this.props.currentConsoleLog || this.props.currentConsoleLog.length === 0) && 
              <div className="bug-detail-no-data-how-to"><span role="img" aria-label="ufo">🛸</span> No console logs captured.</div>
          }
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentConsoleLog: state.bug.currentConsoleLog
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => {
      dispatch(push(url));
    }
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logs);