import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Input } from 'reactstrap';
import './UserSettings.css';

import { updateUser } from '../../redux/actions/user/users';

class UserSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      billingName: "",
      street: "",
      city: "",
      country: "",
      vatId: "",
      zip: ""
    }
  }

  componentDidMount() {
    if (this.state.name === "") {
      setTimeout(() => {
        this.setState({
          name: this.props.currentUser.name ? this.props.currentUser.name : "",
          billingName: this.props.currentUser.billingName ? this.props.currentUser.billingName : "",
          street: this.props.currentUser.street ? this.props.currentUser.street : "",
          city: this.props.currentUser.city ? this.props.currentUser.city : "",
          zip: this.props.currentUser.zip ? this.props.currentUser.zip : "",
          country: this.props.currentUser.country ? this.props.currentUser.country : "",
          vatId: this.props.currentUser.vatId ? this.props.currentUser.vatId : ""
        });
      }, 500);
    }
  }

  save() {
    this.props.updateUser(this.props.currentUser._id, {
      name: this.state.name,
      billingName: this.state.billingName,
      street: this.state.street,
      city: this.state.city,
      zip: this.state.zip,
      country: this.state.country,
      vatId: this.state.vatId,
    });
    toast.success("Account information saved!", {
        position: toast.POSITION.TOP_RIGHT
    });

    // Update user infos also on stripe.
    fetch('https://payment.bugbattle.io/update-customer-data.php', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          billingCustomerId: this.props.currentUser.billingCustomerId,
          name: this.state.name,
          billingName: this.state.billingName,
          street: this.state.street,
          city: this.state.city,
          zip: this.state.zip,
          country: this.state.country,
          vatId: this.state.vatId
        })
    }).then((data) => {
        data.json().then(json => {
          
        });
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    return (
      <div>
        <div className="box-content mb-32">
          <div className="user-settings-container">
            <div className="projectheader ">
                <h1>Account info</h1>
            </div>
            <div className="subtitle-text">Hey { this.props.currentUser.name } 👋</div>
            <Input placeholder="Your name" type="text" value={ this.state.name } onChange={(event) => {
              this.setState({ name: event.target.value })
            }} />
            <Input placeholder="Billing name" type="text" value={ this.state.billingName } onChange={(event) => {
              this.setState({ billingName: event.target.value })
            }} />
            <Input placeholder="Street" type="text" value={ this.state.street } onChange={(event) => {
              this.setState({ street: event.target.value })
            }} />
            <Input placeholder="ZIP code" type="text" value={ this.state.zip } onChange={(event) => {
              this.setState({ zip: event.target.value })
            }} />
            <Input placeholder="City" type="text" value={ this.state.city } onChange={(event) => {
              this.setState({ city: event.target.value })
            }} />
            <Input placeholder="Country" type="text" value={ this.state.country } onChange={(event) => {
              this.setState({ country: event.target.value })
            }} />
            <Input placeholder="VAT ID" type="text" value={ this.state.vatId } onChange={(event) => {
              this.setState({ vatId: event.target.value })
            }} />
            <Button disabled={ this.state.name === "" } color="primary" onClick={this.save.bind(this)}>
              Save
            </Button>
            <div className="mt-32">
              <div className="projectheader ">
                  <h1>Danger zone ⛔️</h1>
              </div>
              <span>Time to say goodbye?</span><br />
              <a href="mailto:deleterequest@bugbattle.io">Delete account</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId, data) => {
        dispatch(updateUser(userId, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);