import React, { Component } from 'react';
import { faClipboardListCheck, faHelmetBattle, faHammer, faTrophy, faColumns, faHome } from '@fortawesome/pro-regular-svg-icons';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Confetti from 'react-dom-confetti';
import Tooltip from 'rc-tooltip';
import './MainSidebar.css';

import { loadProjects, fetchProjectDone } from '../../redux/actions/project/projects';
import { setProject, selectProject } from '../../redux/actions/project/projectUI';
import logo from './BugBattleLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MongoClient from '../../mongodb/MongoClient';
import { loadCurrentUser } from '../../redux/actions/user/users';

class MainSidebar extends Component {
  componentDidMount() {
    if (MongoClient.getInstance().getClient().auth.isLoggedIn) {
      this.props.loadCurrentUser();
      this.props.loadProjects();
    }
  }

  getPointsForCurrentProject() {
    if (!this.props.currentUser.points) {
      return 0;
    }
    let projectId = this.props.currentProject._id.toHexString();
    if (!this.props.currentUser.points[projectId]) {
      return 0;
    }
    return this.props.currentUser.points[projectId]["overall"];
  }

  renderUserMenu() {
    return (
      <div className="mainsidebar--userinfo-container">
        {this.props.currentProject._id && <div className="mainsidebar--userinfo-trophy">
          <span className="mainsidebar--userinfo-trophy-icon"><FontAwesomeIcon icon={faTrophy} /></span>
          <span className="mainsidebar--userinfo-trophy-points">{this.getPointsForCurrentProject()}</span>
        </div>}
        <div className="mainsidebar--userinfo" onClick={() => {
          this.props.push("/profile");
        }}>
          <img src={this.props.currentUser.avatar} alt="Avatar" />
          <Confetti active={this.props.pointsUpdated} config={{
            angle: 60,
            spread: 40,
            startVelocity: 55,
            elementCount: 60,
            decay: 0.9
          }} />
        </div>
      </div>
    )
  }

  renderProjectMenu() {
    return (<div className="mainsidebar--items">
      <Tooltip placement="left" mouseEnterDelay={0.15} overlay={<span>Home</span>}>
        <div className={`mainsidebar--item mainsidebar--item__first ${this.props.currentRoute === '/' ? 'mainsidebar--item--active' : ''}`} onClick={() => {
          this.props.push('/project/' + this.props.currentProject._id + '/');
        }}>
          <FontAwesomeIcon icon={faHome} />
        </div>
      </Tooltip>


      <Tooltip placement="left" mouseEnterDelay={0.15} overlay={<span>Bugs</span>}>
        <div className={`mainsidebar--item ${this.props.currentRoute === 'list' ? 'mainsidebar--item--active' : ''}`} onClick={() => {
          this.props.push('/project/' + this.props.currentProject._id + '/list');
        }}>
          <FontAwesomeIcon icon={faClipboardListCheck} />
        </div>
      </Tooltip>



      <Tooltip placement="left" mouseEnterDelay={0.15} overlay={<span>Board</span>}>
        <div className={`mainsidebar--item ${this.props.currentRoute === 'board' ? 'mainsidebar--item--active' : ''}`} onClick={() => {
          this.props.push('/project/' + this.props.currentProject._id + '/board');
        }}>
          <FontAwesomeIcon icon={faColumns} />
        </div>
      </Tooltip>
      <Tooltip placement="left" mouseEnterDelay={0.15} overlay={<span>Battle</span>}>
        <div className={`mainsidebar--item ${this.props.currentRoute === 'battle' ? 'mainsidebar--item--active' : ''}`} onClick={() => {
          this.props.push('/project/' + this.props.currentProject._id + '/battle');
        }}>
          <FontAwesomeIcon icon={faHelmetBattle} />
        </div>
      </Tooltip>
      <Tooltip placement="left" mouseEnterDelay={0.15} overlay={<span>Settings</span>}>
        <div className={`mainsidebar--item ${this.props.currentRoute === 'settings' ? 'mainsidebar--item--active' : ''}`} onClick={() => {
          this.props.push('/project/' + this.props.currentProject._id + '/settings');
        }}>
          <FontAwesomeIcon icon={faHammer} />
        </div>
      </Tooltip>
    </div>)
  }

  renderProjectList() {
    return (<div className="mainsidebar--items"></div>)
  }

  render() {
    if (!this.props.currentUser || !this.props.currentUser._id) {
      return (<div></div>);
    }
    return (
      <div className="mainsidebar--container">
        <div className="mainsidebar--logo" onClick={() => {
          if (this.props.currentUser._id) {
            this.props.resetProject();
            this.props.push('/dashboard');
          } else {
            this.props.push('/login');
          }
        }}>
          <img src={logo} alt="BugBattle Logo" />
        </div>

        {this.props.currentProject._id ? this.renderProjectMenu() : this.renderProjectList()}
        <div className="mainsidebar--spacer"></div>
        {this.props.currentUser._id && this.renderUserMenu()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
    currentUser: state.user.currentUser,
    pointsUpdated: state.user.pointsUpdated,
    currentRoute: state.projectUI.currentRoute
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadProjects: () => {
      dispatch(loadProjects());
    },
    push: (url) => {
      dispatch(push(url));
    },
    setProject: (projectId) => {
      dispatch(setProject(projectId));
    },
    resetProject: () => {
      dispatch(selectProject(null));
      dispatch(fetchProjectDone({
        _id: null,
        bugStatusList: []
      }));
    },
    loadCurrentUser: () => {
      dispatch(loadCurrentUser());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSidebar);