import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import Modal from 'react-modal';
import './IntegrationTokenViewer.css';

import iOSLogo from './apple.png';
import AndroidLogo from './android.png';
import JavaScriptLogo from './javascript.png';
import { showIntegrationModal } from '../../redux/actions/project/projects';
import IosIntegrationTutorialModal from '../IosIntegrationTutorialModal/IosIntegrationTutorialModal';
import AndroidIntegrationTutorialModal from '../AndroidIntegrationTutorialModal/AndroidIntegrationTutorialModal';
import JSIntegrationTutorialModal from '../JSIntegrationTutorialModal/JSIntegrationTutorialModal';

const bugDetailModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
    maxWidth: '85vw',
  },
};

class IntegrationTokenViewer extends Component {
  getProjectToken() {
    if (!this.props.currentProject._id) {
      return '';
    }
    return this.props.currentProject._id.toString();
  }

  render() {
    return (
      <div className='sdk-container'>
        <Modal
          isOpen={this.props.showIntegrationTutorial}
          contentLabel='Bug Detail Modal'
          style={bugDetailModalStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {
            this.props.showIntegrationModal(false, '');
          }}
          ariaHideApp={false}
        >
          <div>{this.props.showIntegrationTutorialType}</div>
          {this.props.showIntegrationTutorialType === 'ios' && <IosIntegrationTutorialModal></IosIntegrationTutorialModal>}
          {this.props.showIntegrationTutorialType === 'android' && <AndroidIntegrationTutorialModal></AndroidIntegrationTutorialModal>}
          {this.props.showIntegrationTutorialType === 'js' && <JSIntegrationTutorialModal></JSIntegrationTutorialModal>}
          <div
            className='bug-modal-close-btn-modally'
            onClick={() => {
              this.props.showIntegrationModal(false, '');
            }}
          >
            <FontAwesomeIcon color='#fff' icon={faTimes} />
          </div>
        </Modal>
        <div className=''>
          <div className='projectheader'>
            <h1>Getting started</h1>
          </div>
          <div className='subtitle-text'>
            You're only one step away from building better apps! Get started by integrating the SDK.
            <br />
            It's super easy and takes less than a minute. Here is your integration token:
          </div>
          <div className='integration-token-viewer-code'>{this.getProjectToken()}</div>
        </div>
        <Row>
          <Col xs='4' sm='4'>
            <div className='IntegrationCardNew'>
              <img className='IntegrationCardLogo' src={iOSLogo} alt='iOS Integration' />
              <div className='IntegrationCardContent'>
                <div className='IntegrationCardContentTitle'>iOS SDK</div>
              </div>
              <Button
                size='lg'
                color='primary'
                onClick={() => {
                  this.props.showIntegrationModal(true, 'ios');
                }}
              >
                Get started
              </Button>
            </div>
          </Col>
          <Col xs='4' sm='4'>
            <div className='IntegrationCardNew IntegrationCard-Disabled'>
              <img className='IntegrationCardLogo' src={AndroidLogo} alt='Android Integration' />
              <div className='IntegrationCardContent'>
                <div className='IntegrationCardContentTitle'>Android SDK</div>
              </div>
              <Button
                size='lg'
                color='primary'
                onClick={() => {
                  this.props.showIntegrationModal(true, 'android');
                }}
              >
                Get started
              </Button>
            </div>
          </Col>
          <Col xs='4' sm='4'>
            <div className='IntegrationCardNew IntegrationCard-Disabled'>
              <img className='IntegrationCardLogo' src={JavaScriptLogo} alt='JavaScript Integration' />
              <div className='IntegrationCardContent'>
                <div className='IntegrationCardContentTitle'>JavaScript SDK</div>
              </div>
              <Button
                size='lg'
                color='primary'
                onClick={() => {
                  this.props.showIntegrationModal(true, 'js');
                }}
              >
                Get started
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
    showIntegrationTutorial: state.project.showIntegrationTutorial,
    showIntegrationTutorialType: state.project.showIntegrationTutorialType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showIntegrationModal: (show, integration) => {
      dispatch(showIntegrationModal(show, integration));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationTokenViewer);
