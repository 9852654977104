import React, { Component } from 'react';
import { connect } from 'react-redux';
import './UserListItem.css'

import { loadUser } from '../../redux/actions/user/users';

class UserListItem extends Component {
  getUser() {
    var user = this.props.users[this.props.userId];
    if (user) {
      return user;
    }
    return {
      _id: null,
      name: "",
      avatar: null,
      email: ""
    };
  }

  render() {
    var user = this.getUser();
    return (
        <div className="user-item-list">
          { user.avatar ? <img alt="Avatar" src={ user.avatar }></img> : <div className="user-item-list-img-loading"></div> }
          <div>{ user.name ? user.name : 'N/A' }</div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.user.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: (userId) => {
      dispatch(loadUser(userId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListItem);