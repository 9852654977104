import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './QuickProjectSelector.css';

import { loadProjects, fetchProjectDone } from '../../redux/actions/project/projects';
import { setProject, selectProject } from '../../redux/actions/project/projectUI';
import { selectBug } from '../../redux/actions/bug/bugs';

class QuickProjectSelector extends Component {
  render() {
    if (!this.props.currentUser || !this.props.currentUser._id || !this.props.currentProject._id || !this.props.currentProject._id.toString()) {
      return (<div></div>);
    }
    return (
        <div className="project-selector-top">
          <select value={this.props.currentProject._id.toString()} onChange={(e) => {
            this.props.setProject(e.target.value);
          }}>
              {
                this.props.projects.map((project, index) => {
                  return (<option key={index} value={project._id.toString()}>{project.name}</option>);
                })
              }
          </select>
          <FontAwesomeIcon icon={ faChevronDown }></FontAwesomeIcon>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.project.projects,
    currentUser: state.user.currentUser,
    currentProject: state.project.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadProjects: () => {
      dispatch(loadProjects());
    },
    push: (url) => {
      dispatch(push(url));
    },
    setProject: (projectId) => {
      dispatch(selectBug({
        _id: null
      }));
      dispatch(setProject(projectId));
    },
    resetProject: () => {
      dispatch(selectProject(null));
      dispatch(fetchProjectDone({
          _id: null,
          bugStatusList: []
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickProjectSelector);