import { SET_CURRENT_USER, RESOLVE_USER, RESOLVE_USER_DONE, UPDATE_USER_POINTS, UPDATE_USER_ACTION, UPDATED_USER_POINTS, SHOW_ADD_CARD_MODAL } from "../../actions/user/users";
import { getWeekIdentifier } from "../../../helper/bugdetails";

const initialState = {
    loading: false,
    currentUser: {
        _id: null,
        name: null,
        email: null,
        avatar: null,
        owner: null,
        points: {}
    },
    pointsUpdated: false,
    showAddCardModal: false,
    users: {}
};

export default function users(state = initialState, action) {
    if (action.type === SET_CURRENT_USER) {
        return Object.assign({}, state, {
            loading: false,
            currentUser: action.user
        });
    }
    if (action.type === UPDATE_USER_ACTION) {
        let newState = Object.assign({}, state, {});
        if (action.userId === state.currentUser._id) {
            newState.currentUser = Object.assign({}, state.currentUser, action.data);
        }
        let userKeys = Object.keys(newState.users);
        for (let i = 0; i < userKeys.length; i++) {
            let userToUpdate = newState.users[userKeys[i]];
            if (userToUpdate && userToUpdate._id === action.userId) {
                newState.users[userKeys[i]] = Object.assign({}, userToUpdate, action.data);
            }
        }
        return newState;
    }
    if (action.type === UPDATED_USER_POINTS) {
        return Object.assign({}, state, {
            pointsUpdated: false
        });
    }
    if (action.type === SHOW_ADD_CARD_MODAL) {
        return Object.assign({}, state, {
            showAddCardModal: action.show
        });
    }
    if (action.type === UPDATE_USER_POINTS) {
        let newState = Object.assign({}, state, {});
        let projectId = action.projectId;
        let weekIdentifier = getWeekIdentifier();
        if (action.userId === state.currentUser.owner) {
            if (!newState.currentUser.points) {
                newState.currentUser.points = {};
            }
            if (newState.currentUser.points[projectId] && newState.currentUser.points[projectId]["overall"]) {
                newState.currentUser.points[projectId]["overall"] += action.bugpoints;
            } else {
                newState.currentUser.points[projectId] = {};
                newState.currentUser.points[projectId]["overall"] = action.bugpoints;
            }
            if (newState.currentUser.points[projectId] && newState.currentUser.points[projectId][weekIdentifier]) {
                newState.currentUser.points[projectId][weekIdentifier] += action.bugpoints;
            } else {
                newState.currentUser.points[projectId][weekIdentifier] = action.bugpoints;
            }
            newState.pointsUpdated = true;
        }
        let userKeys = Object.keys(newState.users);
        for (let i = 0; i < userKeys.length; i++) {
            let userToUpdate = newState.users[userKeys[i]];
            if (userToUpdate && userToUpdate.owner === action.userId) {
                if (!userToUpdate.points) {
                    userToUpdate.points = {};
                }
                if (userToUpdate.points[projectId] && userToUpdate.points[projectId]["overall"]) {
                    userToUpdate.points[projectId]["overall"] += action.bugpoints;
                } else {
                    userToUpdate.points[projectId] = {};
                    userToUpdate.points[projectId]["overall"] = action.bugpoints;
                }
                if (userToUpdate.points[projectId] && userToUpdate.points[projectId][weekIdentifier]) {
                    userToUpdate.points[projectId][weekIdentifier] += action.bugpoints;
                } else {
                    userToUpdate.points[projectId][weekIdentifier] = action.bugpoints;
                }
            }
        }
        return newState;
    }
    if (action.type === RESOLVE_USER) {
        let newUsersObject = Object.assign({}, state.users);
        newUsersObject[action.userId] = {
            _id: action.userId,
            name: null,
            email: null,
            avatar: null
        };
        return Object.assign({}, state, {
            users: newUsersObject
        });
    }
    if (action.type === RESOLVE_USER_DONE) {
        let newUsersObject = Object.assign({}, state.users);
        newUsersObject[action.userId] = action.user;
        return Object.assign({}, state, {
            users: newUsersObject
        });
    }
    return state;
}  