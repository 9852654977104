import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { FixedSizeList as List } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import './BugListSelector.css'; 
import { getStorypoints, getPriority } from '../../helper/bugdetails';
import UserSmall from '../UserSmall/UserSmall';

class BugListSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowHeight: window.innerHeight
    };
  }

  updateDimensions() {
    this.setState({
      windowHeight: window.innerHeight
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  getStatusColorForStatusKey(statusKey) {
    var item = this.props.bugStatusObject[statusKey];
    if (item) {
      return item.color;
    }
    return '#888';
  }

  renderNoBugs() {
    return (<div>
      No bugs reported yet.
    </div>);
  }

  render() {
    let currentBug = this.props.currentBug;
    let bugsList = _.reject(this.props.bugs, function(el) {
      if (!currentBug) {
        return false;
      }
      return currentBug._id === el._id
    });

    return (
      <div className="buglistSelector">
        {
          (!this.props.loadingBugs && bugsList.length <= 0) ? this.renderNoBugs() : <List
          height={ (this.state.windowHeight - 107) }
          itemCount={ bugsList.length }
          itemSize={ 100 }>
          {({ index, style }) => {
            let bug = bugsList[index];
            let bugStorypoint = getStorypoints(bug);
            let bugPriorities = getPriority(bug["priority"], this.props.bugPriorities);
            return (
              <div className="bug" style={ style } key={ bug._id } onClick={() => {
                this.props.selectBug(bug);
              }}>
                <div className="bugTitleContainer">
                  <div className="bugTitle">
                    <div className="bugStatusBubbleInline" style={{
                      backgroundColor: this.getStatusColorForStatusKey(bug.status)
                    }}></div>
                    { bug.name }
                  </div>
                  <div className="bugItemDate">
                    <Moment fromNow>{ bug.createdAt }</Moment>
                  </div>
                </div>
                <div className="bugDescription">{ bug.description }</div>
                <div className="bugStateContainer">
                  <div className="bugStorypointContainer">
                    <span>{ bugStorypoint }</span>
                    <span className="bugStorypointIcon ml-8"><FontAwesomeIcon icon={ bugPriorities.icon } /></span>
                    <span>{ bugPriorities.name }</span>
                  </div>
                  <div className="bugStorypointContainer bugStorypointContainerFlex">
                    <UserSmall userId={ bug.assignedTo }></UserSmall>
                  </div>
                </div>
              </div>
            )
          }}
        </List>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bugs: state.bug.bugs,
    selectedProject: state.projectUI.selectedProjectId,
    currentBug: state.bug.currentBug,
    bugStatusObject: state.bug.bugStatusObject,
    currentBugListFilter: state.bug.currentBugListFilter,
    bugStorypoints: state.bug.bugStorypoints,
    bugPriorities: state.bug.bugPriorities,
    loadingBugs: state.bug.loadingBugs,
    currentProject: state.project.currentProject
  };
};

export default connect(
  mapStateToProps,
  null
)(BugListSelector);