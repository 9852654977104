import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ConfigureJiraModal.css';
import { push } from 'connected-react-router';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { updateProject } from '../../redux/actions/project/projects';
import { loadJiraResources, loadJiraProjects } from '../../redux/actions/project/jira';

class ConfigureJiraModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jiraConfig: {},
    };
  }

  render() {
    const { jiraConfig } = this.state;
    return (
      <div className='ConfigureIntegrationModal'>
        <div className='field-container'>
          <span>Resource to post to</span>
          <Select
            isLoading={this.props.jiraData.loadingJiraResources}
            options={this.props.jiraData.resources.map(el => {
              return { label: el.name, value: el.id };
            })}
            onMenuOpen={() => {
              this.props.loadJiraResources(this.props.currentProject.jira);
            }}
            onChange={val => {
              this.props.loadJiraProjects(val.value, this.props.currentProject.jira.access_token);
              let config = { ...jiraConfig };
              config.resourceId = val.value;
              config.resourceName = val.label;
              this.setState({ jiraConfig: config });
            }}
          />
        </div>
        <div className='field-container'>
          <span>Project</span>
          <Select
            isLoading={this.props.jiraData.loadingJiraProjects}
            isDisabled={!jiraConfig.resourceId}
            options={this.props.jiraData.projects.map(el => {
              return { label: el.name, value: el.id };
            })}
            onChange={val => {
              let config = { ...jiraConfig };
              config.projectId = val.value;
              config.projectName = val.label;
              this.setState({ jiraConfig: config });
            }}
          />
        </div>
        <div className='field-container'>
          <span>Issue Type</span>
          <Select
            isLoading={this.props.jiraData.loadingJiraIssueTypes}
            isDisabled={!this.state.jiraConfig.projectId}
            options={this.props.jiraData.projects
              .filter(project => {
                return project.id === this.state.jiraConfig.projectId;
              })
              .map(project => {
                return project.issueTypes.map(el => {
                  return { label: el.name, value: el.id };
                });
              })
              .flat()}
            onChange={val => {
              let config = { ...jiraConfig };
              config.issueTypeId = val.value;
              config.issueTypeName = val.label;
              this.setState({ jiraConfig: config });
            }}
          />
        </div>
        <div className='modal-buttons'>
          <Button
            color='primary'
            disabled={!jiraConfig.resourceId || !jiraConfig.projectId || !jiraConfig.issueTypeId}
            onClick={() => {
              this.props.closeModal();
              this.props.updateProject(this.props.currentProject._id.toString(), {
                jira: {
                  ...this.state.jiraConfig,
                  access_token: this.props.currentProject.jira.access_token,
                  refresh_token: this.props.currentProject.jira.refresh_token,
                },
              });
            }}
          >
            Save
          </Button>
          <Button color='secondary' onClick={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
    jiraData: state.jira,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: url => {
      dispatch(push(url));
    },
    loadJiraResources: conf => {
      dispatch(loadJiraResources(conf));
    },
    loadJiraProjects: (resourceId, accessToken) => {
      dispatch(loadJiraProjects(resourceId, accessToken));
    },
    updateProject: (projectId, data) => {
      dispatch(updateProject(projectId, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureJiraModal);
