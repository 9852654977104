import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { createBrowserHistory } from 'history';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { StripeProvider } from 'react-stripe-elements';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./redux/store/index";

const history = createBrowserHistory();
history.listen(function (location) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'Pageview',
        'url': location.pathname + location.search
    });
});
const appStore = store(history);

ReactDOM.render(
    <Provider store={ appStore }>
        <DragDropContextProvider backend={HTML5Backend}>
            <StripeProvider apiKey="pk_live_RNNsF5YO1QgAfNe8MH7O8H2Z">
                <App history={history} />
            </StripeProvider>
        </DragDropContextProvider>
    </Provider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();