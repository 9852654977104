import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { toast } from 'react-toastify';
import './ProjectMetaSettings.css';

import { updateProject, removeProject } from '../../redux/actions/project/projects';

class ProjectMetaSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectName: ""
    }
  }

  componentDidMount() {
    this.setState({
      projectName: this.props.currentProject.name
    });
  }

  save() {
    this.props.updateProject(this.props.currentProject._id, {
      name: this.state.projectName
    });
    toast.success("Project updated successfully!", {
        position: toast.POSITION.TOP_RIGHT
    });
  }

  deleteProject() {
    if (this.canDeleteProject()) {
      if (!window.confirm("Do you really want to remove this project?")) {
        return;
      }
      
      this.props.removeProject(this.props.currentProject._id);
    } else {
      if (!window.confirm("Please cancel your billing plan first!")) {
        return;
      }
    }
  }

  canDeleteProject() {
    if (!this.props.currentProject.currentPlan) {
      return true;
    }
    if (this.props.currentProject.currentPlan === "free") {
      return true;
    }
    if (!this.props.currentProject.billingSubscription) {
      return true;
    }
    if (this.props.currentProject.billingSubscription.status !== "active") {
      return true;
    }
    if (this.props.currentProject.billingSubscription.cancel_at_period_end === true) {
      return true;
    }
    return false;
  }
  
  render() {
    return (
        <div className="">
          <div className="box-content mb-32">
            <div className="projectheader ">
                <h1>Project settings</h1>
            </div>
            <div className="subtitle-text">All project settings in one place.</div>
            <InputGroup>
              <Input type="text" value={ this.state.projectName } onChange={(event) => {
                this.setState({ projectName: event.target.value })
              }} />
              <InputGroupAddon addonType="append">
                <Button disabled={ this.state.projectName === "" } color="primary" onClick={this.save.bind(this)}>
                  Save
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <div className="project-delete-link">
              <a href="#" onClick={this.deleteProject.bind(this)}>
                Delete project
              </a>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (projectId, data) => {
        dispatch(updateProject(projectId, data));
    },
    removeProject: (projectId) => {
      dispatch(removeProject(projectId))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectMetaSettings);