import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import { BarLoader } from 'react-spinners';
import Confetti from 'react-dom-confetti';
import { toast } from 'react-toastify';
import './BillingPlanSubscriptions.css';

import { updateUser } from '../../redux/actions/user/users';
import { loadProject, updateProjectData } from '../../redux/actions/project/projects';
import BillingSettings from '../../components/BillingSettings/BillingSettings';

class BillingPlanSubscriptions extends Component {
    state = {
        loading: false,
        planUpdated: false,
        renderAddCard: false
    }

    upgradePlan(plan) {
        if (plan !== "free" && !this.props.currentUser.billingCustomerCard) {
            alert("Please link a credit card to your profile first.");
            return;
        }
        
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.props.loadProject(this.props.currentProject._id);
            setTimeout(() => {
                this.setState({
                    loading: false
                });
                toast.success("Billing plan for " + this.props.currentProject.name + " has been updated successfully!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                setTimeout(() => {
                    this.setState({
                        planUpdated: true
                    });
                }, 1000);
            }, 1000);
        }, 12000);
        this.fetchPlanUpgrade(plan);
    }

    fetchPlanUpgrade(plan) {
        fetch('https://payment.bugbattle.io/process-subscription.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: this.props.currentUser._id.toHexString(),
                customerId: this.props.currentUser.billingCustomerId,
                projectId: this.props.currentProject._id.toHexString(),
                plan: plan
            })
        }).then((data) => {
            data.json().then(json => {
                // Update internal structure.
            });
        }).catch((err) => {

        });
    }

    cancelPlan(cancel) {
        this.setState({
            loading: true
        });
        fetch('https://payment.bugbattle.io/manage-subscription.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: this.props.currentUser._id.toHexString(),
                customerId: this.props.currentUser.billingCustomerId,
                projectId: this.props.currentProject._id.toHexString(),
                cancel: cancel
            })
        }).then((data) => {
            data.json().then(json => {
                // Locally reflect changes.
                let billingSubscription = Object.assign({}, this.props.currentProject.billingSubscription, {
                    cancel_at_period_end: cancel
                });
                this.props.updateProjectData({
                    billingSubscription
                });
                this.setState({
                    loading: false
                });
            });
        }).catch((err) => {

        });
    }

    renderPlans() {
        if (this.state.loading) {
            return (
                <div className="loading-plan-container">
                    <div className="loading-plan">We are updating your plan.<br />This might take up to 30 seconds.</div>
                    <BarLoader
                        sizeUnit={"px"}
                        width={ 100 }
                        height={ 4 }
                        color={'#3574F3'}
                        loading={ true }
                        />
                </div>
            );
        }

        return(
            <div className="project-plans">
                <Row>
                    <Col xs="4" sm="4">
                        <div className="IntegrationCardNew mt-0">
                            <div className="BillingCardContent">
                                <div className="BillingCardContentTitle">Free</div>
                                <div>
                                    Unlimited bug reports<br />
                                    1 user<br />
                                    7 days data retention<br />
                                    Integrations
                                </div>
                                <div className="BillingCardContentPrice">
                                    0.00$ / month
                                </div>
                                { (this.props.currentProject.currentPlan === "free") ?
                                    <div style={{ width: '100%' }}>
                                        <div className="current-plan">Current plan</div>
                                    </div>
                                    :
                                    <Button size="lg" block color="primary" onClick={() => {
                                        this.upgradePlan("free");
                                    }}>
                                    Select plan
                                    </Button>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs="4" sm="4">
                        <div className="IntegrationCardNew mt-0">
                            <div className="BillingCardContent">
                                <div className="BillingCardContentTitle">Startup</div>
                                <div>
                                    Unlimited bug reports<br />
                                    5 users<br />
                                    6 months data retention<br />
                                    Integrations
                                </div>
                                <div className="BillingCardContentPrice">
                                    14.90$ / month
                                </div>
                                { (this.props.currentProject.currentPlan === "startup") ?
                                    <div style={{ width: '100%' }}>
                                        <div className="current-plan">Current plan</div>
                                        {
                                            this.props.currentProject.billingSubscription.cancel_at_period_end ?
                                            <div className="next-payment">Your subscription will be canceled <Moment fromNow>{ new Date(this.props.currentProject.billingSubscription.current_period_end * 1000) }</Moment>.</div>
                                            :
                                            <div className="next-payment">Next payment <Moment fromNow>{ new Date(this.props.currentProject.billingSubscription.current_period_end * 1000) }</Moment>.</div>    
                                        }
                                    </div>
                                    :
                                    <Button size="lg" block color="primary" onClick={() => {
                                        this.upgradePlan("startup");
                                    }}>
                                    Select plan
                                    </Button>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs="4" sm="4">
                        <div className="IntegrationCardNew mt-0">
                            <div className="BillingCardContent">
                                <div className="BillingCardContentTitle">Premium</div>
                                <div>
                                    Unlimited bug reports<br />
                                    Unlimited users<br />
                                    12 months data retention<br />
                                    Integrations
                                </div>
                                <div className="BillingCardContentPrice">
                                    29.90$ / month
                                </div>
                                { (this.props.currentProject.currentPlan === "premium") ?
                                    <div style={{ width: '100%' }}>
                                        <div className="current-plan">Current plan</div>
                                        {
                                            this.props.currentProject.billingSubscription.cancel_at_period_end ?
                                            <div className="next-payment">Your subscription will be canceled <Moment fromNow>{ new Date(this.props.currentProject.billingSubscription.current_period_end * 1000) }</Moment>.</div>
                                            :
                                            <div className="next-payment">Next payment <Moment fromNow>{ new Date(this.props.currentProject.billingSubscription.current_period_end * 1000) }</Moment>.</div>    
                                        }
                                    </div>
                                    :
                                    <Button size="lg" block color="primary" onClick={() => {
                                        this.upgradePlan("premium");
                                    }}>
                                    Select plan
                                    </Button>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                <Confetti active={ this.state.planUpdated } config={{
                  angle: 45,
                  spread: 40,
                  startVelocity: 35,
                  elementCount: 60,
                  decay: 0.9
                }}/>
                {
                    this.props.currentProject.billingSubscription && !this.props.currentProject.billingSubscription.cancel_at_period_end && <div className="cancelSubscription">
                    <a href="#" onClick={() => {
                        if (window.confirm("Do you really want to cancel your subscription? If there is anywhing we can do for you, just let us know!")) {
                            this.cancelPlan(true);
                        }
                    }}>Cancel subscription</a>
                </div>
                }
                {
                    this.props.currentProject.billingSubscription && this.props.currentProject.billingSubscription.cancel_at_period_end && <div className="resubscribeSubscription">
                    <a href="#" onClick={() => {
                        this.cancelPlan(false);
                    }}>Re-subscribe to current plan.</a>
                </div>
                }
            </div>
        );
    }

    renderAddCard() {
        return(
            <div className="mb-32">
                <BillingSettings></BillingSettings>
            </div>
        );
    }

    render() {
        return (
            <div className="mb-16">
                { (this.props.currentUser.id && !this.props.currentUser.billingCustomerCard) && this.renderAddCard() }
                <div className="projectheader ">
                    <h1>{ this.state.loading ? "🤠" : "Plans" }</h1>
                </div>
                <div className="subtitle-text">Choose the plan that suits you best!</div>
                { this.renderPlans() }
            </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
    currentProject: state.project.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId, data) => {
        dispatch(updateUser(userId, data));
    },
    loadProject: (projectId) => {
        dispatch(loadProject(projectId));
    },
    updateProjectData: (data) => {
        dispatch(updateProjectData(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingPlanSubscriptions);