import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card } from 'reactstrap';
import './ProjectIntegrations.css';
import Modal from 'react-modal';
import ConfigureJiraModal from '../ConfigureJiraModal/ConfigureJiraModal';
import ConfigureTrelloModal from '../ConfigureTrelloModal/ConfigureTrelloModal';
import TrelloClient, { Trello } from 'react-trello-client';
import JiraLogo from '../../assets/jira-logo.jpg';
import TrelloLogo from '../../assets/trello-logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { updateProject } from '../../redux/actions/project/projects';
import { connectJiraToProject, removeJiraFromProject, connectTrelloToProject, removeTrelloFromProject } from '../../redux/actions/project/projects';

let jiraAuthURL =
  'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=eHhYmZTjlNDeZyT7R5xOU0OZ8xNztBl3&scope=read%3Ajira-work%20write%3Ajira-work%20offline_access&redirect_uri=https%3A%2F%2Fwebhooks.mongodb-stitch.com%2Fapi%2Fclient%2Fv2.0%2Fapp%2Fbugbattle-xfblb%2Fservice%2FJira%2Fincoming_webhook%2Fauth&response_type=code&prompt=consent&state=';

const trelloOpts = {
  type: 'popup',
  name: 'BugBattle',
  persist: false,
  scope: { read: true, write: true, account: false },
  expiration: 'never',
};

const bugDetailModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class ProjectIntegrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bugStatusList: null,
      newStatusName: '',
      newStatusColor: '#0693E3',
      showColorPicker: false,
      modalVisible: false,
      modal: '',
    };
  }

  addState() {
    var newState = {
      name: this.state.newStatusName,
      color: this.state.newStatusColor,
      key: encodeURIComponent(this.state.newStatusName),
    };
    var bugStatusList = [...this.props.currentProject.bugStatusList];
    bugStatusList.push(newState);
    this.saveWithNewStatusList(bugStatusList);
    this.setState({
      newStatusName: '',
      newStatusColor: '#0693E3',
    });
  }

  removeState(index) {
    var bugStatusList = [...this.props.currentProject.bugStatusList];
    bugStatusList.splice(index, 1);
    this.saveWithNewStatusList(bugStatusList);
  }

  saveWithNewStatusList(bugStatusList) {
    this.props.updateProject(this.props.currentProject._id, {
      bugStatusList: bugStatusList,
    });
  }

  handleChangeComplete = color => {
    this.setState({
      newStatusColor: color.hex,
      showColorPicker: false,
    });
  };

  /**
   * Render connect or disconnect jira button depending on the state
   */
  renderJiraIntegration = () => {
    const jira = this.props.currentProject.jira;
    const jiraConnected = jira && jira.access_token && jira.refresh_token ? true : false;
    return (
      <Card className='integration-card'>
        <img src={JiraLogo} alt='jira logo' width='200' style={{ alignSelf: 'center', verticalAlign: 'center' }} />
        <div className='buttons'>
          <p>To forward the bugs into your Jira Board you first need to authorize Bugbattle to create Bugs there.</p>
          <Button
            size='lg'
            color='primary'
            onClick={() => {
              if (jiraConnected) {
                this.props.removeJira(this.props.currentProject._id);
              } else {
                window.open(jiraAuthURL + this.props.currentProject._id, '', 'height=800,width=600');
                this.props.connectToJira(this.props.currentProject._id);
              }
            }}
          >
            {jiraConnected ? 'Disconnect' : 'Connect'}
          </Button>
          <br />
          <p>One last thing. Choose your Jira Project.</p>
          <Button
            color={jiraConnected ? 'primary' : 'secondary'}
            disabled={!jiraConnected}
            onClick={() => {
              this.setState({ modalVisible: true, modal: 'jira' });
            }}
          >
            Configure
          </Button>
          {jira && jira.access_token && jira.refresh_token && (!jira.resourceId || !jira.projectId || !jira.issueTypeId) && <span className='configErrorText'>Connected but not configured properly.</span>}
        </div>
        {jira && jira.access_token && jira.refresh_token && jira.resourceId && jira.projectId && jira.issueTypeId && (
          <div className='ribbon ribbon-top-right'>
            <span>success</span>
          </div>
        )}
      </Card>
    );
  };

  /**
   * Render connect or disconnect Trello button depending on the state
   */
  renderTrelloIntegration = () => {
    const project = this.props.currentProject;
    const trelloConnected = (project.trello && project.trello.token) ? true : false;
    return (
      <Card className='integration-card'>
        <img src={TrelloLogo} alt='Trello logo' width='200' style={{ alignSelf: 'center' }} />
        <div className='buttons'>
          <p>To forward the bugs into your Trello Board you first need to authorize Bugbattle to create Bugs there.</p>
          <Button
            size='lg'
            color='primary'
            onClick={() => {
              if (trelloConnected) {
                this.props.removeTrello(this.props.currentProject._id);
              } else {
                Trello.authorize(trelloOpts);
              }
            }}
          >
            {trelloConnected ? 'Disconnect' : 'Connect'}
          </Button>
          <br />
          <p>One last thing. Choose your Trello Board.</p>
          <Button
            color={trelloConnected ? 'primary' : 'secondary'}
            disabled={!trelloConnected}
            onClick={() => {
              this.setState({ modalVisible: true, modal: 'trello' });
            }}
          >
            Configure
          </Button>
          {project.trello && project.trello.token && (!project.trello.boardId || !project.trello.listId || !project.trello.memberId) && <span className='configErrorText'> Connected but not configured properly.</span>}
        </div>
        {project.trello && project.trello.token && project.trello.boardId && project.trello.listId && project.trello.memberId && (
          <div className='ribbon ribbon-top-right'>
            <span>success</span>
          </div>
        )}
      </Card>
    );
  };

  /**
   * Render connect to zapier info
   */
  renderZapierIntegration = () => {
    return (
      <Card className='integration-card'>
        <div className='projectheader'>
          <h1>Zapier (BETA)</h1>
        </div>
        <div className='subtitle-text'>We are providing integrations through zapier. Follow the following steps to create a new integration.</div>
        <div className='subtitle-text'>
          Get started with the BugBattle ZAP by clicking on the following link:
          <br />
          <a href='https://zapier.com/developer/public-invite/32241/2d4f7ac7bbe71f978f8a248579da2491/' rel='noopener noreferrer' target='_blank'>
            https://zapier.com/developer/public-invite/32241/2d4f7ac7bbe71f978f8a248579da2491/
          </a>
          <br />
          <br />
          <b>Your Zapier Private Key:</b>
        </div>
        <div className='integration-token-viewer-code' style={{ marginTop: -20 }}>
          {this.props.currentProject && this.props.currentProject.zapierSecretKey}
        </div>
      </Card>
    );
  };

  /**
   * Render config jira or trello modals
   */
  renderModals = () => {
    return (
      <Modal
        isOpen={this.state.modalVisible}
        style={bugDetailModalStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          this.setState({ modalVisible: false, modal: '' });
        }}
        ariaHideApp={false}
      >
        {this.state.modal === 'jira' && (
          <ConfigureJiraModal
            closeModal={() => {
              this.setState({ modalVisible: false, modal: '' });
            }}
          />
        )}
        {this.state.modal === 'trello' && (
          <ConfigureTrelloModal
            closeModal={() => {
              this.setState({ modalVisible: false, modal: '' });
            }}
          />
        )}
      </Modal>
    );
  };

  render() {
    return (
      <div className='integrations-container'>
        {this.renderJiraIntegration()}
        {this.renderTrelloIntegration()}
        {this.renderZapierIntegration()}
        {this.renderModals()}
        <TrelloClient
          apiKey='b41c95c287b125e3ebf0983b65998e28'
          clientVersion={1}
          apiEndpoint='https://api.trello.com'
          authEndpoint='https://trello.com'
          intentEndpoint='https://trello.com'
          authorizeName='BugBattle'
          authorizeType='popup'
          authorizeOnSuccess={() => {
            // this.props.connectToTrello(this.props.currentProject._id, Trello.token())
            this.props.connectToTrello(this.props.currentProject._id, Trello.token());
          }}
          authorizeOnError={err => {
            console.log(err);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject,
    connectingProjectToJira: state.project.connectingProjectToJira,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProject: (projectId, data) => {
      dispatch(updateProject(projectId, data));
    },
    connectToJira: projectID => {
      dispatch(connectJiraToProject(projectID));
    },
    removeJira: projectID => {
      dispatch(removeJiraFromProject(projectID));
    },
    connectToTrello: (projectID, token) => {
      dispatch(connectTrelloToProject(projectID, token));
    },
    removeTrello: projectID => {
      dispatch(removeTrelloFromProject(projectID));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIntegrations);
