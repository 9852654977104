import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import CardSection from './CardSection';
import poweredByStripe from './powered_by_stripe.png';
import { updateUser, showAddCardModal } from '../../redux/actions/user/users';
import { BarLoader } from 'react-spinners';

class CheckoutForm extends React.Component {
    state = {
        loading: false
    };

    saveCreditCard(billingCustomerId, card) {
        this.props.updateUser(this.props.currentUser._id, {
            billingCustomerId: billingCustomerId,
            billingCustomerCard: card
        });
    }

    handleSubmit = (ev) => {
        ev.preventDefault();

        this.setState({
            loading: true
        });

        this.props.stripe.createToken({
            name: this.props.currentUser.name
        }).then(({token}) => {
            if (token.card) {
                let card = token.card;
                fetch('https://payment.bugbattle.io/add-credit-card.php', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.props.currentUser.email,
                        name: this.props.currentUser.name,
                        token: token,
                        billingCustomerId: this.props.currentUser.billingCustomerId
                    })
                }).then((data) => {
                    data.json().then(json => {
                        this.saveCreditCard(json.customerId, card);
                        toast.success("Awesome, your card has been added!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        this.props.toggleAddCardModal(false);
                    });
                }).catch((err) => {
                    this.setState({
                        loading: false
                    });
                    toast.error("Ooops. An error occured! Please contact us!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
            } else {
                this.setState({
                    loading: false
                });   
                toast.error("Ooops. An error occured! Please contact us!", {
                    position: toast.POSITION.TOP_RIGHT
                });     
            }
        });
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <CardSection />
                {
                    this.state.loading ? <div className="payment-button-line-loading">
                        <BarLoader
                            sizeUnit={"px"}
                            width={ 100 }
                            height={ 4 }
                            color={'#3574F3'}
                            loading={ true }
                            />
                    </div> : 
                    <div className="payment-button-line">
                        <button className="btn btn-primary">{ this.props.currentUser.billingCustomerId ? "Update card" : "Add card" }</button>
                        <img src={ poweredByStripe } alt="Powered by Stripe" />
                    </div>
                }
            </form>
        );
    }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId, data) => {
        dispatch(updateUser(userId, data));
    },
    toggleAddCardModal: (show) => {
      dispatch(showAddCardModal(show));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(CheckoutForm));