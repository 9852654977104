import {
    LOADING_JIRA_RESOURCES,
    LOADING_JIRA_RESOURCES_SUCCESS,
    LOADING_JIRA_RESOURCES_ERRROR,
    LOADING_JIRA_PROJECTS,
    LOADING_JIRA_PROJECTS_SUCCESS,
    LOADING_JIRA_PROJECTS_ERRROR,
} from '../../actions/project/jira';

const initialState = {
    loadingJiraResources: false,
    loadingJiraResourcesError: "",
    projects: [],
    resources: [],
    issueTypes: [],
};

export default function projects(state = initialState, action) {
    // resources
    if (action.type === LOADING_JIRA_RESOURCES) {
        return Object.assign({}, state, {
            loadingJiraResources: action.payload,
        });
    }

    if (action.type === LOADING_JIRA_RESOURCES_SUCCESS) {
        return Object.assign({}, state, {
            loadingJiraResources: false,
            resources: action.payload
        });
    }

    if (action.type === LOADING_JIRA_RESOURCES_ERRROR) {
        return Object.assign({}, state, {
            loadingJiraProjects: false,
            resources: [],
            loadingJiraProjectsError: "We have an error",
        });
    }
    // projects
    if (action.type === LOADING_JIRA_PROJECTS) {
        return Object.assign({}, state, {
            loadingJiraProjects: action.payload,
        });
    }

    if (action.type === LOADING_JIRA_PROJECTS_SUCCESS) {
        return Object.assign({}, state, {
            loadingJiraProjects: false,
            projects: action.payload
        });
    }

    if (action.type === LOADING_JIRA_PROJECTS_ERRROR) {
        return Object.assign({}, state, {
            loadingJiraProjects: false,
            resources: [],
            loadingJiraProjectsError: "We have an error",
        });
    }
    return state;
}
