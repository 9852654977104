import axios from 'axios';

export const LOADING_BUGS_FOR_TESTER = 'LOADING_BUGS_FOR_TESTER'
export const loadingBugsForTester = () => {
  return {
    type: LOADING_BUGS_FOR_TESTER
  }
}

export const BUGS_FOR_TESTER_LOADED = 'BUGS_FOR_TESTER_LOADED'
export const fetchBugsForTesterDone = (bugs) => {
  return {
    type: BUGS_FOR_TESTER_LOADED,
    bugs
  }
}

export const LOADING_COMMENTS_FOR_TESTER_BUG = 'LOADING_COMMENTS_FOR_TESTER_BUG'
export const loadingCommentsForTesterBug = () => {
  return {
    type: LOADING_COMMENTS_FOR_TESTER_BUG
  }
}

export const COMMENTS_FOR_TESTER_BUG_LOADED = 'COMMENTS_FOR_TESTER_BUG_LOADED'
export const commentsForTesterBugLoaded = (comments) => {
  return {
    type: COMMENTS_FOR_TESTER_BUG_LOADED,
    comments
  }
}

export const SET_FILTER_FOR_TESTER = 'SET_FILTER_FOR_TESTER'
export const setFilterForTester = (filter) => {
  return {
    type: SET_FILTER_FOR_TESTER,
    filter
  }
}

export const TOGGLE_BUG_FOR_TESTER = 'TOGGLE_BUG_FOR_TESTER'
export const toggleBugForTester = (bugId) => {
  return {
    type: TOGGLE_BUG_FOR_TESTER,
    bugId
  }
}

export const TOGGLE_CONFETTI_PARTY_FOR_TESTER = 'TOGGLE_CONFETTI_PARTY_FOR_TESTER'
export const toggleConfettiPartyForTester = (confettiParty) => {
  return {
    type: TOGGLE_CONFETTI_PARTY_FOR_TESTER,
    confettiParty
  }
}

export const SET_TESTER_CREDENTIALS = 'SET_TESTER_CREDENTIALS'
export const setTesterCredentials = (projectId, testerEmail, accessKey) => {
  return {
    type: SET_TESTER_CREDENTIALS,
    projectId,
    testerEmail,
    accessKey
  }
}

export const loadBugsForTester = (projectId, testerEmail, accessKey) => {
  return (dispatch) => {
    dispatch(loadingBugsForTester());
    dispatch(setTesterCredentials(projectId, testerEmail, accessKey));
    axios.post('https://webhooks.mongodb-stitch.com/api/client/v2.0/app/bugbattle-xfblb/service/fetchDataForTester/incoming_webhook/fetchDataForTesterHook', {
        projectId,
        testerEmail,
        accessKey
    })
    .then(function (data) {
        dispatch(fetchBugsForTesterDone(data.data));
    })
    .catch(function (error) {
        dispatch(fetchBugsForTesterDone([]));
    });
  }
}

export const loadCommentsForBugTester = (projectId, testerEmail, accessKey, bugId) => {
  return (dispatch) => {
    dispatch(loadingCommentsForTesterBug());
    axios.post('https://webhooks.mongodb-stitch.com/api/client/v2.0/app/bugbattle-xfblb/service/fetchCommentsForTester/incoming_webhook/fetchCommentsForTesterFunc', {
        projectId,
        testerEmail,
        accessKey,
        bugId
    })
    .then(function (data) {
        dispatch(commentsForTesterBugLoaded(data.data));
    })
    .catch(function (error) {
        dispatch(commentsForTesterBugLoaded([]));
    });
  }
}

export const loadBugdetailsForTester = (bugId) => {
  return (dispatch, getState) => {
    const { projectId, testerEmail, accessKey } = getState().tester;
    dispatch(toggleBugForTester(bugId));
    dispatch(loadCommentsForBugTester(projectId, testerEmail, accessKey, bugId));
  }
}

export const sendExternalComment = (bugId, comment, status) => {
  return (dispatch, getState) => {
    const { projectId, testerEmail, accessKey } = getState().tester;

    axios.post('https://webhooks.mongodb-stitch.com/api/client/v2.0/app/bugbattle-xfblb/service/sendFeedbackForTester/incoming_webhook/sendFeedbackForTester', {
        projectId,
        testerEmail,
        accessKey,
        bugId,
        comment,
        status
    })
    .then(function (data) {
      dispatch(loadBugsForTester(projectId, testerEmail, accessKey));
      dispatch(toggleConfettiPartyForTester(true));
      setTimeout(() => {
        dispatch(toggleConfettiPartyForTester(false));
      }, 2000);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}