import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import './CommentCreation.css';
import { sendComment } from '../../redux/actions/bug/bugs';
import MongoClient from '../../mongodb/MongoClient';

class CommentCreation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: ""
        };
    }

    componentDidMount() {

    }

    sendComment() {
        if (this.state.comment === "") {
            return;
        }
        let commentObj = {
            comment: this.state.comment,
            bugId: this.props.currentBug._id.toString(),
            projectId: this.props.currentProject._id.toString(),
            owner: this.props.currentProject.owner,
            shared: this.props.currentProject.shared,
            createdAt: new Date(),
            createdBy: MongoClient.getInstance().getClient().auth.user.id
        };
        this.props.sendComment(commentObj);
        this.setState({
            comment: ""
        });
    }

    render() {
        return (
            <div className="comment-creation">
                <InputGroup>
                    <Input type="text" placeholder="Write a comment..." value={ this.state.comment } onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.sendComment();
                        }
                    }} onChange={(event) => {
                        this.setState({ comment: event.target.value })
                    }} />
                    <InputGroupAddon addonType="append">
                    <Button disabled={ this.state.comment === "" } color="primary" onClick={() => {
                        this.sendComment();
                    }}>
                        Send
                    </Button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    currentBug: state.bug.currentBug,
    currentProject: state.project.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      sendComment: (comment) => {
          dispatch(sendComment(comment));
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentCreation);