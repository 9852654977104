import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input } from 'reactstrap';
import logo from './BugBattleLogo.svg'
import './LoginPage.css'

import MongoClient from '../../mongodb/MongoClient';
import { setCurrentUser, loadCurrentUser } from '../../redux/actions/user/users';
import { BarLoader } from 'react-spinners';
import { push } from 'connected-react-router';
import { loadProjects } from '../../redux/actions/project/projects';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: false,
      email: "",
      password: "",
      createUser: false,
      name: ""
    };
  }

  componentDidMount() {
    if (MongoClient.getInstance().getClient().auth.isLoggedIn) {
      this.props.push('/dashboard');
    }
  }

  fetchUserData() {
    return MongoClient.getInstance().fetchLocalUser().then((userData) => {
      if (userData) {
        this.props.setCurrentUser(userData);
        this.props.loadCurrentUser();
        this.props.loadProjects();
        this.props.push('/dashboard');
      } else {
        this.setState({
          createUser: true,
          loading: false
        });
      }
    })
  }
  
  login() {
    this.setState({
      loading: true
    });

    MongoClient.getInstance().login(this.state.email, this.state.password).then((authedId) => {
      return this.fetchUserData();
    }).catch((err) => {
      this.setState({
        loading: false
      });
      alert("Login incorred.");
    });
  }

  createUserInfo() {
    return MongoClient.getInstance().createUserInfo(this.state.name).then((data) => {
      this.fetchUserData();
    });
  }

  renderLogin() {
    return (
      <div>
        <img src={ logo } alt="BugBattle Logo" />
        <div className="login-signup-title">
          Login
        </div>
        <Input placeholder="Email" type="text" value={this.state.email} onChange={(event) => {
          this.setState({ email: event.target.value })
        }} />
        <Input placeholder="Password" type="password" value={this.state.password} onChange={(event) => {
          this.setState({ password: event.target.value })
        }} />
        <Button size="lg" disabled={ this.state.email === "" || this.state.password === "" } color="primary" onClick={this.login.bind(this)}>
          Login
        </Button>
        <div className="mt-16"><a href="/forgotpassword">Forgot password?</a></div>
        <div className="LoginFormRegister">
        Don't have an account? <a href="/register">Sign up</a>
        </div>
      </div>
    )
  }

  renderCreateUser() {
    return (
      <div>
        <img src={ logo } alt="BugBattle Logo" />
        <Input placeholder="What's your name?" type="text" value={this.state.name} onChange={(event) => {
          this.setState({ name: event.target.value })
        }} />
        <Button size="lg" disabled={ this.state.name === "" } color="primary" onClick={this.createUserInfo.bind(this)}>
          Next
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div className="LoginContainer">
        <div className="LoginForm">
          { this.state.loading ? <div className="modal-center-content">
            <BarLoader
              sizeUnit={"px"}
              width={ 100 }
              height={ 4 }
              color={'#3574F3'}
              loading={ true }
            />
          </div> : <div>
            { !this.state.createUser ? this.renderLogin() : this.renderCreateUser() }
          </div> }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      setCurrentUser: (user) => {
          dispatch(setCurrentUser(user));
      },
      loadProjects: () => {
          dispatch(loadProjects());
      },
      loadCurrentUser: () => {
          dispatch(loadCurrentUser());
      },
      push: (url) => {
        dispatch(push(url));
      },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LoginPage);
