import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BarLoader } from 'react-spinners';
import './ExternalComments.css';
import Comment from '../Comment/Comment';

class ExternalComments extends Component {
    render() {
        if (this.props.commentsLoading) {
            return (
                <div className="comments-no-comments">
                    <BarLoader
                        sizeUnit={"px"}
                        width={ 100 }
                        height={ 4 }
                        color={'#3574F3'}
                        loading={ true }
                    /> 
                </div>
            )
        }

        if (this.props.comments.length === 0) {
            return (
                <div className="comments-no-comments">
                    No comments yet.
                </div>
            )
        }

        return (
            <div className="comments mt-16">
                {
                    this.props.comments.sort(function(a, b) {
                        if (a.createdAt < b.createdAt)
                          return 1;
                        if (a.createdAt > b.createdAt)
                          return -1;
                        return 0;
                    }).map((comment, index) => {
                        return (
                            <Comment key={ comment._id } comment={ comment }></Comment>
                        )
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    comments: state.tester.currentComments,
    commentsLoading: state.bug.loadingComments
  };
};

export default connect(
  mapStateToProps,
  null
)(ExternalComments);