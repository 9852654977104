import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter, push } from 'connected-react-router';
import classNames from 'classnames';
import './App.css';

// Pages
import LoginPage from './pages/LoginPage/LoginPage';
import HomePage from './pages/HomePage/HomePage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import DashboardPage from './pages/DashboardPage/DashbaordPage';
import CreateProjectPage from './pages/CreateProjectPage/CreateProjectPage';
import ProjectHomePage from './pages/ProjectHomePage/ProjectHomePage';
import ProjectListPage from './pages/ProjectListPage/ProjectListPage';
import ProjectSettingsPage from './pages/ProjectSettingsPage/ProjectSettingsPage';
import ProjectBattlePage from './pages/ProjectBattlePage/ProjectBattlePage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import VerifyEmailPage from './pages/VerifyEmailPage/VerifyEmailPage';
import VerifyTokenPage from './pages/VerifyTokenPage/VerifyTokenPage';
import ProjectBoardPage from './pages/ProjectBoardPage/ProjectBoardPage';
import MainSidebar from './components/MainSidebar/MainSidebar';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import TesterPage from './pages/TesterPage/TesterPage';
import QuickProjectSelector from './components/QuickProjectSelector/QuickProjectSelector';

class App extends Component {
  componentDidMount() {

  }

  isSidebarHidden() {
    let hideSidebar = false;
    let currentRoute = this.props.history.location.pathname;
    if (currentRoute.startsWith("/tester/")) {
      hideSidebar = true;
    }
    return hideSidebar;
  }

  render() {
    return (
      <div className={classNames(
        'app',
        {
          'hide-sidebar': this.isSidebarHidden()
        }
      )}>
        <div className="mainsidebar">
          <MainSidebar />
        </div>
        <div className="project-quick-selection-container">
          <QuickProjectSelector />
        </div>
        <div className={"mainapp " + (this.props.currentUser._id ? '' : 'mainapp-full-width')}>
          <ConnectedRouter history={this.props.history}>
            <div>
              <Switch>
                <Route path="/tester/:project/:email/:accessKey" component={TesterPage} />
                <Route path="/" exact component={HomePage} />
                <Route path="/login/" component={LoginPage} />
                <Route path="/register/" component={RegisterPage} />
                <Route path="/dashboard/" component={DashboardPage} />
                <Route path="/createproject/" component={CreateProjectPage} />
                <Route path="/verifyemail/" component={VerifyEmailPage} />
                <Route path="/confirmemail/" component={VerifyTokenPage} />
                <Route path="/forgotpassword/" component={ForgotPassword} />
                <Route path="/passwordreset/" component={ResetPassword} />
                <Route path="/profile/" component={ProfilePage} />
                <Route path="/project/:id/list" component={ProjectListPage} />
                <Route path="/project/:id/board" component={ProjectBoardPage} />
                <Route path="/project/:id/battle" component={ProjectBattlePage} />
                <Route path="/project/:id/settings/:page" component={ProjectSettingsPage} />
                <Route path="/project/:id/settings/" component={ProjectSettingsPage} />
                <Route path="/project/:id" component={ProjectHomePage} />
              </Switch>
            </div>
          </ConnectedRouter>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasProjects: (state.project.projects.length > 0),
    loadingProjects: state.project.loadingProjects,
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => {
      dispatch(push(url));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
