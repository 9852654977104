import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';
import classNames from 'classnames';
import './ProjectBattlePage.css';

import UserRanking from '../../components/UserRanking/UserRanking';
import Giphy from '../../components/Giphy/Giphy';
import { setProject, setNavRoute } from '../../redux/actions/project/projectUI';
import { getWeekIdentifier } from '../../helper/bugdetails';

class ProjectBattlePage extends Component {
    state = {
        currentRanking: "weekly"
    };

    /**
     * Component did mount
     */
    componentDidMount() {
        this.props.setProject(this.props.match.params.id);
        this.props.setNavRoute("battle");
    }

    /**
     * Sort user's by points
     */
    sortUserByPoints() {
        let users = [];
        let currentWeekIdentifier = getWeekIdentifier();
        if (this.props.users && this.props.currentProject._id) {
            let self = this;
            Object.keys(this.props.users).forEach(function (key) {
                users.push(self.props.users[key]);
            });
            let projectID = this.props.currentProject._id.toHexString();
            users.sort((a, b) => {
                let aP = 0;
                let bP = 0;
                if (!a || !b) {
                    return 0;
                }
                if (a.points && a.points[projectID]) {
                    if (this.state.currentRanking === "weekly") {
                        aP = a.points[projectID][currentWeekIdentifier];
                    } else {
                        aP = a.points[projectID]["overall"];
                    }
                    if (!aP) {
                        aP = 0;
                    }
                }
                if (b.points && b.points[projectID]) {
                    if (this.state.currentRanking === "weekly") {
                        bP = b.points[projectID][currentWeekIdentifier];
                    } else {
                        bP = b.points[projectID]["overall"];
                    }
                    if (!bP) {
                        bP = 0;
                    }
                }
                return bP - aP;
            });

            return users;
        }
        return users;
    }

    isWeeklyWinner(users) {
        if(this.props.currentUser && this.props.currentUser._id && users && users.length > 0 && users[0] && users[0]._id && users[0]._id.toString() === this.props.currentUser._id.toString()) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Set filter of ranking table
     */
    setFilter(filter) {
        this.setState({
            currentRanking: filter
        });
    }

    /**
     * Main render method
     */
    render() {
        let rankingType = "overall";
        if (this.state.currentRanking === "weekly") {
            rankingType = getWeekIdentifier();
        }
        let gifBgImage = "";
        if (this.props.currentProject && this.props.currentProject.giphy) {
            gifBgImage = this.props.currentProject.giphy.url;
        }
        let users = this.sortUserByPoints();
        return (
            <div className="BattlePage">
                <div className="Container">
                    <div className="LeftContainerBattle">
                        <div className="projectheader ">
                            <div className="battle-category-selection">
                                <h1>{ this.state.currentRanking === "weekly" ? "Weekly Battle " : "Overall Battle" }</h1>
                                <ButtonGroup>
                                    <Button color="primary" className={classNames({ 'active': this.state.currentRanking === "weekly" })} onClick={() => { this.setFilter("weekly"); }}>Weekly</Button>
                                    <Button color="primary" className={classNames({ 'active': this.state.currentRanking === "overall" })} onClick={() => { this.setFilter("overall"); }}>Overall</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="project-ranking-list">
                            {this.props.users && this.props.currentProject._id &&
                                users.map((user, index) => {
                                    return (
                                        <div key={index} className="project-members-list-user-item">
                                            <UserRanking rank={index} type={ rankingType } user={user} currentProjectId={this.props.currentProject._id.toHexString()}></UserRanking>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="GiphyContainer" style={{
                    backgroundImage: 'url("' + gifBgImage + '")'
                }}>
                    <div className="">
                        <Giphy isWeeklyWinner={ this.isWeeklyWinner(users) } />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentProject: state.project.currentProject,
        users: state.user.users,
        currentUser: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setProject: (projectId) => {
            dispatch(setProject(projectId));
        },
        setNavRoute: (route) => {
            dispatch(setNavRoute(route));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectBattlePage);
