import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrophy, faClipboardList } from '@fortawesome/pro-regular-svg-icons';
import Modal from 'react-modal';
import { Button } from 'reactstrap';
import './ProjectBoardPage.css';
import { setProject, setNavRoute } from '../../redux/actions/project/projectUI';
import BugDetail from '../../components/BugDetail/BugDetail';
import BoardColumn from './BoardColumn';
import { showBugModal } from '../../redux/actions/bug/bugs';

class ProjectBoardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowHeight: window.innerHeight
        };
    }

    updateDimensions() {
        this.setState({
            windowHeight: window.innerHeight
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.props.setProject(this.props.match.params.id);
        this.props.setNavRoute("board");
    }
    
    render() {
        return (
            <div className="ProjectPage">
                <div className="Container">
                    <div className="kanban-container-scroll">
                        <div className="kanban-container-scroll-container" style={{
                            minWidth: (307 * this.props.bugStatusList.length)
                        }}>
                            {
                                this.props.bugStatusList.map((status) => {
                                    var bugsInColumn = this.props.bugStatusListBugs[status.key];
                                    if (!bugsInColumn) {
                                        return null;
                                    }
                                    return (
                                        <div key={ status.key } className="kanban-column">
                                            <div className="kanban-column-header">
                                                <div>{ status.name }</div>
                                                <div className="kanban-column-header-stats">
                                                    <span>
                                                        <span className="kanban-column-header-stats-label">{ bugsInColumn.length }</span>
                                                        <FontAwesomeIcon icon={ faClipboardList } />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="kanban-column-body">
                                                <div className="kanban-column-body-drag">
                                                    <BoardColumn boardkey={ status.key } boardcolor={ status.color } buglist={ bugsInColumn }></BoardColumn>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Modal
                isOpen={this.props.showBugModal}
                contentLabel="Bug Detail Modal"
                style={bugDetailModalStyles}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => {
                    this.props.toggleBugModal(false);
                }}
                ariaHideApp={false}>
                    <div className="bugdetails-modal-container">
                        <BugDetail></BugDetail>
                    </div>
                    <div className="bug-modal-close-btn-full" onClick={() => {
                        this.props.toggleBugModal(false);
                    }}>
                        <FontAwesomeIcon color="#fff" icon={ faTimes } />
                    </div>
                </Modal>
            </div>
        );
    }
}

const bugDetailModalStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: 'none',
        maxWidth: '85vw',
        minWidth: '85vw'
    }
};

const mapStateToProps = state => {
  return {
    selectedProject: state.projectUI.selectedProjectId,
    currentBug: state.bug.currentBug,
    currentProject: state.project.currentProject,
    bugStatusList: state.bug.bugStatusList,
    bugStatusListBugs: state.bug.bugStatusListBugs,
    showBugModal: state.bug.showBugModal,
    pointsUpdated: state.user.pointsUpdated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      setProject: (projectId) => {
          dispatch(setProject(projectId));
      },
      toggleBugModal: (show) => {
          dispatch(showBugModal(show));
      },
      setNavRoute: (route) => {
          dispatch(setNavRoute(route));
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectBoardPage);