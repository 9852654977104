import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { push } from 'connected-react-router';
import './DashboardPage.css'

import ProjectSelectionList from '../../components/ProjectSelectionList/ProjectSelectionList';
import { BarLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardListCheck } from '@fortawesome/pro-regular-svg-icons';

class DashboardPage extends Component {
  renderCreateProject() {
    return (
      <div>
          <span className="dashboard-dialog-card-header" role="img" aria-label="Awesome!">Hi { this.props.currentUser.name } 👋</span>
          <div className="dashboard-dialog-card-body">Awesome to have you on board!<br />Create a new project or ask your friends to invite you to an existing team.</div>
          <Button size="lg" color="primary" className="mb-16 createProjectLink" onClick={() => {
            this.props.push("/createproject/");
          }}>Create new project</Button>
      </div>
    )
  }

  renderLoading() {
    return (
      <div className="Container">
        <div className="dashboard-loading-container">
          <BarLoader
            sizeUnit={"px"}
            width={ 100 }
            height={ 4 }
            color={'#3574F3'}
            loading={ true }
          /> 
        </div>
      </div>
    );
  }

  renderDashboard() {
    return (
      <div className="Container">
          <div className="MainContainerFull">
              <div className="MainContainerFullHero">
                  <div className="MainContainerFullHeroIcon"><FontAwesomeIcon icon={ faClipboardListCheck }></FontAwesomeIcon></div>
                  <div className="MainContainerFullHeroTitle">Projects</div>
                  <div className="MainContainerFullHeroBody">
                      <div className="MainContainerFullHeroBodyTitle">Ready to start a new project?</div>
                      <div className="MainContainerFullHeroBodyLinks"><a href="/createproject/">Create new project</a></div>
                  </div>
              </div>
              <div className="MainContainerFullInnerFullWidth">
                { this.props.hasProjects ? <ProjectSelectionList></ProjectSelectionList> : this.renderCreateProject() }
              </div>
          </div>
      </div>
    );
  }

  render() {
    return (
      <div className="ProjectPage">
        {
          this.props.loadingProjects ? this.renderLoading() : this.renderDashboard()
        }
        <div class="no-mobile-optimization">
          <div>
            The BugBattle Dashboard is not optimized for mobile yet.<br /><a href="https://www.bugbattle.io">Back to BugBattle.</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasProjects: (state.project.projects.length > 0),
    loadingProjects: state.project.loadingProjects,
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      push: (url) => {
          dispatch(push(url));
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);