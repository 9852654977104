import { FETCH_BUGS_DONE, FETCH_BUGS, SELECT_BUG, UPDATE_BUG_DONE, UPDATE_BUG, SHOW_BUG_MODAL, SET_BUGLIST_FILTER, DELETE_BUG, FETCH_COMMENTS_DONE, COMPOSE_COMMENT, FETCH_COMMENTS, SET_BUG_DRAGGING_COLOR, FETCH_LOG_DONE, FETCH_LOG, FETCH_STEPS_TO_REPRODUCE, FETCH_STEPS_TO_REPRODUCE_DONE } from "../../actions/bug/bugs";
import { faQuestion, faAngleDown, faAngleDoubleDown, faExclamationSquare, faBan, faFeather, faDuck, faSquirrel, faSheep, faRam, faRabbit, faMonkey, faNarwhal, faUnicorn, faPig } from '@fortawesome/pro-regular-svg-icons';
import { staticData } from '../../../helper/bugdetails';

const initialState = {
    loading: false,
    loadingBugs: true,
    loadingLog: false,
    loadingStepsToReproduce: false,
    updateBugLoading: true,
    bugs: [],
    currentBug: {
        _id: null
    },
    currentConsoleLog: [],
    currentStepsToReproduce: [],
    currentBugListFilter: {
        status: localStorage.getItem('bugStatusFilter'),
        assignedTo: localStorage.getItem('bugAssignedToFilter')
    },
    bugDraggingColor: null,
    bugStatusList: [],
    bugStatusObject: {},
    bugStatusListBugs: {},
    showBugModal: false,
    comments: [],
    commentsLoading: false,
    bugPriorities: staticData.bugPriorities
};

function getBugsForStatus(bugs, status) {
    return bugs.filter((item, index) => {
        return item.status === status
    });
}
  
export default function bugs(state = initialState, action) {
    if (action.type === SET_BUG_DRAGGING_COLOR) {
        return Object.assign({}, state, {
            bugDraggingColor: action.color
        });
    }
    if (action.type === COMPOSE_COMMENT) {
        var comments = [...state.comments];
        comments.splice(0, 0, action.comment);
        return Object.assign({}, state, {
            comments
        });
    }
    if (action.type === SHOW_BUG_MODAL) {
        return Object.assign({}, state, {
            showBugModal: action.show
        });
    }
    if (action.type === SET_BUGLIST_FILTER) {
        return Object.assign({}, state, {
            currentBugListFilter: {
                status: action.status,
                assignedTo: action.assignedTo
            }
        });
    }
    if (action.type === FETCH_STEPS_TO_REPRODUCE) {
        return Object.assign({}, state, {
            currentStepsToReproduce: [],
            loadingStepsToReproduce: true
        });
    }
    if (action.type === FETCH_STEPS_TO_REPRODUCE_DONE) {
        return Object.assign({}, state, {
            currentStepsToReproduce: action.stepsToReproduce,
            loadingStepsToReproduce: false
        });
    }
    if (action.type === FETCH_LOG) {
        return Object.assign({}, state, {
            currentConsoleLog: [],
            loadingLog: true
        });
    }
    if (action.type === FETCH_LOG_DONE) {
        return Object.assign({}, state, {
            currentConsoleLog: action.log,
            loadingLog: false
        });
    }
    if (action.type === FETCH_COMMENTS) {
        return Object.assign({}, state, {
            comments: [],
            commentsLoading: true
        });
    }
    if (action.type === FETCH_COMMENTS_DONE) {
        return Object.assign({}, state, {
            comments: action.comments,
            commentsLoading: false
        });
    }
    if (action.type === FETCH_BUGS) {
        return Object.assign({}, state, {
            loadingBugs: true
        });
    }
    if (action.type === FETCH_BUGS_DONE) {
        let statusList = [];
        if (action.bugStatusList) {
            statusList = [...action.bugStatusList];
        }
        // Default
        statusList.splice(0, 0, {
            name: "Open",
            key: "open",
            color: "#ff0000"
        });
        statusList.push({
            name: "Feedback needed",
            key: "feedbackneeded",
            color: "rgb(142, 209, 252)"
        });
        statusList.push({
            name: "Ready to test again",
            key: "readytotestagain",
            color: "rgb(6, 147, 227)"
        });
        statusList.push({
            name: "Done",
            key: "done",
            color: "rgb(141, 198, 63)"
        });

        // Map to object.
        var bugStatusObject = {};
        for (var i = 0; i < statusList.length; i++) {
            var item = statusList[i];
            bugStatusObject[item.key] = item;
        }

        // Filter bugs by status.
        let bugStatusListBugs = {};
        for (let i = 0; i < statusList.length; i++) {
            let statusKey = statusList[i].key;
            bugStatusListBugs[statusKey] = getBugsForStatus(action.bugs, statusKey);
        }

        return Object.assign({}, state, {
            loadingBugs: false,
            bugs: action.bugs,
            bugStatusList: statusList,
            bugStatusObject: bugStatusObject,
            bugStatusListBugs: bugStatusListBugs
        });
    }
    if (action.type === DELETE_BUG) {
        let newState = Object.assign({}, state, {
            updateBugLoading: true
        });

        // Remove bug in list (locally)
        let bugPos = -1;
        for (let i = 0; i < newState.bugs.length; i++) {
            if (newState.bugs[i]._id.equals(action.bugId)) {
                bugPos = i;
            }
        }
        if (bugPos >= 0) {
            newState.bugs.splice(bugPos, 1);
        }

        // Regenerate bugstatuslist
        let statusList = newState.bugStatusList;
        let bugStatusListBugs = {};
        for (let i = 0; i < statusList.length; i++) {
            let statusKey = statusList[i].key;
            bugStatusListBugs[statusKey] = getBugsForStatus(newState.bugs, statusKey);
        }
        newState.bugStatusListBugs = bugStatusListBugs;
        
        return newState;
    }
    if (action.type === SELECT_BUG) {
        return Object.assign({}, state, {
            currentBug: action.bug,
            comments: []
        });
    }
    if (action.type === UPDATE_BUG) {
        let newState = Object.assign({}, state, {
            updateBugLoading: true
        });

        // Update current bug if it's the bug to update!
        if (newState.currentBug._id && newState.currentBug._id.equals(action.bugId)) {
            newState.currentBug = Object.assign({}, newState.currentBug, action.data);
        }

        // Update bug in list (locally)
        let bugPos = -1;
        let bugToUpdate = {};
        for (let i = 0; i < newState.bugs.length; i++) {
            if (newState.bugs[i]._id.equals(action.bugId)) {
                bugPos = i;
                bugToUpdate = newState.bugs[i];
            }
        }
        if (bugPos >= 0) {
            newState.bugs[bugPos] = Object.assign({}, bugToUpdate, action.data);
        }

        // Regenerate bugstatuslist
        let statusList = newState.bugStatusList;
        let bugStatusListBugs = {};
        for (let i = 0; i < statusList.length; i++) {
            let statusKey = statusList[i].key;
            bugStatusListBugs[statusKey] = getBugsForStatus(newState.bugs, statusKey);
        }
        newState.bugStatusListBugs = bugStatusListBugs;
        
        return newState;
    }
    if (action.type === UPDATE_BUG_DONE) {
        return Object.assign({}, state, {
            updateBugLoading: false
        });
    }
    return state;
}  