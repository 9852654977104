import Trello from '../../../helper/trello'

//RESOURCES
export const LOADING_TRELLO_BOARDS = 'LOADING_TRELLO_BOARDS';
export const loadingTrelloBoards = state => {
    return {
        type: LOADING_TRELLO_BOARDS,
        payload: state,
    };
};

export const LOADING_TRELLO_BOARDS_SUCCESS = 'LOADING_TRELLO_BOARDS_SUCCESS';
export const loadingTrelloBoardsSuccess = boards => {
    return {
        type: LOADING_TRELLO_BOARDS_SUCCESS,
        payload: boards,
    };
};

export const LOADING_TRELLO_BOARDS_ERROR = 'LOADING_TRELLO_BOARDS_ERROR';
export const loadingTrelloBoardsError = err => {
    return {
        type: LOADING_TRELLO_BOARDS_ERROR,
        payload: err,
    };
};

// BOARD LISTS
export const LOADING_BOARD_LISTS = 'LOADING_BOARD_LISTS';
export const loadingBoardLists = state => {
    return {
        type: LOADING_BOARD_LISTS,
        payload: state,
    };
};

export const LOADING_BOARD_LISTS_SUCCESS = 'LOADING_BOARD_LISTS_SUCCESS';
export const loadingBoardListsSuccess = state => {
    return {
        type: LOADING_BOARD_LISTS_SUCCESS,
        payload: state,
    };
};

export const LOADING_BOARD_LISTS_ERROR = 'LOADING_BOARD_LISTS_ERROR';
export const loadingBoardListsError = list => {
    return {
        type: LOADING_BOARD_LISTS_ERROR,
        payload: list,
    };
};

// BOARD LISTS
export const LOADING_BOARD_MEMBERS = 'LOADING_BOARD_MEMBERS';
export const loadingBoardMembers = state => {
    return {
        type: LOADING_BOARD_MEMBERS,
        payload: state,
    };
};

export const LOADING_BOARD_MEMBERS_SUCCESS = 'LOADING_BOARD_MEMBERS_SUCCESS';
export const loadingBoardMembersSuccess = state => {
    return {
        type: LOADING_BOARD_MEMBERS_SUCCESS,
        payload: state,
    };
};

export const LOADING_BOARD_MEMBERS_ERROR = 'LOADING_BOARD_MEMBERS_ERROR';
export const loadingBoardMembersError = list => {
    return {
        type: LOADING_BOARD_MEMBERS_ERROR,
        payload: list,
    };
};

/**
 * Load user boards from Trello
 * @param {*} conf 
 */
export const loadTrelloBoards = (token) => {
    return dispatch => {
        dispatch(loadingTrelloBoards(true))
        Trello.loadBoards(token)
            .then(res => {
                dispatch(loadingTrelloBoardsSuccess(res.data))
            })
            .catch(err => {
                dispatch(loadingTrelloBoardsError("error loading boards"))
            })
    };
};

/**
 * 
 * @param {string} boardId 
 * @param {string} token 
 */
export const loadBoardLists = (boardId, token) => {
    return dispatch => {
        dispatch(loadingBoardLists(true))
        Trello.loadBoardLists(boardId, token)
            .then(res => {
                dispatch(loadingBoardListsSuccess(res.data))
            })
            .catch(err => {
                dispatch(loadingBoardListsError("error loading board lists"))
            })
    };
};

/**
 * 
 * @param {string} boardId 
 * @param {string} token 
 */
export const loadBoardMembers = (boardId, token) => {
    return dispatch => {
        dispatch(loadingBoardMembers(true))
        Trello.loadBoardMembers(boardId, token)
            .then(res => {
                dispatch(loadingBoardMembersSuccess(res.data))
            })
            .catch(err => {
                console.log(err)
                dispatch(loadingBoardMembersError("error loading board members"))
            })
    };
};

