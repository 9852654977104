import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProjectListPage.css'

import BugListComponent from '../../components/BugListComponent/BugListComponent';
import { setProject, setNavRoute } from '../../redux/actions/project/projectUI';
import BugDetail from '../../components/BugDetail/BugDetail';
import { push } from 'connected-react-router';

class ProjectPage extends Component {
    componentDidMount() {
        this.props.setProject(this.props.match.params.id);
        this.props.setNavRoute("list");
    }

    render() {
        return (
            <div className="ProjectPage">
                <div className="Container shadow-card">
                    <div className="LeftSidebar">
                        <BugListComponent></BugListComponent>
                    </div>
                    <div className="MainContainer">
                        <BugDetail></BugDetail>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentProject: state.project.currentProject
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setProject: (projectId) => {
            dispatch(setProject(projectId));
        },
        setNavRoute: (route) => {
            dispatch(setNavRoute(route));
        },
        push: (url) => {
            dispatch(push(url));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectPage);
