import React, { Component } from 'react';
import { connect } from 'react-redux';
import './UserRanking.css'

import { loadUser } from '../../redux/actions/user/users';

class UserRanking extends Component {
  getPointsForProject(user) {
    if (!user || !user.points) {
      return 0;
    }
    if (!this.props.currentProjectId) {
      return 0;
    }
    let projectId = this.props.currentProjectId;
    if (!user.points[projectId]) {
      return 0;
    }
    if (!user.points[projectId][this.props.type]) {
      return 0;
    }
    return user.points[projectId][this.props.type];
  }

  getUser() {
    var user = this.props.users[this.props.userId];
    if (user) {
      return user;
    }
    return {
      _id: null,
      name: "",
      avatar: null,
      email: ""
    };
  }

  getRankEmoji() {
    let items = ["🥇", "🥈", "🥉", "🍆", "🍅", "🥕", "🥦", "🥑", "🥒", "🌽", "🍌", "🍉", "🍎", "🥔"];
    if (this.props.rank < items.length) {
      return items[this.props.rank];
    }
    return "🥓";
  }

  render() {
    var user = this.props.user;
    return (
        <div className="user-ranking-item">
          <div className="weekly-winner">{ this.getRankEmoji() }</div>
          { (user && user.avatar) ? <img alt="Avatar" src={ user.avatar }></img> : <div className="user-ranking-item-img-loading"></div> }
          <span>{ user.name ? user.name : '...' }</span>
          <span className="user-ranking-item-currnet-points">{ this.getPointsForProject(user) }</span>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.user.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRanking);