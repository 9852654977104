import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_DONE,
  FETCH_PROJECT,
  FETCH_PROJECT_DONE,
  UPDATE_PROJECT,
  UPDATE_PROJECT_DONE,
  DELETE_PROJECT,
  DELETE_PROJECT_DONE,
  SHOW_INTEGRATION_MODAL,
  UPDATE_JIRA_ACCESS_TOKEN,
} from '../../actions/project/projects';

const initialState = {
  loadingProjects: false,
  loadingCurrentProject: false,
  updateProjectLoading: false,
  deleteProjectLoading: false,
  showIntegrationTutorial: false,
  showIntegrationTutorialType: 'ios',
  projects: [],
  currentProject: {
    _id: null,
    bugStatusList: [],
  },
};

export default function projects(state = initialState, action) {
  if (action.type === SHOW_INTEGRATION_MODAL) {
    return Object.assign({}, state, {
      showIntegrationTutorial: action.show,
      showIntegrationTutorialType: action.integration,
    });
  }
  if (action.type === FETCH_PROJECTS) {
    return Object.assign({}, state, {
      loadingProjects: true,
    });
  }
  if (action.type === FETCH_PROJECTS_DONE) {
    return Object.assign({}, state, {
      loadingProjects: false,
      projects: action.projects,
    });
  }
  if (action.type === FETCH_PROJECT) {
    return Object.assign({}, state, {
      loadingCurrentProject: true,
    });
  }
  if (action.type === FETCH_PROJECT_DONE) {
    return Object.assign({}, state, {
      loadingCurrentProject: false,
      currentProject: action.project,
    });
  }
  if (action.type === DELETE_PROJECT) {
    return Object.assign({}, state, {
      deleteProjectLoading: true,
    });
  }
  if (action.type === DELETE_PROJECT_DONE) {
    let newState = Object.assign({}, state, {
      deleteProjectLoading: false,
      currentProject: {
        _id: null,
      },
    });

    // Update project in list (locally)
    let projectPos = -1;
    for (var i = 0; i < newState.projects.length; i++) {
      if (newState.projects[i]._id.equals(action.projectId)) {
        projectPos = i;
      }
    }
    if (projectPos >= 0) {
      newState.projects.slice(projectPos, 1);
    }

    return newState;
  }
  if (action.type === UPDATE_PROJECT) {
    let newState = Object.assign({}, state, {
      updateProjectLoading: true,
    });
    newState.currentProject = Object.assign({}, newState.currentProject, action.data);

    // Update project in list (locally)
    let projectPos = -1;
    for (var ii = 0; ii < newState.projects.length; ii++) {
      if (newState.projects[ii]._id.equals(newState.currentProject._id)) {
        projectPos = ii;
      }
    }
    if (projectPos >= 0) {
      newState.projects[projectPos] = newState.currentProject;
    }

    return newState;
  }
  if (action.type === UPDATE_PROJECT_DONE) {
    return Object.assign({}, state, {
      updateProjectLoading: false,
    });
  }
  return state;
}
