import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import './ProfilePage.css';

import { selectProject } from '../../redux/actions/project/projectUI';
import MongoClient from '../../mongodb/MongoClient';
import UserSettings from '../../components/UserSettings/UserSettings';
import { fetchProjectDone } from '../../redux/actions/project/projects';
import { setCurrentUser } from '../../redux/actions/user/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOut, faBoxUsd, faReceipt } from '@fortawesome/pro-regular-svg-icons';
import BillingSettings from '../../components/BillingSettings/BillingSettings';
import InvoicesList from '../../components/BillingSettings/InvoicesList';

const headerArray = [{
    title: "Account info",
    icon: faUser,
    key: "account_info",
    description: "All important account settings in one place.",
    link: {
        title: "Learn more",
        url: "https://developers.bugbattle.io/bugbattle/getting-started"
    }
}, {
    title: "Billing",
    icon: faBoxUsd,
    key: "billing_details",
    description: "Click the link below for more informations about billing!",
    link: {
        title: "Learn more",
        url: "https://developers.bugbattle.io/bugbattle/getting-started"
    }
}, {
    title: "Invoices",
    icon: faReceipt,
    key: "invoices_list",
    description: "Please let us know if you have any questions! We are happy to help 😃",
    link: {
        title: "Learn more",
        url: "https://developers.bugbattle.io/bugbattle/getting-started"
    }
}];

class ProfilePage extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: "account_info"
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    
    getSelectedHeader() {
        for (var i = 0; i < headerArray.length; i++) {
            if (headerArray[i].key === this.state.activeTab) {
                return headerArray[i];
            }
        }
        return {};
    }

    render() {
        let selectedHeader = this.getSelectedHeader();

        return (
            <div className="Container">
                <div className="LeftNavigationBar">
                    <div className="settings-tab-item-hero">Account</div>
                    {
                        headerArray.map((headerItem, index) => {
                            return (
                                <div key={ index } className={`settings-tab-item ${this.state.activeTab === headerItem.key ? 'settings-tab-item-active' : ''}`} onClick={() => {
                                    this.toggle(headerItem.key)
                                }}>
                                    <div className="settings-tab-item-icon">
                                        <FontAwesomeIcon icon={ headerItem.icon }></FontAwesomeIcon>
                                    </div>
                                    { headerItem.title }
                                </div>
                            );
                        })
                    }
                    <div className="settings-tab-item-spacer"></div>
                    <div className="settings-tab-item" onClick={() => {
                        this.props.selectProject(null);
                        MongoClient.getInstance().getAuth().logout().then(() => {
                            this.props.push("/login");
                        });
                    }}><div className="settings-tab-item-icon"><FontAwesomeIcon icon={ faSignOut }></FontAwesomeIcon></div> Sign out</div>
                </div>
                <div className="MainContainerFull">
                    <div className="MainContainerFullHero">
                        <div className="MainContainerFullHeroIcon"><FontAwesomeIcon icon={ selectedHeader.icon }></FontAwesomeIcon></div>
                        <div className="MainContainerFullHeroTitle">{ selectedHeader.title }</div>
                        <div className="MainContainerFullHeroBody">
                            <div className="MainContainerFullHeroBodyTitle">{ selectedHeader.description }</div>
                            <div className="MainContainerFullHeroBodyLinks"><a href={ selectedHeader.link.url } target="_blank" rel="noopener noreferrer">{ selectedHeader.link.title }</a></div>
                        </div>
                    </div>
                    <div className="MainContainerFullInner">
                        { (this.state.activeTab === "account_info") && <UserSettings></UserSettings> }
                        { (this.state.activeTab === "billing_details") && <BillingSettings></BillingSettings> }
                        { (this.state.activeTab === "invoices_list") && <InvoicesList></InvoicesList> }
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
      currentUser: state.user.currentUser
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        push: (url) => {
            dispatch(push(url));
        },
        selectProject: (projectId) => {
            dispatch(selectProject(projectId));
            dispatch(fetchProjectDone({
                _id: null,
                bugStatusList: []
            }));
            dispatch(setCurrentUser({
                _id: null,
                name: null,
                email: null,
                avatar: null,
                owner: null,
                points: 0
            }));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);
