import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import './ProjectCard.css'

import { setProject } from '../../redux/actions/project/projectUI';
import Moment from 'react-moment';

class ProjectCard extends Component {
  render() {
    return (
      <div className="projectcard" onClick={() => {
          var projectId = this.props.project._id;
          this.props.setProject(projectId);
          this.props.push('/project/' + projectId);
      }}>
        <div className="title">
          { this.props.project.name }
        </div>
        <div className="titleid"><div>{ this.props.project._id.toString() }</div></div>
        <div className="planInfo">
          <span>{ this.props.project.currentPlan }</span>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProject: (projectId) => {
        dispatch(push("project/" + projectId));
    },
    push: (url) => {
      dispatch(push(url));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ProjectCard);