import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import './BugDetailStateSelect.css'

import { updateBug } from '../../redux/actions/bug/bugs';

class BugDetailStateSelect extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      actionList: this.props.bugStatusList
    };
  }

  getButtonLabel() {
    var bugStatusKey = this.props.currentBug["status"];
    var item = this.props.bugStatusObject[bugStatusKey];
    if (item) {
      return item;
    }
    return {
      name: "Not set",
      key: "",
      color: "#888"
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  changeValue(e) {
    let id = e.currentTarget.getAttribute("id");
    var data = {
      status: id
    };
    this.props.updateBug(this.props.currentBug, data);
  }

  render() {
    var currentStatus = this.getButtonLabel();
    return (
        <div className="bugdetail-actions-dropdown">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
              <div className="bugStatusItem">
                <div className="bugStatusBubbleInlineBig" style={{
                  backgroundColor: currentStatus.color
                }}></div>
                <span>{ currentStatus.name }</span>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              { this.state.actionList.map((item, index) => {
                return (
                  <DropdownItem key={ index } id={ item.key } onClick={ this.changeValue.bind(this) }>
                    <div className="bugStatusItem">
                      <div className="bugStatusBubbleInlineBig" style={{
                        backgroundColor: item.color
                      }}></div>
                      <span>{ item.name }</span>
                    </div>
                  </DropdownItem>
                )
              }) }
            </DropdownMenu>
          </Dropdown>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentBug: state.bug.currentBug,
    bugStatusList: state.bug.bugStatusList,
    bugStatusObject: state.bug.bugStatusObject
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      updateBug: (bug, data) => {
        dispatch(updateBug(bug, data));
      }
    };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugDetailStateSelect);