import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Giphy.css'
import { Selector } from "react-giphy-selector";
import { updateProject } from '../../redux/actions/project/projects';

class Giphy extends Component {
    state = {
        enableEdit: false
    }

    /**
     * Add gif
     * @param {*} data 
     */
    addGiphy(data) {
        this.props.addGiphytoProject(this.props.projectId, this.props.userId, data);
        this.setState({
            enableEdit: false
        });
    }

    /**
     * Main render method
     */
    render() {
        if (this.state.enableEdit) {
            return (
                <div>
                    <Selector
                        apiKey={'ny4EZJWaRitfN8CjbSrfJYsTi8wjpuAv'}
                        onGifSelected={this.addGiphy.bind(this)}
                        queryFormClassName="giphy-query"
                        queryFormInputClassName="giphy-form-input form-control"
                        queryFormSubmitClassName="btn btn-primary giphy-form-submit" />
                </div>
            );
        }

        return (
            <div className="edit-gif-button">
                <button type="button" className="btn btn-primary" onClick={() => {
                    if (!this.props.isWeeklyWinner) {
                        window.alert("Sorry, only the weekly leader can edit this GIF. 🥒");
                    } else {
                        this.setState({
                            enableEdit: true
                        });
                    }
                }}>Edit GIF 🥇</button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        projectId: state.project.currentProject._id,
        userId: state.user.currentUser.owner
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addGiphytoProject(projectId, userId, data) {
            dispatch(updateProject(projectId.toHexString(), { giphy: { userId: userId, url: data.images.original.gif_url } }));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Giphy);