import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Confetti from 'react-dom-confetti';
import './VerifyEmailPage.css';

class VerifyEmailPage extends Component {
  state = {
    confetti: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        confetti: true
      });
    }, 1000);
  }

  render() {
    return (
      <div className="LoginContainer">
        <div className="LoginForm p-32">
            <span className="verify-email-header" role="img" aria-label="Awesome!">💪</span>
            <div className="projectheader center">
                <h1 className="center">One step to go...</h1>
            </div>
            <div>Thank's for signing up! Please check your inbox and click the verification link we've just sent to you!</div>
            <div className="mt-16">
              <Button size="lg" color="primary" onClick={() => {
                  window.location.href = "mailto:hello@bugbattle.io";
              }}>
                Open inbox
              </Button>
            </div>
            <div className="confetti-center">
              <Confetti active={ this.state.confetti } config={{
                    angle: 90,
                    spread: 40,
                    startVelocity: 25,
                    elementCount: 10,
                    decay: 0.9
                  }}/>
            </div>
        </div>
      </div>
    );
  }
}

export default VerifyEmailPage;
