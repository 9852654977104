import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import './BugDetailAssignToSelect.css'

import { updateBug } from '../../redux/actions/bug/bugs';
import UserListItem from '../UserListItem/UserListItem';

class BugDetailAssignToSelect extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  changeValue(e) {
    let id = e.currentTarget.getAttribute("id");
    var data = {
      assignedTo: id
    };
    this.props.updateBug(this.props.currentBug, data);
  }

  render() {
    var selectedUser = this.props.currentBug["assignedTo"];
    return (
        <div className="bugdetail-actions-dropdown-assignedto">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
              <div className="bugAssignedToItem">
                <UserListItem userId={ selectedUser }></UserListItem>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              { this.props.currentBug.owner.map((item, index) => {
                return (
                  <DropdownItem key={ index } id={ item } onClick={ this.changeValue.bind(this) }>
                    <div className="bugAssignedToItem">
                      <UserListItem userId={ item }></UserListItem>
                    </div>
                  </DropdownItem>
                )
              }) }
              <DropdownItem id={ null } onClick={ this.changeValue.bind(this) }>
                  <div className="bugAssignedToItem">
                    <UserListItem userId=""></UserListItem>
                  </div>
                </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentBug: state.bug.currentBug
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      updateBug: (bug, data) => {
        dispatch(updateBug(bug, data));
      }
    };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugDetailAssignToSelect);