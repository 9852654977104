import MongoClient from '../../../mongodb/MongoClient';

export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    user
  }
}

export const RESOLVE_USER = 'RESOLVE_USER'
export const resolveUser = (userId) => {
  return {
    type: RESOLVE_USER,
    userId
  }
}

export const RESOLVE_USER_DONE = 'RESOLVE_USER_DONE'
export const resolveUserDone = (user, userId) => {
  return {
    type: RESOLVE_USER_DONE,
    user,
    userId
  }
}

export const UPDATED_USER_POINTS = 'UPDATED_USER_POINTS'
export const updatedUserPoints = () => {
  return {
    type: UPDATED_USER_POINTS
  }
}

export const UPDATE_USER_POINTS = 'UPDATE_USER_POINTS'
export const updateUserPoints = (userId, bugpoints, projectId) => {
  return {
    type: UPDATE_USER_POINTS,
    userId,
    bugpoints,
    projectId
  }
}

export const SHOW_ADD_CARD_MODAL = 'SHOW_ADD_CARD_MODAL'
export const showAddCardModal = (show) => {
  return {
    type: SHOW_ADD_CARD_MODAL,
    show
  }
}

export const UPDATE_USER_ACTION = 'UPDATE_USER_ACTION'
export const updateUserAction = (userId, data) => {
  return {
    type: UPDATE_USER_ACTION,
    userId,
    data
  }
}

export const UPDATE_USER_ACTION_DONE = 'UPDATE_USER_ACTION_DONE'
export const updateUserActionDone = (userId, data) => {
  return {
    type: UPDATE_USER_ACTION_DONE,
    userId,
    data
  }
}

export const loadCurrentUser = () => {
  return (dispatch) => {
    MongoClient.getInstance().fetchLocalUser().then((user) => {
      if (user) {
        dispatch(setCurrentUser(user));
        window.Intercom('boot', {  
          app_id: 'im0ge218',  
          email: user.email,
          name: user.name,
          user_id: user._id.toHexString()
        });
      }
    })
  }
}

export const loadUser = (userId) => {
  return (dispatch) => {
    dispatch(resolveUser(userId));

    MongoClient.getInstance().loadUser(userId).then((user) => {
      dispatch(resolveUserDone(user, userId));
    })
  }
}

export const updateUser = (userId, data) => {
  return (dispatch) => {
    dispatch(updateUserAction(userId, data));

    MongoClient.getInstance().updateUser(userId, data).then((user) => {});
  }
}