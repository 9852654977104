import {
    LOADING_TRELLO_BOARDS,
    LOADING_TRELLO_BOARDS_SUCCESS,
    LOADING_TRELLO_BOARDS_ERROR,
    LOADING_BOARD_LISTS,
    LOADING_BOARD_LISTS_SUCCESS,
    LOADING_BOARD_LISTS_ERROR,
    LOADING_BOARD_MEMBERS,
    LOADING_BOARD_MEMBERS_SUCCESS,
    LOADING_BOARD_MEMBERS_ERROR,
} from '../../actions/project/trello';

const initialState = {
    loadingTrelloBoards: false,
    loadingTrelloBoardsError: "",
    boards: [],
    lists: [],
    members: [],
};

export default function projects(state = initialState, action) {
    // boards
    if (action.type === LOADING_TRELLO_BOARDS) {
        return Object.assign({}, state, {
            loadingTrelloBoards: action.payload,
        });
    }

    if (action.type === LOADING_TRELLO_BOARDS_SUCCESS) {
        return Object.assign({}, state, {
            loadingTrelloBoards: false,
            boards: action.payload
        });
    }

    if (action.type === LOADING_TRELLO_BOARDS_ERROR) {
        return Object.assign({}, state, {
            loadingTrelloBoards: false,
            boards: [],
            loadingJiraProjectsError: action.payload,
        });
    }
    // board lists
    if (action.type === LOADING_BOARD_LISTS) {
        return Object.assign({}, state, {
            loadingBoardLists: action.payload,
        });
    }

    if (action.type === LOADING_BOARD_LISTS_SUCCESS) {
        return Object.assign({}, state, {
            loadingBoardLists: false,
            lists: action.payload
        });
    }

    if (action.type === LOADING_BOARD_LISTS_ERROR) {
        return Object.assign({}, state, {
            loadingBoardLists: false,
            loadingBoardListsError: action.payload,
            boards: [],
        });
    }
    // board members
    if (action.type === LOADING_BOARD_MEMBERS) {
        return Object.assign({}, state, {
            loadingBoardMembers: action.payload,
        });
    }

    if (action.type === LOADING_BOARD_MEMBERS_SUCCESS) {
        return Object.assign({}, state, {
            loadingBoardMembers: false,
            members: action.payload
        });
    }

    if (action.type === LOADING_BOARD_MEMBERS_ERROR) {
        return Object.assign({}, state, {
            loadingBoardMembers: false,
            loadingBoardMembersError: action.payload,
            members: [],
        });
    }
    return state;
}
