import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import './StepsToReproduce.css';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindow, faCompress, faKeyboard, faStar } from '@fortawesome/pro-regular-svg-icons';

class StepsToReproduce extends Component {
  renderIconForType(type) {
    if (type === "View") {
      return (
        <FontAwesomeIcon icon={ faWindow } />
      );
    }
    if (type === "Button") {
      return (
        <FontAwesomeIcon icon={ faCompress } />
      );
    }
    if (type === "Input") {
      return (
        <FontAwesomeIcon icon={ faKeyboard } />
      );
    }
    return (
      <FontAwesomeIcon icon={ faStar } />
    );
  }

  render() {
    return (
      <div className="callstack-info-container">
          <div className="callstack-info-container-body">
          { this.props.currentStepsToReproduce && this.props.currentStepsToReproduce.map((value, index) => {
              return (
                  <div className="steps-to-reproduce" key={ index }>
                      <div className="steps-to-reproduce-icon-container">
                          <div className="steps-to-reproduce-icon-container-inner">
                              <div className="steps-to-reproduce-icon">
                                  { this.renderIconForType(value.type) }
                              </div>
                          </div>
                      </div>
                      <div className="steps-to-reproduce-header">
                          <div className="steps-to-reproduce-header-title">{ value.type }</div>
                          <div className="steps-to-reproduce-header-date"><Moment format="hh:mm A">{ value.date }</Moment></div>
                      </div>
                      <div className="steps-to-reproduce-body">{ value.data }</div>
                  </div>
              )
          })}
          { (!this.props.currentStepsToReproduce || this.props.currentStepsToReproduce.length === 0) && 
              <div className="bug-detail-no-data-how-to"><span role="img" aria-label="ufo">🛸</span> No console logs captured.</div>
          }
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentStepsToReproduce: state.bug.currentStepsToReproduce
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => {
      dispatch(push(url));
    }
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepsToReproduce);