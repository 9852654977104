import { push } from 'connected-react-router';
import MongoClient from '../../../mongodb/MongoClient';
import { loadBugs } from '../bug/bugs';
import { loadUser } from '../user/users';

/**
 * Load projects
 */
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const fetchProjects = () => {
  return {
    type: FETCH_PROJECTS,
  };
};

export const FETCH_PROJECTS_DONE = 'FETCH_PROJECTS_DONE';
export const fetchProjectsDone = projects => {
  return {
    type: FETCH_PROJECTS_DONE,
    projects,
  };
};

export const loadProjects = () => {
  return dispatch => {
    dispatch(fetchProjects());

    MongoClient.getInstance()
      .loadProjects()
      .then(projects => {
        dispatch(fetchProjectsDone(projects));
      });
  };
};

export const SHOW_INTEGRATION_MODAL = 'SHOW_INTEGRATION_MODAL';
export const showIntegrationModal = (show, integration) => {
  return {
    type: SHOW_INTEGRATION_MODAL,
    show,
    integration,
  };
};

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const updateProjectData = data => {
  return {
    type: UPDATE_PROJECT,
    data,
  };
};

export const UPDATE_PROJECT_DONE = 'UPDATE_PROJECT_DONE';
export const updateProjectDataDone = project => {
  return {
    type: UPDATE_PROJECT_DONE,
    project,
  };
};

/**
 * Remove project
 */
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const deleteProject = projectId => {
  return {
    type: DELETE_PROJECT,
    projectId,
  };
};

export const DELETE_PROJECT_DONE = 'DELETE_PROJECT_DONE';
export const deleteProjectDone = projectId => {
  return {
    type: DELETE_PROJECT_DONE,
    projectId,
  };
};

/**
 * Load project
 */
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const fetchProject = projectId => {
  return {
    type: FETCH_PROJECT,
    projectId: projectId,
  };
};

export const FETCH_PROJECT_DONE = 'FETCH_PROJECT_DONE';
export const fetchProjectDone = project => {
  return {
    type: FETCH_PROJECT_DONE,
    project,
  };
};

export const loadProject = projectId => {
  return (dispatch, getState) => {
    let currentBugs = getState().bug.bugs;
    if (currentBugs && currentBugs.length > 0 && projectId === getState().project.currentProject._id) {
      return;
    }
    dispatch(fetchProject(projectId));
    MongoClient.getInstance()
      .loadProject(projectId)
      .then(project => {
        if (project && project.owner && project.owner.length > 0) {
          // Load all users for the project.
          project.owner.map(userId => {
            return dispatch(loadUser(userId));
          });
        }

        dispatch(fetchProjectDone(project));
        dispatch(loadBugs(projectId, project.bugStatusList));
      });
  };
};

export const refreshProject = projectId => {
  return dispatch => {
    dispatch(fetchProject(projectId));
    MongoClient.getInstance()
      .loadProject(projectId)
      .then(project => {
        if (project && project.owner && project.owner.length > 0) {
          // Load all users for the project.
          project.owner.map(userId => {
            return dispatch(loadUser(userId));
          });
        }

        dispatch(fetchProjectDone(project));
        dispatch(loadBugs(projectId, project.bugStatusList));
      });
  };
};

export const updateProject = (projectId, data) => {
  return dispatch => {
    dispatch(updateProjectData(data));
    MongoClient.getInstance()
      .updateProject(projectId, data)
      .then(project => {
        dispatch(updateProjectDataDone(project));
      });
  };
};

export const removeProject = projectId => {
  return dispatch => {
    dispatch(deleteProject(projectId));

    MongoClient.getInstance()
      .removeProject(projectId)
      .then(() => {
        dispatch(deleteProjectDone(projectId));
        dispatch(push('/dashboard/'));
      });
  };
};

/**
 * Connect jira to project
 * @param {ObjectID} projectID the mongodb ID of the project.
 */
export const connectJiraToProject = projectID => {
  return dispatch => {
    MongoClient.getInstance()
      .onProjectChange(projectID)
      .then(stream => {
        stream.onNext(event => {
          stream.close();
          dispatch(refreshProject(projectID));
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
};

/**
 * Remove jira from project
 * @param {ObjectID} projectID the mongodb id of the project.
 */
export const removeJiraFromProject = projectID => {
  return dispatch => {
    MongoClient.getInstance()
      .updateProject(projectID, {
        jira: {},
      })
      .then(res => {
        dispatch(refreshProject(projectID));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

/**
 * Connect Trello to project 
 * @param {ObjectID} projectID the mongodb ID of the project.
 */
export const connectTrelloToProject = (projectID, token) => {
  return dispatch => {
    MongoClient.getInstance()
      .updateProject(projectID, {
        trello: {
          token
        },
      })
      .then(() => {
        dispatch(refreshProject(projectID));
      })
      .catch(err => console.log(err));
  };
};

/**
 * Remove Trello from project
 * @param {ObjectID} projectID the mongodb id of the project.
 */
export const removeTrelloFromProject = projectID => {
  return dispatch => {
    MongoClient.getInstance()
      .updateProject(projectID, {
        trello: {},
      })
      .then(() => {
        dispatch(refreshProject(projectID));
      })
      .catch(err => console.log(err));
  };
};