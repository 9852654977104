import axios from 'axios';

// our app api key
const apiKey = "b41c95c287b125e3ebf0983b65998e28";

/**
 * 
 * @param {string} token 
 */
export const loadBoards = (token) => {
    return axios.get(`https://api.trello.com/1/members/me/boards?key=${apiKey}&token=${token}`);
};

/**
 * Load lists in board
 * @param {string} boardId 
 * @param {string} token 
 */
export const loadBoardLists = (boardId, token) => {
    return axios.get(`https://api.trello.com/1/boards/${boardId}/lists?key=${apiKey}&token=${token}`);
};

/**
 * Load lists in board
 * @param {string} boardId 
 * @param {string} token 
 */
export const loadBoardMembers = (boardId, token) => {
    return axios.get(`https://api.trello.com/1/boards/${boardId}/members?key=${apiKey}&token=${token}`);
};

export default { loadBoards, loadBoardLists, loadBoardMembers }
