import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { BarLoader } from 'react-spinners';

class UserInvoices extends Component {
    state = {
        invoices: [],
        loading: true
    }

    componentDidMount() {
        this.fetchInvoices();
    }

    fetchInvoices() {
        fetch('https://payment.bugbattle.io/get_invoices.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                billingCustomerId: this.props.currentUser.billingCustomerId
            })
        }).then((data) => {
            data.json().then(json => {
                this.setState({
                    invoices: json,
                    loading: false
                });
            });
        }).catch((err) => {
            this.setState({
                loading: false
            });
        });
    }

    renderLoadingComponent() {
        return (
            <BarLoader
            sizeUnit={"px"}
            width={ 100 }
            height={ 4 }
            color={'#3574F3'}
            loading={ true }
            />
        );
    }

    render() {
        if (this.state.loading) {
            return this.renderLoadingComponent();
        }

        return (
            <div>
                {
                    (this.state.invoices && this.state.invoices.data && this.state.invoices.data.length > 0) ? this.state.invoices.data.map((invoice) => {
                        return (
                            <div className="invoice-line">
                                <div className="invoice-line-description">
                                    <b>{ (invoice.lines.data && invoice.lines.data.length > 0) ? invoice.lines.data[0].description : "No description" }</b><br />
                                    { (invoice.amount_due / 100.0).toFixed(2) }$
                                </div>
                                <div><Moment format="MM/DD/YYYY">{ invoice.data }</Moment></div>
                                <div><a href={ invoice.invoice_pdf } target="_blank" rel="noopener noreferrer">Download</a></div>
                            </div>
                        )
                    }) :
                    <div>No invoices yet. If this is a bug, <a href="#" onClick={() => {
                        window.Intercom('showNewMessage');
                    }}>let us know</a>!</div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser
  };
};

export default connect(
  mapStateToProps,
  null
)(UserInvoices);