import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import './ProjectSelectionList.css'
import ProjectCard from '../ProjectCard/ProjectCard';
import { push } from 'connected-react-router';

class ProjectSelectionList extends Component {
  render() {
    return (
      <div className="">
        <div className="">
          <div className="projectheader">
            <h1>Your projects</h1>
          </div>
          <div className="subtitle-text">
              Choose the project you want to get started or create a new one!
          </div>
        </div>
        <div className="projects-list-description">
          <Button color="primary" onClick={() => {
            this.props.push("/createproject/");
          }}>+ Create project</Button>
        </div>
        <div className="projects-list">
        {
          this.props.projects.map(project => {
            return (
                <ProjectCard key={ project._id } project={ project }></ProjectCard>
            );
          })
        }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.project.projects
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      push: (url) => {
          dispatch(push(url));
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSelectionList);