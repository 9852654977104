import React, { Component } from 'react';
import Confetti from 'react-dom-confetti';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';
import classNames from 'classnames';
import './TesterPage.css';
import { loadBugsForTester, setFilterForTester } from '../../redux/actions/tester/tester';
import ExternalBugCard from '../../components/ExternalBugCard/ExternalBugCard';

class TesterPage extends Component {
    componentDidMount() {
        let projectId = this.props.match.params.project;
        let testerEmail = this.props.match.params.email;
        let accessKey = this.props.match.params.accessKey;

        this.props.loadBugsForTester(projectId, testerEmail, accessKey);
    }

    getFilteredBugs() {
        if (!this.props.filter || this.props.filter === "") {
            return this.props.bugs;
        }

        return this.props.bugs.filter((el) => {
            return el.status === this.props.filter;
        });
    }

    render() {
        let filteredBugs = this.getFilteredBugs();
        return (
            <div>
                <div className="ExternalContainer">
                    <div className="ExternalAppHeader">
                        <div className="title">Bugs</div>
                        <div className="actions">
                            <ButtonGroup>
                                <Button color="primary" className={classNames({ 'active': this.props.filter === "" })} onClick={() => { this.props.setFilter(""); }}>All</Button>
                                <Button color="primary" className={classNames({ 'active': this.props.filter === "feedbackneeded" })} onClick={() => { this.props.setFilter("feedbackneeded"); }}>Feedback needed</Button>
                                <Button color="primary" className={classNames({ 'active': this.props.filter === "readytotestagain" })} onClick={() => { this.props.setFilter("readytotestagain"); }}>Test again</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="externalBugsContainer">
                    {
                        (filteredBugs && filteredBugs.length > 0) && filteredBugs.map((bug, index) => {
                            return (<ExternalBugCard key={index} bug={bug}></ExternalBugCard>);
                        })
                    }
                    </div>
                </div>
                <div className={classNames({ 'loadingOverlayExternal': true, 'loadingOverlayExternal--active': this.props.confettiParty })}>
                    <div className="center-confettig-for-tester">
                        <Confetti active={ this.props.confettiParty } config={{
                            angle: 90,
                            spread: 40,
                            startVelocity: 35,
                            elementCount: 60,
                            decay: 0.9
                        }}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      bugs: state.tester.bugs,
      loading: state.tester.loading,
      filter: state.tester.filter,
      confettiParty: state.tester.confettiParty
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadBugsForTester: (projectId, testerEmail, accessKey) => {
            dispatch(loadBugsForTester(projectId, testerEmail, accessKey));
        },
        setFilter: (filter) => {
            dispatch(setFilterForTester(filter));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TesterPage);
