import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BugDetailPrioritySelect.css'

import { updateBug } from '../../redux/actions/bug/bugs';
import { getPriority } from '../../helper/bugdetails';

class BugDetailPrioritySelect extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  getButtonLabel() {
    return getPriority(this.props.currentBug["priority"], this.props.bugPriorities);
  }
  
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  changeValue(e) {
    let id = e.currentTarget.getAttribute("id");
    var data = {
      priority: id
    };
    this.props.updateBug(this.props.currentBug, data);
  }

  render() {
    var currentStatus = this.getButtonLabel();
    return (
        <div className="bugdetail-actions-dropdown-priorities">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
              <div className="bugPriorityItem">
                  <span className="bugPriorityItemIcon"><FontAwesomeIcon icon={ currentStatus.icon } /></span>
                  <span>{ currentStatus.name }</span>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              { this.props.bugPriorities.map((item, index) => {
                return (
                  <DropdownItem key={ index } id={ item.key } onClick={ this.changeValue.bind(this) }>
                    <div className="bugPriorityItem">
                      <span className="bugPriorityItemIcon"><FontAwesomeIcon icon={ item.icon } /></span>
                      <span>{ item.name }</span>
                    </div>
                  </DropdownItem>
                )
              }) }
            </DropdownMenu>
          </Dropdown>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentBug: state.bug.currentBug,
    bugPriorities: state.bug.bugPriorities
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      updateBug: (bug, data) => {
        dispatch(updateBug(bug, data));
      }
    };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugDetailPrioritySelect);