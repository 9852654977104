import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class HomePage extends Component {
  componentDidMount() {

  }

  render() {
    if (this.props.currentUser && this.props.currentUser._id) {
      this.props.push("/dashboard");
    } else {
      this.props.push("/login");
    }

    return (
      <div className="App"></div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      push: (url) => {
        dispatch(push(url));
      },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
