import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BarLoader } from 'react-spinners';
import './Comments.css';
import { loadComments } from '../../redux/actions/bug/bugs';
import Comment from '../Comment/Comment';

class Comments extends Component {
    render() {
        if (this.props.commentsLoading) {
            return (
                <div className="comments-no-comments">
                    <BarLoader
                        sizeUnit={"px"}
                        width={ 100 }
                        height={ 4 }
                        color={'#3574F3'}
                        loading={ true }
                    /> 
                </div>
            )
        }

        if (this.props.comments.length === 0) {
            return (
                <div className="comments-no-comments">
                    No comments yet.
                </div>
            )
        }

        return (
            <div className="comments">
                {
                    this.props.comments.map((comment, index) => {
                        return (
                            <Comment key={ comment._id } comment={ comment }></Comment>
                        )
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    currentBug: state.bug.currentBug,
    comments: state.bug.comments,
    commentsLoading: state.bug.commentsLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      loadComments: (bugId) => {
          dispatch(loadComments(bugId));
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);