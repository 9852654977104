import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import JSONTree from 'react-json-tree';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/pro-regular-svg-icons';
import './BugDetailTabs.css';

import BugDetailsMainPage from '../BugDetail/BugDetailsMainPage';
import StepsToReproduce from '../StepsToReproduce/StepsToReproduce';
import Logs from '../Logs/Logs';

class BugDetailTabs extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '0'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

  render() {
    return (
        <div className="">
            <div className="navitem-container">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '0' })}
                        onClick={() => { this.toggle('0'); }}>
                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}>
                            Steps to reproduce
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}>
                            Console log
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4'); }}>
                            Custom data
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="0">
                    <BugDetailsMainPage></BugDetailsMainPage>
                </TabPane>
                <TabPane tabId="1">
                    <StepsToReproduce></StepsToReproduce>
                </TabPane>
                <TabPane tabId="2">
                    <Logs></Logs>
                </TabPane>
                <TabPane tabId="4">
                        { (this.props.currentBug.customData && Object.keys(this.props.currentBug.customData).length > 0) &&
                            <JSONTree 
                                data={ this.props.currentBug.customData }
                                theme={{
                                    scheme: 'google',
                                    author: 'seth wright (http://sethawright.com)',
                                    base00: '#1d1f21',
                                    base01: '#282a2e',
                                    base02: '#373b41',
                                    base03: '#969896',
                                    base04: '#b4b7b4',
                                    base05: '#c5c8c6',
                                    base06: '#e0e0e0',
                                    base07: '#ffffff',
                                    base08: '#CC342B',
                                    base09: '#F96A38',
                                    base0A: '#FBA922',
                                    base0B: '#198844',
                                    base0C: '#3971ED',
                                    base0D: '#3971ED',
                                    base0E: '#A36AC7',
                                    base0F: '#3971ED'
                                }}
                            />
                        }
                        { (!this.props.currentBug.customData || Object.keys(this.props.currentBug.customData).length === 0) && 
                            <div className="bug-detail-no-data-how-to"><span role="img" aria-label="ufo">🥑</span> No custom data captured. Learn how to <a href="https://developers.bugbattle.io/?q=custom+data" target="_blank" rel="noopener noreferrer">add custom data</a> to bug reports!</div>
                        }
                </TabPane>
            </TabContent>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentBug: state.bug.currentBug
  };
};

export default connect(
  mapStateToProps,
  null
)(BugDetailTabs);