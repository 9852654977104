import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Comment.css'

import { loadUser } from '../../redux/actions/user/users';
import Moment from 'react-moment';

class Comment extends Component {
  componentDidMount() {
    if (this.getUser()._id === null && !this.props.comment.reportedBy) {
      this.props.loadUser(this.props.comment.createdBy);
    }
  }

  getUser() {
    var user = this.props.users[this.props.comment.createdBy];
    if (user) {
      return user;
    }
    return {
      _id: null,
      name: "Tester",
      avatar: null,
      email: ""
    };
  }

  render() {
    var user = this.getUser();
    return (
        <div className="comment-item">
          <div>
            { user.avatar ? <img alt="Avatar" src={ user.avatar }></img> : <div className="comment-item-img-loading"></div> } 
            <span className="comment-item-name">{ user.name ? user.name : '...' }</span> 
            <span>{ this.props.comment.comment }</span>
          </div>
          <div className="comment-item-createdAt">
            <Moment fromNow>{ this.props.comment.createdAt }</Moment>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.user.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: (userId) => {
      dispatch(loadUser(userId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comment);