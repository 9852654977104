import React, { Component } from 'react';
import { connect } from 'react-redux';
import Steps, { Step } from 'rc-steps';
import { Button } from 'reactstrap';
import Highlight from 'react-highlight';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import './AndroidIntegrationTutorialModal.css';
import { push } from 'connected-react-router';

class AndroidIntegrationTutorialModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      language: 'swift',
      currentStep: 0,
      steps: [{
        title: "Add BugBattle SDK"
      }, {
        title: "Add initialization code"
      }, {
        title: "Verify",
      }]
    };
  }
  
  prevStep = () => {
    let s = this.state.currentStep - 1;
    this.setState({
      currentStep: s
    });
  }

  nextStep = () => {
    let s = this.state.currentStep + 1;
    if (s === this.state.steps.length) {
      s = 0;
    }
    this.setState({
      currentStep: s
    });
  }

  render() {
    const cs = this.state.currentStep;
    this.stepsRefs = [];
    return (
      <div className="CardBoxBorder CardBoxBorder--Modal">
        <form className="my-step-form">
          <div className="my-step-container">
            <Steps current={cs}>
              {
                this.state.steps.map((s, i) => {
                  return (
                    <Step ref={c => this.stepsRefs[i] = c}
                      key={i}
                      title={s.title}
                    />
                  );
                })
              }
            </Steps>
          </div>
          <div className="IntegrationModalBody">
              { (cs === 0) && <div>
                <div className="mt-16">Open your app in your preferred  development tool, for e.g. Android Studio.</div>
                <div className="boldtext mt-16">Add the repository</div>
                <div className="mt-16">As already mentioned, it’s very simple to include our SDK. Add the following repository to your root build.gradle, to get access to the JitPack repository, which hosts our SDK.</div>
                <Highlight className='java'>
                    { `allprojects {
    repositories {
      ...
      maven {
        url 'https://jitpack.io'
      }
    }
  }` }
                </Highlight>
                <div className="boldtext mt-16">Add the dependency</div>
                <div className="mt-16">In the same file ( build.gradle ) add the dependency to include the library and replace the version with the latest one.</div>
                <Highlight className='java'>
                    { `dependencies {
  ...
  implementation 'com.github.BugBattle:BugBattle-Android-SDK:{lastest version}'
}` }
                </Highlight>
                <div>Current Version: <img src="https://jitpack.io/v/BugBattle/BugBattle-Android-SDK.svg" /></div>
                <div className="mt-16">You are now ready to use our SDK in your app. Let's carry on with the initialisation 🎉</div>
                <div className="mt-16 mb-16">Check out our <a href="https://developers.bugbattle.io/android-sdk/getting-started/getting-started" target="_blank" rel="noopener noreferrer">documentation</a> for more details.</div>
              </div> }
              { (cs === 1) && <div>
                <div>
                  <div className="boldtext mt-16">Initialise the SDK</div>
                  <div className="mt-16">Open your preferred development tool and add the following imports.</div>
                  <Highlight className='java'>
                    { "import bugbattle.io.bugbattle.BugBattle;\nimport bugbattle.io.bugbattle.BugBattleActivationMethod;" }
                  </Highlight>
                  <div className="mt-16">Add the initialise method to your main activity's <i>onCreate</i> method.</div>
                  <Highlight className='java'>
                    { `@Override protected void onCreate(Bundle savedInstanceState) {
  ....
  BugBattle.initialise("` + this.props.currentProject._id.toHexString() + `", BugBattleActivationMethod.SHAKE, this);
} `}
                  </Highlight>
                  <div className="mt-16">The initialise method takes two parameters. The api key and the activation method. There are two ways to start the bug reporting flow. The default option is to activate the flow by shaking the device (<i>BugBattleActivationMethod.SHAKE</i>). You can also choose BugBattleActivationMethod.NONE and start the flow by yourself in order to implement a custom integration.</div>
                </div>
                <div className="boldtext mt-16">Save & run <span role="img" aria-label="rocket">🚀</span></div>
                <div className="mt-16 mb-16">Need help? Check out our <a href="https://developers.bugbattle.io/android-sdk/getting-started/getting-started" target="_blank" rel="noopener noreferrer">documentation</a> or <a href="#" onClick={() => {
                  window.Intercom('showNewMessage');
                }}>contact us</a>.</div>
              </div> }
              { (cs === 2) && <div className="success-panel">
                <div className="boldtext mt-16">Congratulations <span role="img" aria-label="rocket">🚀</span></div>
                <div className="mt-16 mb-16">You have installed BugBattle successfully! If you run into problems during installation, just <a href="#" onClick={() => {
                  window.Intercom('showNewMessage');
                }}>let us know</a>! We are here to help!</div>
                <div className="IntegrationModalBodyButtons">
                  <Button size="lg" color="primary" onClick={() => {
                    this.props.push('/project/' + this.props.currentProject._id + '/');
                  }}>Go to dashboard</Button>
                </div>
              </div> }
          </div>
          {
            (cs < 2) && 
            <div className="IntegrationModalBodyButtons">
              { cs > 0 && <Button size="lg" color="link" onClick={this.prevStep}>Back</Button> }
              <Button size="lg" color="primary" onClick={this.nextStep}>Next</Button>
            </div>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => {
      dispatch(push(url));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AndroidIntegrationTutorialModal);
