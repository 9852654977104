import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { createProject } from '../../redux/actions/project/createProject';
import './CreateProjectButton.css'

class CreateProjectButton extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      projectName: ""
    };
  }

  render() {
    return (
      <div className="createprojectbutton">
        <div className="projectheader">
          <h1>Create a new project</h1>
        </div>
        <div className="subtitle-text">
          Let's start by choosing a project name!
        </div>
        <Input placeholder="Projectname" value={this.state.projectName} onChange={(event) => {
            this.setState({ projectName: event.target.value })
        }}></Input>
        <div className="createprojectbutton-btn">
          <Button size="lg" color="primary" disabled={this.state.projectName === ""} className="mb-16" onClick={() => {
            if (this.state.projectName === "") {
              return;
            }
            
            this.props.createProject(this.state.projectName);
          }}>
          Create project
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (projectName) => {
      dispatch(createProject(projectName));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(CreateProjectButton);