import { SELECT_PROJECT, SET_NAV_ROUTE } from "../../actions/project/projectUI";

const initialState = {
    selectedProjectId: null,
    currentRoute: "list"
};

export default function projectUI(state = initialState, action) {
    if (action.type === SELECT_PROJECT) {
        return Object.assign({}, state, {
            selectedProjectId: action.projectId
        });
    }
    if (action.type === SET_NAV_ROUTE) {
        return Object.assign({}, state, {
            currentRoute: action.route
        });
    }
    return state;
}  