import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { FixedSizeList as List } from 'react-window';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import { push } from 'connected-react-router';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import './BugListComponent.css';
import { selectBug, reportBug } from '../../redux/actions/bug/bugs';
import BugDetailFilter from '../BugDetailFilter/BugDetailFilter';
import { getStorypoints, getPriority, getBugPointsClass } from '../../helper/bugdetails';
import UserSmall from '../UserSmall/UserSmall';
import { BarLoader } from 'react-spinners';

const modalStyle = {
  content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
      maxWidth: '50vw'
  }
};

class BugListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowHeight: window.innerHeight,
      showCreateBugModal: false,
      bugDescription: ""
    };
  }

  updateDimensions() {
    this.setState({
      windowHeight: window.innerHeight
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  getStatusColorForStatusKey(statusKey) {
    var item = this.props.bugStatusObject[statusKey];
    if (item) {
      return item.color;
    }
    return '#888';
  }

  getBugListForStatus() {
    if (this.props.currentBugListFilter.status === "" || this.props.currentBugListFilter.status === null) {
      return this.props.bugs;
    }
    var buglist = this.props.bugStatusListBugs[this.props.currentBugListFilter.status];
    if (buglist) {
      return buglist;
    }
    return [];
  }

  getBugList() {
    let statusList = this.getBugListForStatus();
    let assignedToFilter = this.props.currentBugListFilter.assignedTo;
    if (assignedToFilter && assignedToFilter !== "" && assignedToFilter !== "null") {
      return statusList.filter(function(object) { return object.assignedTo === assignedToFilter });
    }
    return statusList;
  }

  showProjectSettings() {
    this.props.push('/project/' + this.props.currentProject._id + '/settings');
  }

  reportBug() {
    this.props.reportBug(this.props.currentUser.email, this.state.bugDescription, this.props.currentProject._id.toString());
    this.setState({
      showCreateBugModal: false,
      bugDescription: ""
    });
  }

  renderIntegrationInfo() {
    return (<div className="no-reports-yet-tutorial">
      <div className="no-reports-yet-tutorial-header">No bug reports yet!</div>
      <div className="no-reports-yet-tutorial-body">
      Find out how to <a href="#" onClick={ this.showProjectSettings.bind(this) }>integrate the BugBattle SDK</a> within your apps!
      </div>
      <Button block color="primary" onClick={ this.showProjectSettings.bind(this) }>
      Get started
      </Button>
    </div>);
  }

  renderBugList(buglist) {
    if (this.props.bugs.length <= 0) {
      return this.renderIntegrationInfo();
    }
    return (
    <List
        height={ (this.state.windowHeight - 107) }
        itemCount={ buglist.length }
        itemSize={ 100 }>
        {({ index, style }) => {
          let bug = buglist[index];
          let bugStorypoint = getStorypoints(bug);
          let bugPriorities = getPriority(bug["priority"], this.props.bugPriorities);
          return (
            <div className="bug" style={{...style, ...{
              backgroundColor: this.props.currentBug._id === bug._id ? '#fff' : 'transparent'
            }}} key={ bug._id } onClick={() => {
              this.props.selectBug(bug);
            }}>
              <div className="bugTitleContainer">
                <div className="bugTitle">
                  <div className="bugStatusBubbleInline" style={{
                    backgroundColor: this.getStatusColorForStatusKey(bug.status)
                  }}></div>
                  { bug.name }
                </div>
                <div className="bugItemDate">
                  <Moment fromNow>{ bug.createdAt }</Moment>
                </div>
              </div>
              <div className="bugDescription">{ bug.description }</div>
              <div className="bugStateContainer">
                <div className="bugStorypointContainer">
                  <span className={ getBugPointsClass(bugStorypoint) }>{ bugStorypoint }</span>
                  <span className="bugStorypointIcon ml-8"><FontAwesomeIcon icon={ bugPriorities.icon } /></span>
                  <span>{ bugPriorities.name }</span>
                </div>
                <div className="bugStorypointContainer bugStorypointContainerFlex">
                  <UserSmall userId={ bug.assignedTo }></UserSmall>
                </div>
              </div>
            </div>
          )
        }}
      </List>
    );
  }

  render() {
    var buglist = this.getBugList();
    return (
      <div className="buglist">
        <Modal
        isOpen={this.state.showCreateBugModal}
        contentLabel="Report new bug"
        style={ modalStyle }
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          this.setState({
            showCreateBugModal: false
          });
        }}
        ariaHideApp={false}>
          <div className="new-bug-modal">
                <InputGroup>
                  <Input type="text" placeholder="What went wrong?" value={ this.state.comment } onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                          this.reportBug();
                      }
                  }} onChange={(event) => {
                      this.setState({ bugDescription: event.target.value })
                  }} />
                  <InputGroupAddon addonType="append">
                  <Button disabled={ this.state.bugDescription === "" } color="primary" onClick={() => {
                      this.reportBug();
                  }}>
                      Report
                  </Button>
                  </InputGroupAddon>
                </InputGroup>
          </div>
          <div className="bug-modal-close-btn" onClick={() => {
            this.setState({
              showCreateBugModal: false
            });
          }}>
              <FontAwesomeIcon color="#fff" icon={ faTimes } />
          </div>
        </Modal>
        <div className="buglistHeader">
          <div className="buglist-header-title-container">
            <div className="buglistHeaderTitle">{ buglist.length } Bugs</div>
            <div className="buglist-header-title-container-new">
              <Button className="add-button" style={{
              }} color="primary" onClick={() => {
                this.setState({
                  showCreateBugModal: true
                });
              }}>
                <FontAwesomeIcon color="#fff" icon={ faPlus } /> Add bug
              </Button>
            </div>
          </div>
          <div className="buglistHeaderFilter">
            <BugDetailFilter></BugDetailFilter>
          </div>
        </div>
        <div className="bugcontainer">
          {
            this.props.loadingBugs ? <div className="bugsListLoading">
              <BarLoader
                sizeUnit={"px"}
                width={ 100 }
                height={ 4 }
                color={'#3574F3'}
                loading={ true }
              />
            </div> : this.renderBugList(buglist)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bugStatusListBugs: state.bug.bugStatusListBugs,
    selectedProject: state.projectUI.selectedProjectId,
    currentBug: state.bug.currentBug,
    bugStatusObject: state.bug.bugStatusObject,
    bugs: state.bug.bugs,
    currentBugListFilter: state.bug.currentBugListFilter,
    bugStorypoints: state.bug.bugStorypoints,
    bugPriorities: state.bug.bugPriorities,
    loadingBugs: state.bug.loadingBugs,
    currentProject: state.project.currentProject,
    currentUser: state.user.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      selectBug: (bug) => {
          dispatch(selectBug(bug));
      },
      push: (url) => {
        dispatch(push(url));
      },
      reportBug: (reportedBy, description, apiToken) => {
        dispatch(reportBug(reportedBy, description, apiToken));
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugListComponent);