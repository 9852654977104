import React, { Component } from 'react';
import { connect } from 'react-redux';
import Steps, { Step } from 'rc-steps';
import { Button } from 'reactstrap';
import Highlight from 'react-highlight';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import './JSIntegrationTutorialModal.css';
import { push } from 'connected-react-router';

class JSIntegrationTutorialModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      language: 'staticfiles',
      currentStep: 0,
      steps: [{
        title: "Add BugBattle SDK"
      }, {
        title: "Add initialization code"
      }, {
        title: "Verify",
      }]
    };
  }
  
  prevStep = () => {
    let s = this.state.currentStep - 1;
    this.setState({
      currentStep: s
    });
  }

  nextStep = () => {
    let s = this.state.currentStep + 1;
    if (s === this.state.steps.length) {
      s = 0;
    }
    this.setState({
      currentStep: s
    });
  }

  render() {
    const cs = this.state.currentStep;
    this.stepsRefs = [];
    return (
      <div className="CardBoxBorder CardBoxBorder--Modal">
        <form className="my-step-form">
          <div className="my-step-container">
            <Steps current={cs}>
              {
                this.state.steps.map((s, i) => {
                  return (
                    <Step ref={c => this.stepsRefs[i] = c}
                      key={i}
                      title={s.title}
                    />
                  );
                })
              }
            </Steps>
          </div>
          <div className="IntegrationModalBody">
              { (cs === 0) && <div>
                <div className="programingLanguageSelection">
                  <Button color={ this.state.language === 'staticfiles' ? 'primary' : 'secondary' } onClick={() => {
                    this.setState({
                      language: 'staticfiles'
                    });
                  }}>Static files</Button>
                  <Button color={ this.state.language === 'npm' ? 'primary' : 'secondary' } onClick={() => {
                    this.setState({
                      language: 'npm'
                    });
                  }}>npm</Button>
                </div>
                {
                  this.state.language === 'staticfiles' ? <div>
                    <div className="boldtext mt-16">Import the BugBattle SDK</div>
                    <div className="mt-16">Import the BugBattle SDK by adding the following imports to your webapp's / webapp's head-tag.</div>
                    <Highlight className='javascript'>
                      {`<link href="https://jssdk.bugbattle.io/latest/index.css" rel="stylesheet">
<script src="https://jssdk.bugbattle.io/latest/index.js"></script>
<script>let BugBattle = window.BugBattle.default;</script>`}
                    </Highlight>
                  </div> : <div>
                    <div className="boldtext mt-16">Install the BugBattle SDK</div>
                    <div className="mt-16">Install the BugBattle SDK by running the following command.</div>
                    <Highlight className='javascript'>
                      {"npm install bugbattle --save"}
                    </Highlight>
                    <div className="boldtext mt-16">Import the BugBattle SDK</div>
                    <div className="mt-16">Then import the BugBattle SDK and CSS by adding the following import statements to your project.</div>
                    <Highlight className='javascript'>
                      {`import BugBattle from 'bugbattle';
import 'bugbattle/build/index.css';`}
                    </Highlight>
                  </div>
                }
                <div className="mt-16 mb-16">Need help? Check out our <a href="https://github.com/BugBattle/BugBattle-JavaScript-SDK" target="_blank" rel="noopener noreferrer">documentation</a> or <a href="#" onClick={() => {
                  window.Intercom('showNewMessage');
                }}>contact us</a>.</div>
              </div> }
              { (cs === 1) && <div>
                <div className="boldtext mt-16">Import the BugBattle SDK</div>
                <div className="mt-16">Add the following code to your app / website to initialize the BugBattle JavaScript SDK.<br /><i>Note: this has to be done after the SDK imports!</i></div>
                <Highlight className='javascript'>
                      {`<script>
let bugBattle = new BugBattle("` + this.props.currentProject._id.toHexString() + `", BugBattle.FEEDBACK_BUTTON);
</script>`}
                </Highlight>
                <div className="mt-16 mb-16">Check out our <a href="https://github.com/BugBattle/BugBattle-JavaScript-SDK" target="_blank" rel="noopener noreferrer">documentation</a> for more informations & customization options (color scheme, custom data, ...).</div>
              </div> }
              { (cs === 2) && <div className="success-panel">
                <div className="boldtext mt-16">Congratulations <span role="img" aria-label="rocket">🚀</span></div>
                <div className="mt-16 mb-16">You have installed BugBattle successfully! If you run into problems during installation, just <a href="#" onClick={() => {
                  window.Intercom('showNewMessage');
                }}>let us know</a>! We are here to help!</div>
                <div className="IntegrationModalBodyButtons">
                  <Button size="lg" color="primary" onClick={() => {
                    this.props.push('/project/' + this.props.currentProject._id + '/');
                  }}>Go to dashboard</Button>
                </div>
              </div> }
          </div>
          {
            (cs < 2) && 
            <div className="IntegrationModalBodyButtons">
              { cs > 0 && <Button size="lg" color="link" onClick={this.prevStep}>Back</Button> }
              <Button size="lg" color="primary" onClick={this.nextStep}>Next</Button>
            </div>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.project.currentProject
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => {
      dispatch(push(url));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JSIntegrationTutorialModal);