import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import './ExternalCommentCreation.css';
import { sendExternalComment } from '../../redux/actions/tester/tester';

class ExternalCommentCreation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: ""
        };
    }

    componentDidMount() {

    }

    sendComment(status) {
        this.props.sendExternalComment(this.props.bug._id, this.state.comment, status);
        this.setState({
            comment: ""
        });
    }

    renderBugFeedback() {
        return (
            <div className="comment-creation">
                <InputGroup>
                    <Input type="text" placeholder="Write your feedback..." value={ this.state.comment } onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.sendComment("open");
                        }
                    }} onChange={(event) => {
                        this.setState({ comment: event.target.value })
                    }} />
                    <InputGroupAddon addonType="append">
                    <Button disabled={ this.state.comment === "" } color="primary" onClick={() => {
                        this.sendComment("open");
                    }}>
                        Send
                    </Button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        );
    }

    renderVerifyBug() {
        return (
            <div className="comment-creation mt-0">
                <InputGroup>
                    <Input type="text" placeholder="Write your feedback..." value={ this.state.comment } onKeyPress={(e) => {

                    }} onChange={(event) => {
                        this.setState({ comment: event.target.value })
                    }} />
                </InputGroup>
                <div className="tester-answer-buttons">
                    <Button color="danger" block onClick={() => {
                        this.sendComment("open");
                    }}>
                        NOT FIXED
                    </Button>
                    <Button color="success" block onClick={() => {
                        this.sendComment("done");
                    }}>
                        CONFIRM FIX
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.bug.status === "readytotestagain") {
            return this.renderVerifyBug();
        } else {
            return this.renderBugFeedback();
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendExternalComment: (bugId, comment, status) => {
            dispatch(sendExternalComment(bugId, comment, status));
        }
    };
};

export default connect(
  null,
  mapDispatchToProps
)(ExternalCommentCreation);