
import React, { Component } from 'react';
import Invoices from './Invoices';

class InvoicesList extends Component {
    render() {
        return (
        <div className="box-content mb-32">
            <div className="projectheader">
                <h1>Invoices</h1>
            </div>
            <Invoices></Invoices>
        </div>
        );
    }
}

export default InvoicesList;