import React, { Component } from 'react';
import MongoClient from '../../mongodb/MongoClient';
import { Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import logo from './BugBattleLogo.svg';
import './RegisterPage.css';
import { BarLoader } from 'react-spinners';

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      email: "",
      password: "",
      name: "",
      loading: false
    };
  }

  register() {
    this.setState({
      loading: true
    });
    MongoClient.getInstance().register(this.state.email, this.state.password).then(() => {
      this.setState({
        loading: false
      });
      this.props.history.push('/verifyemail');
    })
    .catch(err => {
      this.setState({
        loading: false,
        password: ""
      });
      if (err) {
        alert(err.toString().replace("StitchServiceError: ", ""));
      }
    });
  }

  renderSignup() {
    return (
      <div className="LoginForm">
        <img src={ logo } alt="BugBattle Logo" />
        <div className="login-signup-title">
          Signup closed
        </div>
        <div>
          We are currently working hard on a super duper redesign of BugBattle. The release of BugBattle 2.0 with lot's of new features and stability improvements is almost here.
        </div>
        <div className="LoginFormRegister">
        Already have an account? <a href="/login">Sign in</a>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (MongoClient.getInstance().getClient().auth.isLoggedIn) {
      this.props.push('/dashboard');
    }
  }

  render() {
    return (
      <div className="LoginContainer">
        { this.state.loading ? <div className="modal-center-content">
          <BarLoader
            sizeUnit={"px"}
            width={ 100 }
            height={ 4 }
            color={'#3574F3'}
            loading={ true }
          />
        </div> : <div>
          { this.renderSignup() }
        </div> }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      push: (url) => {
        dispatch(push(url));
      },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RegisterPage);