import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faCode } from '@fortawesome/pro-regular-svg-icons';
import { loadBugdetailsForTester } from '../../redux/actions/tester/tester';
import ExternalComments from '../ExternalComments/ExternalComments';
import ExternalCommentCreation from '../ExternalCommentCreation/ExternalCommentCreation';

class ExternalBugCard extends Component {
    getLabelForState() {
        if (this.props.bug.status === "readytotestagain") {
            return (<div className="type">Ready to test again.</div>);
        } else {
            return (<div className="type--feedback">More feedback needed.</div>);
        }
    }

    renderMetaData() {
        return (
            <div className="bugdetail-bug-meta">
                <div className="bugdetail-bug-meta-card">
                    <div className="bugdetail-bug-meta-card-icon">
                    <FontAwesomeIcon icon={ faCode } />
                    </div>
                    <div className="bugdetail-bug-meta-card-container">
                    <div className="bugdetail-bug-meta-card-title">APP VERSION</div>
                    <div className="bugdetail-bug-meta-card-data">{ this.props.bug.meta.releaseVersionNumber } ({ this.props.bug.meta.buildVersionNumber })</div>
                    </div>
                </div>
                <div className="bugdetail-bug-meta-card">
                    <div className="bugdetail-bug-meta-card-icon">
                    <FontAwesomeIcon icon={ faMobile } />
                    </div>
                    <div className="bugdetail-bug-meta-card-container">
                    <div className="bugdetail-bug-meta-card-title">DEVICE</div>
                    <div className="bugdetail-bug-meta-card-data">{ this.props.bug.meta.deviceIdentifier }</div>
                    </div>
                </div>
            </div>
        );
    }

    renderNoMetaData() {
        return(
            <div>No meta data available.</div>
        );
    }

    expandDetails() {
        if (this.props.bug) {
            this.props.loadBugdetailsForTester(this.props.bug._id);
        }
    }

    render() {
        return (
            <div className="externalCard">
                <div onClick={() => {
                    this.expandDetails();
                }}>
                    <div className="title">{ this.props.bug.name }</div>
                    { this.getLabelForState() }
                </div>
                { (this.props.currentTogglededBug === this.props.bug._id) &&
                    <div className="pt-0">
                        <div className="bugdetail-bug-meta-header-external">Bug details</div>
                        <div className="bugdetail-description mb-16">
                            { this.props.bug.description }
                        </div>
                        { this.props.bug.meta ? this.renderMetaData() : this.renderNoMetaData() }
                        <div className="bugdetail-bug-meta-header-external">Feedback</div>
                        <ExternalCommentCreation bug={ this.props.bug }></ExternalCommentCreation>
                        <ExternalComments bug={ this.props.bug }></ExternalComments>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentTogglededBug: state.tester.currentTogglededBug
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => {
      dispatch(push(url));
    },
    loadBugdetailsForTester: (bugId) => {
        dispatch(loadBugdetailsForTester(bugId));
    },
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalBugCard);