import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragSource } from 'react-dnd';
import './BugCard.css';

import { getStorypoints, getPriority, staticData, getBugPointsClass } from '../../helper/bugdetails';
import UserSmall from '../UserSmall/UserSmall';

const Types = {
  CARD: 'card'
};

class BugCard extends Component {
  getStatusColorForStatusKey(statusKey) {
    var item = this.props.bugStatusObject[statusKey];
    if (item) {
      return item.color;
    }
    return '#888';
  }

  render() {
    const { isDragging, connectDragSource, connectDragPreview } = this.props;

    let bugStorypoint = getStorypoints(this.props.bug);
    let bugPriorities = getPriority(this.props.bug["priority"], staticData.bugPriorities);
    let bugPointsClass = getBugPointsClass(bugStorypoint);

    if (isDragging) {
      return (<div className="bugcard-container-placeholder"></div>)
    }

    return connectDragPreview(connectDragSource(
      <div className="bugcard-container">
        <div className="bugTitleContainer">
          <div className="bugTitle">
            <div className="bugStatusBubbleInline" style={{
              backgroundColor: this.getStatusColorForStatusKey(this.props.bug.status)
            }}></div>
            { this.props.bug.name }
          </div>
          <div className="bugItemDate">
            <Moment fromNow>{ this.props.bug.createdAt }</Moment>
          </div>
        </div>
        <div className="bugDescription">{ this.props.bug.description }</div>
        <div className="bugStateContainer">
          <div className="bugStorypointContainer">
            <span className={ bugPointsClass }>{ bugStorypoint }</span>
            <span className="bugStorypointIcon ml-8"><FontAwesomeIcon icon={ bugPriorities.icon } /></span>
            <span>{ bugPriorities.name }</span>
          </div>
          <div className="bugStorypointContainer bugStorypointContainerFlex">
            <UserSmall userId={ this.props.bug.assignedTo }></UserSmall>
          </div>
        </div>
      </div>
    ));
  }
}

const cardSource = {
	beginDrag(props, monitor, component) {
    const item = {
      id: props.bug._id.toHexString(),
      bug: props.bug
    };
    return item;
  }
}

const mapStateToProps = state => {
  return {
    bugStatusObject: state.bug.bugStatusObject
  };
};

export default connect(
  mapStateToProps,
  null
)(DragSource(Types.CARD, cardSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
}))(BugCard));