import { faQuestion, faAngleDoubleDown, faAngleDown, faExclamationSquare, faRadiation, faRadiationAlt, faExclamationCircle, faEngineWarning, faChevronCircleUp } from "@fortawesome/pro-regular-svg-icons";

var staticData = {
    bugPriorities: [
        {
            name: "N/A",
            icon: faQuestion,
            key: "na",
            factor: 1
        },
        {
            name: "Low",
            icon: faChevronCircleUp,
            key: "low",
            factor: 1
        },
        {
            name: "Medium",
            icon: faEngineWarning,
            key: "medium",
            factor: 2
        },
        {
            name: "High",
            icon: faRadiationAlt,
            key: "high",
            factor: 3
        }
    ]
}
  
function getStorypoints(bug) {
    let hoursSinceNow = 0;
    if (bug.doneAt) {
        hoursSinceNow = Math.abs(bug.doneAt - bug.createdAt) / 36e5;
    } else {
        hoursSinceNow = Math.abs(new Date() - bug.createdAt) / 36e5;
    }

    let priorityFactor = 1;
    for (var i = 0; i < staticData.bugPriorities.length; i++) {
        let currPrio = staticData.bugPriorities[i];
        if (currPrio.key === bug.priority) {
            priorityFactor = currPrio.factor;
        }
    }

    return Math.round(hoursSinceNow * priorityFactor);
}

function getWeekIdentifier() {
    let date = new Date();
    let onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil((((date - onejan) / 86400000) + onejan.getDay()+1)/7) + "_" + date.getFullYear();
}

function getBugPointsClass(bugPoints) {
    if (bugPoints > 100) {
        return "bugpoints-important";
    }
    if (bugPoints > 20) {
        return "bugpoints-medium";
    }
    return "bugpoints-new";
}

function getPriority(priorityKey, priorityList) {
    var item = null;
    for (var i = 0; i < priorityList.length; i++) {
      if (priorityList[i].key === priorityKey) {
        item = priorityList[i];
      }
    }
    if (item) {
      return item;
    }
    return {
        name: "N/A",
        icon: faQuestion,
        key: "na"
    };
}

export {
    getStorypoints,
    getPriority,
    getBugPointsClass,
    getWeekIdentifier,
    staticData
};