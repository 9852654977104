import { ADD_PROJECT, CREATE_PROJECT_DONE } from "../../actions/project/createProject";

const initialState = {
    loading: false,
    project: {
        _id: "",
        name: ""
    }
};
  
export default function createProject(state = initialState, action) {
    if (action.type === ADD_PROJECT) {
        return Object.assign({}, state, {
            loading: true
        });
    }
    if (action.type === CREATE_PROJECT_DONE) {
        return Object.assign({}, state, {
            loading: false,
            project: action.payload
        });
    }
    return state;
}