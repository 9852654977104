import React, { Component } from 'react';
import MongoClient from '../../mongodb/MongoClient';
import { Button, Input } from 'reactstrap';
import logo from '../LoginPage/BugBattleLogo.svg';
import { BarLoader } from 'react-spinners';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      password: "",
      loading: false
    };
  }

  register() {
    this.setState({
      loading: true
    });
    MongoClient.getInstance().resetPasswordWithToken(this.state.password).then(() => {
      this.setState({
        loading: false
      });
      this.props.history.push('/login');
    })
    .catch(err => {
      this.setState({
        loading: false,
        password: ""
      });
      if (err) {
        alert(err.toString().replace("StitchServiceError: ", ""));
      }
    });
  }

  renderSignup() {
    return (
      <div className="LoginForm">
        <img src={ logo } alt="BugBattle Logo" />
        <div className="login-signup-title">
          Reset password
        </div>
        <Input placeholder="Please choose a new password" type="password" value={this.state.password} onChange={(event) => {
          this.setState({ password: event.target.value })
        }} />
        <Button size="lg" disabled={ this.state.password === "" } color="primary" onClick={this.register.bind(this)}>
          Reset password
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className="LoginContainer">
        { this.state.loading ? <div className="modal-center-content">
          <BarLoader
            sizeUnit={"px"}
            width={ 100 }
            height={ 4 }
            color={'#3574F3'}
            loading={ true }
          />
        </div> : <div>
          { this.renderSignup() }
        </div> }
      </div>
    );
  }
}

export default ResetPassword;
