import React, { Component } from 'react';
import MongoClient from '../../mongodb/MongoClient';
import { Button, Input } from 'reactstrap';
import logo from '../LoginPage/BugBattleLogo.svg';
import { BarLoader } from 'react-spinners';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      email: "",
      loading: false
    };
  }

  resetPassword() {
    this.setState({
      loading: true
    });
    MongoClient.getInstance().resetPassword(this.state.email).then(() => {
      this.setState({
        loading: false,
        eamil: ""
      });
      alert("We have sent you a password reset link. Please check your inbox!");
      this.props.history.push('/login');
    })
    .catch(err => {
      this.setState({
        loading: false
      });
      if (err) {
        alert("Error sending password reset email.");
      }
    });
  }

  renderSignup() {
    return (
      <div className="LoginForm">
        <div className="mb-16">
          <img src={ logo } alt="BugBattle Logo" />
          <div className="login-signup-title">
            Reset password
          </div>
          <Input placeholder="Please enter your email" type="text" value={this.state.email} onChange={(event) => {
            this.setState({ email: event.target.value })
          }} />
          <Button size="lg" disabled={ this.state.email === "" } color="primary" onClick={this.resetPassword.bind(this)}>
            Reset
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="LoginContainer">
        { this.state.loading ? <div className="modal-center-content">
          <BarLoader
            sizeUnit={"px"}
            width={ 100 }
            height={ 4 }
            color={'#3574F3'}
            loading={ true }
          />
        </div> : <div>
          { this.renderSignup() }
        </div> }
      </div>
    );
  }
}

export default ForgotPassword;
