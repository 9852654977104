import axios from 'axios';
import { toast } from 'react-toastify';

import MongoClient from '../../../mongodb/MongoClient';
import { updateUserPoints, updatedUserPoints } from '../user/users';
import { loadProject } from '../project/projects';
import { getStorypoints } from '../../../helper/bugdetails';

export const SET_BUGLIST_FILTER = 'SET_BUGLIST_FILTER';
export const setBugListFilter = (status, assignedTo) => {
  return {
    type: SET_BUGLIST_FILTER,
    status,
    assignedTo
  }
}

export const selectBug = (bug) => {
  return (dispatch) => {
    dispatch(selectBugAction(bug));
    if (bug && bug._id) {
      dispatch(loadComments(bug._id.toString()));
      dispatch(loadStepsToReproduce(bug._id.toString()));
      dispatch(loadConsoleLog(bug._id.toString()));
    }
  }
}

export const SELECT_BUG = 'SELECT_BUG';
export const selectBugAction = (bug) => {
  return {
    type: SELECT_BUG,
    bug
  }
}

export const SHOW_BUG_MODAL = 'SHOW_BUG_MODAL';
export const showBugModal = (show) => {
  return {
    type: SHOW_BUG_MODAL,
    show
  }
}

export const showBugModally = (bug) => {
  return (dispatch) => {
    dispatch(selectBug(bug));
    dispatch(showBugModal(true));
  }
}

export const DELETE_BUG = 'DELETE_BUG';
export const deleteBug = (bugId) => {
  return {
    type: DELETE_BUG,
    bugId
  }
}

export const DELETE_BUG_DONE = 'DELETE_BUG_DONE';
export const deleteBugDone = (bugId) => {
  return {
    type: DELETE_BUG_DONE,
    bugId
  }
}

export const FETCH_STEPS_TO_REPRODUCE = 'FETCH_STEPS_TO_REPRODUCE';
export const fetchStepsToReproduce = () => {
  return {
    type: FETCH_STEPS_TO_REPRODUCE
  }
}

export const FETCH_STEPS_TO_REPRODUCE_DONE = 'FETCH_STEPS_TO_REPRODUCE_DONE';
export const fetchStepsToReproduceDone = (stepsToReproduce) => {
  return {
    type: FETCH_STEPS_TO_REPRODUCE_DONE,
    stepsToReproduce
  }
}

export const FETCH_LOG = 'FETCH_LOG';
export const fetchLog = () => {
  return {
    type: FETCH_LOG
  }
}

export const FETCH_LOG_DONE = 'FETCH_LOG_DONE';
export const fetchLogDone = (log) => {
  return {
    type: FETCH_LOG_DONE,
    log
  }
}

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const fetchComments = () => {
  return {
    type: FETCH_COMMENTS
  }
}

export const FETCH_COMMENTS_DONE = 'FETCH_COMMENTS_DONE';
export const fetchCommentsDone = (comments) => {
  return {
    type: FETCH_COMMENTS_DONE,
    comments
  }
}

export const FETCH_BUGS = 'FETCH_BUGS';
export const fetchBugs = () => {
  return {
    type: FETCH_BUGS
  }
}

export const FETCH_BUGS_DONE = 'FETCH_BUGS_DONE';
export const fetchBugsDone = (bugs, bugStatusList) => {
  return {
    type: FETCH_BUGS_DONE,
    bugs,
    bugStatusList
  }
}

export const UPDATE_BUG = 'UPDATE_BUG';
export const updateBugData = (bugId, data) => {
  return {
    type: UPDATE_BUG,
    bugId,
    data
  }
}

export const UPDATE_BUG_DONE = 'UPDATE_BUG_DONE';
export const updateBugDataDone = (bugId, bug) => {
  return {
    type: UPDATE_BUG_DONE,
    bugId,
    bug
  }
}

export const COMPOSE_COMMENT = 'COMPOSE_COMMENT';
export const composeComment = (comment) => {
  return {
    type: COMPOSE_COMMENT,
    comment
  }
}

export const COMPOSE_COMMENT_DONE = 'COMPOSE_COMMENT_DONE';
export const composeCommentDone = (comment) => {
  return {
    type: COMPOSE_COMMENT_DONE,
    comment
  }
}

export const SET_BUG_DRAGGING_COLOR = 'SET_BUG_DRAGGING_COLOR';
export const setBugDraggingColor = (color) => {
  return {
    type: SET_BUG_DRAGGING_COLOR,
    color
  }
}

export const sendComment = (comment) => {
  return (dispatch) => {
    dispatch(composeComment(comment));
    
    MongoClient.getInstance().sendComment(comment).then((commentId) => {
      dispatch(composeCommentDone(comment))
    })
  }
}

export const loadStepsToReproduce = (bugId) => {
  return (dispatch) => {
    dispatch(fetchStepsToReproduce());
    
    MongoClient.getInstance().loadStepsToReproduce(bugId).then((stepsToReproduce) => {
      dispatch(fetchStepsToReproduceDone(stepsToReproduce))
    })
  }
}

export const loadConsoleLog = (bugId) => {
  return (dispatch) => {
    dispatch(fetchLog());
    
    MongoClient.getInstance().loadConsoleLog(bugId).then((log) => {
      dispatch(fetchLogDone(log))
    })
  }
}

export const loadComments = (bugId) => {
  return (dispatch) => {
    dispatch(fetchComments());
    
    MongoClient.getInstance().loadComments(bugId).then((comments) => {
      dispatch(fetchCommentsDone(comments))
    })
  }
}

export const loadBugs = (projectId, bugStatusList) => {
  return (dispatch) => {
    dispatch(fetchBugs());
    setTimeout(() => {
      MongoClient.getInstance().loadBugs(projectId).then((bugs) => {
        dispatch(fetchBugsDone(bugs, bugStatusList))
      })
    }, 1000);
  }
}

export const markBugAsDuplicate = (currentBug, duplicatedBug) => {
  return (dispatch, getState) => {

    var duplicates = [];
    if (currentBug['duplicates']) {
      duplicates = currentBug['duplicates'];
    }
    if (duplicatedBug['duplicates']) {
      duplicates = duplicates.concat(duplicatedBug['duplicates']);
    }
    duplicates.push(duplicatedBug);

    // Select new one.
    dispatch(selectBug(currentBug));

    // Push into the old bug.
    let data = {
      duplicates: duplicates
    };
    let bugId = currentBug._id;
    dispatch(updateBugData(bugId, data));
    MongoClient.getInstance().updateBug(bugId, data).then((bug) => {
      dispatch(updateBugDataDone(bugId, bug));
    });

    // Delete old bug.
    dispatch(deleteBug(duplicatedBug._id));
    MongoClient.getInstance().removeBug(duplicatedBug._id).then(() => {
      dispatch(deleteBugDone(duplicatedBug._id));
    })
  }
}

export const updateBug = (bug, data) => {
  return (dispatch, getState) => {
    let bugId = bug._id;

    dispatch(updateBugData(bugId, data));

    // Update points!
    if (data.status === "done") {
      if (bug.assignedTo && !bug.pointsSpent) {
        var bugpoints = getStorypoints(bug);
        dispatch(updateUserPoints(bug.assignedTo, bugpoints, bug.projectId));
        setTimeout(() => {
          dispatch(updatedUserPoints());
        }, 500);
      }
      // Update date.
      data['doneAt'] = new Date()
    }

    if (data.status === "feedbackneeded") {
      toast.success("An email has been sent to the reporter of the bug! You requested more feedback!", {
          position: toast.POSITION.TOP_RIGHT
      });
    }

    if (data.status === "readytotestagain") {
      toast.success("An email has been sent to the reporter of the bug! You requested a review.", {
          position: toast.POSITION.TOP_RIGHT
      });
    }
    
    MongoClient.getInstance().updateBug(bugId, data).then((bug) => {
      dispatch(updateBugDataDone(bugId, bug))
    })
  }
}

export const removeBug = (bugId) => {
  return (dispatch) => {
    dispatch(deleteBug(bugId));
    dispatch(showBugModal(false));
    dispatch(selectBug({
      _id: null
    }));
    
    MongoClient.getInstance().removeBug(bugId).then(() => {
      dispatch(deleteBugDone(bugId));
    })
  }
}

export const reportBug = (reportedBy, description, apiToken) => {
  return (dispatch) => {
    axios.post('https://webhooks.mongodb-stitch.com/api/client/v2.0/app/bugbattle-xfblb/service/reportBug/incoming_webhook/reportBugWebhook?token=' + apiToken, {
      reportedBy: reportedBy,
      description: description
    })
    .then(function (response) {
      dispatch(loadProject(apiToken));
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}