import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faMobile, faAppleAlt, faWindowRestore, faInfo, faSignature, faMobileAndroid } from '@fortawesome/pro-regular-svg-icons';
import { RIETextArea } from 'riek';
import _ from 'lodash';
import './BugDetailsMeta.css';
import { updateBug } from '../../redux/actions/bug/bugs';

class BugDetailsMeta extends Component {
  /**
   * Get session duration
   */
  getDuration() {
    if (this.props.currentBug.meta.sessionDuration) {
      let secs = Math.round(this.props.currentBug.meta.sessionDuration);
      let minutes = Math.round(secs / 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let seconds = Math.round(secs % 60);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    } else {
      return "00:00";
    }
  }

  /**
   * Render meta data
   */
  renderMetaData() {
    // Web
    if (this.props.currentBug.meta.web) {
      return (
        <div className="bugdetail-bug-meta">
          <div className="bugdetail-bug-meta-card">
            <div className="bugdetail-bug-meta-card-icon">
              <FontAwesomeIcon icon={faCode} />
            </div>
            <div className="bugdetail-bug-meta-card-container">
              <div className="bugdetail-bug-meta-card-title">APP VERSION</div>
              <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.releaseVersionNumber ? this.props.currentBug.meta.releaseVersionNumber : "NOT SET"} ({ this.props.currentBug.meta.buildVersionNumber ? this.props.currentBug.meta.buildVersionNumber : "NOT SET" })</div>
            </div>
          </div>
          <div className="bugdetail-bug-meta-card">
            <div className="bugdetail-bug-meta-card-icon">
              <FontAwesomeIcon icon={faMobile} />
            </div>
            <div className="bugdetail-bug-meta-card-container">
              <div className="bugdetail-bug-meta-card-title">USER AGENT</div>
              <div className="bugdetail-bug-meta-card-data bugdetail-bug-meta-card-data-oneliner">{this.props.currentBug.meta.deviceIdentifier}</div>
            </div>
          </div>
          <div className="bugdetail-bug-meta-card">
            <div className="bugdetail-bug-meta-card-icon">
              {(this.props.currentBug.meta.systemName && this.props.currentBug.meta.systemName.toLowerCase() === "android") ? <FontAwesomeIcon icon={faMobileAndroid} /> : <FontAwesomeIcon icon={faAppleAlt} />}
            </div>
            <div className="bugdetail-bug-meta-card-container">
              <div className="bugdetail-bug-meta-card-title">OS</div>
              <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.systemName} {this.props.currentBug.meta.systemVersion}</div>
            </div>
          </div>
          <div className="bugdetail-bug-meta-card">
            <div className="bugdetail-bug-meta-card-icon">
              <FontAwesomeIcon icon={faWindowRestore} />
            </div>
            <div className="bugdetail-bug-meta-card-container">
              <div className="bugdetail-bug-meta-card-title">DURATION</div>
              <div className="bugdetail-bug-meta-card-data">{this.getDuration()}</div>
            </div>
          </div>
          <div className="bugdetail-bug-meta-card">
            <div className="bugdetail-bug-meta-card-icon">
              <FontAwesomeIcon icon={faInfo} />
            </div>
            <div className="bugdetail-bug-meta-card-container">
              <div className="bugdetail-bug-meta-card-title">BROWSER</div>
              <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.bundleID}</div>
            </div>
          </div>
          <div className="bugdetail-bug-meta-card">
            <div className="bugdetail-bug-meta-card-icon">
              <FontAwesomeIcon icon={faSignature} />
            </div>
            <div className="bugdetail-bug-meta-card-container">
              <div className="bugdetail-bug-meta-card-title">BROWSER NAME</div>
              <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.deviceName}</div>
            </div>
          </div>
        </div>
      );
    }

    // App
    return (
      <div className="bugdetail-bug-meta">
        <div className="bugdetail-bug-meta-card">
          <div className="bugdetail-bug-meta-card-icon">
            <FontAwesomeIcon icon={faCode} />
          </div>
          <div className="bugdetail-bug-meta-card-container">
            <div className="bugdetail-bug-meta-card-title">APP VERSION</div>
            <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.releaseVersionNumber} ({this.props.currentBug.meta.buildVersionNumber})</div>
          </div>
        </div>
        <div className="bugdetail-bug-meta-card">
          <div className="bugdetail-bug-meta-card-icon">
            <FontAwesomeIcon icon={faMobile} />
          </div>
          <div className="bugdetail-bug-meta-card-container">
            <div className="bugdetail-bug-meta-card-title">DEVICE</div>
            <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.deviceIdentifier}</div>
          </div>
        </div>
        <div className="bugdetail-bug-meta-card">
          <div className="bugdetail-bug-meta-card-icon">
            {(this.props.currentBug.meta.systemName && this.props.currentBug.meta.systemName.toLowerCase() === "android") ? <FontAwesomeIcon icon={faMobileAndroid} /> : <FontAwesomeIcon icon={faAppleAlt} />}
          </div>
          <div className="bugdetail-bug-meta-card-container">
            <div className="bugdetail-bug-meta-card-title">OS</div>
            <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.systemName} {this.props.currentBug.meta.systemVersion}</div>
          </div>
        </div>
        <div className="bugdetail-bug-meta-card">
          <div className="bugdetail-bug-meta-card-icon">
            <FontAwesomeIcon icon={faWindowRestore} />
          </div>
          <div className="bugdetail-bug-meta-card-container">
            <div className="bugdetail-bug-meta-card-title">DURATION</div>
            <div className="bugdetail-bug-meta-card-data">{this.getDuration()}</div>
          </div>
        </div>
        <div className="bugdetail-bug-meta-card">
          <div className="bugdetail-bug-meta-card-icon">
            <FontAwesomeIcon icon={faInfo} />
          </div>
          <div className="bugdetail-bug-meta-card-container">
            <div className="bugdetail-bug-meta-card-title">BUNDLE ID</div>
            <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.bundleID}</div>
          </div>
        </div>
        <div className="bugdetail-bug-meta-card">
          <div className="bugdetail-bug-meta-card-icon">
            <FontAwesomeIcon icon={faSignature} />
          </div>
          <div className="bugdetail-bug-meta-card-container">
            <div className="bugdetail-bug-meta-card-title">DEVICE NAME</div>
            <div className="bugdetail-bug-meta-card-data">{this.props.currentBug.meta.deviceName}</div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render no meta data
   */
  renderNoMetaData() {
    return (
      <div>No meta data available.</div>
    );
  }

  /**
   * Main render
   */
  render() {
    return (
      <div className="bugdetail-details">
        <div className="bugdetail-bug-title">{this.props.currentBug.name}</div>
        <div className="bugdetail-description">
          <RIETextArea
            value={this.props.currentBug.description}
            change={(e) => {
              if (e.description !== this.props.currentBug.description) {
                this.props.updateBug(this.props.currentBug, e);
              }
            }}
            propName='description'
            validate={_.isString} />
        </div>

        <div className="bugdetail-bug-meta-header">Bug details</div>
        {this.props.currentBug.meta ? this.renderMetaData() : this.renderNoMetaData()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentBug: state.bug.currentBug
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBug: (bug, data) => {
      dispatch(updateBug(bug, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BugDetailsMeta);