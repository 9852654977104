import uuidv4 from 'uuid/v4';
import MongoClient from '../../../mongodb/MongoClient';
import { loadProjects } from './projects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

export const ADD_PROJECT = "ADD_PROJECT";
export const addProject = (name) => {
  return {
    type: ADD_PROJECT,
    payload: {
      _id: uuidv4(),
      name: name
    }
  };
};

export const CREATE_PROJECT_DONE = 'CREATE_PROJECT_DONE'
export const createProjectDone = (name, id) => {
  return {
    type: CREATE_PROJECT_DONE,
    payload: {
      name: name,
      _id: id,
    }
  }
}

export const createProject = (name) => {
  return (dispatch) => {
    dispatch(addProject(name));

    MongoClient.getInstance().createProject(name).then((id) => {
      dispatch(createProjectDone(name, id));
      dispatch(loadProjects());
      dispatch(push('/project/' + id));
      window.Intercom('update', {
        createdProject: true
      });
      window.Intercom('trackEvent', 'created-project', {
        projectName: name
      });
      toast.success("Project created successfully!", {
        position: toast.POSITION.TOP_RIGHT
      });
    });
  }
}